/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "getting-started-with-gatsby"
  }, "Getting Started with Gatsby"), "\n", React.createElement(_components.p, null, "To use Theme UI with ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com"
  }, "Gatsby"), ", install and use\n", React.createElement(_components.a, {
    href: "/packages/gatsby-plugin"
  }, React.createElement(_components.code, null, "gatsby-plugin-theme-ui")), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i theme-ui @theme-ui/mdx gatsby-plugin-theme-ui @emotion/react @mdx-js/react\n")), "\n", React.createElement(_components.p, null, "Add the plugin to your ", React.createElement(_components.code, null, "gatsby-config.js"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "module.exports = {\n  plugins: ['gatsby-plugin-theme-ui'],\n}\n")), "\n", React.createElement(_components.p, null, "Create a theme file located at ", React.createElement(_components.code, null, "src/gatsby-plugin-theme-ui/index.js"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme file\nconst theme = {\n  colors: {\n    text: '#000',\n    background: '#fff',\n  },\n}\n\nexport default theme\n")), "\n", React.createElement(_components.p, null, "With ", React.createElement(_components.code, null, "gatsby-plugin-theme-ui"), ", there is no need to manually use the\n", React.createElement(_components.code, null, "ThemeUIProvider"), " component. Use the ", React.createElement(_components.a, {
    href: "/sx-prop"
  }, React.createElement(_components.code, null, "sx"), " prop"), ",\n", React.createElement(_components.a, {
    href: "/color-modes"
  }, "color modes"), ", and other features just as you would with any other\napplication."), "\n", React.createElement(_components.p, null, "For more information, see the documentation for\n", React.createElement(_components.a, {
    href: "/packages/gatsby-plugin"
  }, React.createElement(_components.code, null, "gatsby-plugin-theme-ui")), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
