"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    h2: "h2",
    h3: "h3",
    strong: "strong",
    em: "em",
    hr: "hr",
    ul: "ul",
    li: "li"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uimdx",
      children: "@theme-ui/mdx"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://mdxjs.com",
        children: "MDX"
      }), " utilities for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://theme-ui.com",
        children: "Theme UI"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/mdx\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "api",
      children: "API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "usethemedstyleswithmdx",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "useThemedStylesWithMdx"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wraps an object of components provided to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "MDXProvider"
      }), " with styles from\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.styles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example usage:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-tsx",
        children: "import {\n  MDXProvider,\n  useMDXComponents,\n  Components as MDXComponents,\n  MergeComponents as MergeMDXComponents,\n} from '@mdx-js/react'\nimport { useThemedStylesWithMdx } from '@theme-ui/mdx'\nimport { ThemeUIProvider, Theme } from 'theme-ui'\n\ninterface MyProviderProps {\n  theme: Theme\n  components?: MDXComponents | MergeMDXComponents\n  children: React.ReactNode\n}\nfunction MyProvider({ theme, components, children }: MyProviderProps) {\n  const componentsWithStyles = useThemedStylesWithMdx(\n    useMDXComponents(components)\n  )\n\n  return (\n    <ThemeUIProvider theme={theme}>\n      <MDXProvider components={componentsWithStyles}>{children}</MDXProvider>\n    </ThemeUIProvider>\n  )\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "themed",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "Themed"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://theme-ui.com/mdx/themed",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Themed"
        }), " components dictionary"]
      }), " to\nrender UI with styles from ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://theme-ui.com/theming#styles",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.styles"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.em, {
        children: "outside"
      }), " of MDX. These are primarily meant as a mechanism to use styles defined\nin a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme"
      }), " object outside of MDX, which can then be embedded in JSX>"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-jsx",
        children: "// picks up styles from `theme.styles.h1`\n<Themed.h1 />\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you’re looking to automatically hyperlink headings in MDX, check out the\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://theme-ui.com/mdx/linked-headings",
        children: "linked headings guide"
      }), ".)"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Other exports"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "themed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "defaultMdxComponents"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = MDXContent;
exports.default = _default;