/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "centered-container"
  }, "Centered Container"), "\n", React.createElement(_components.p, null, "Create a centered, max-width container for reuse in multiple layouts."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<div\n  sx={{\n    maxWidth: 512,\n    mx: 'auto',\n    px: 3,\n    py: 4,\n  }}>\n  Centered\n</div>\n")), "\n", React.createElement(_components.p, null, "First, add a ", React.createElement(_components.code, null, "container"), " size to your theme,\nthen use the size in your component definition."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme\nexport default {\n  sizes: {\n    container: 768,\n  },\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\n// example Container component\nexport default (props) => (\n  <div\n    {...props}\n    sx={{\n      maxWidth: 'container',\n      mx: 'auto',\n      px: 3,\n    }}\n  />\n)\n")), "\n", React.createElement(_components.p, null, "See also: ", React.createElement(_components.a, {
    href: "/components/container"
  }, "Container component")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
