/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Container, Box, Button} from 'theme-ui';
import {Banner, Tiles} from '..';
import Graph from '../components/graph';
import ReadmeBadges from '../components/readme-badges';
import IntroCodeSamples from '../components/intro-code-samples';
import Testimonials from './testimonials';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    ol: "ol",
    li: "li",
    h2: "h2",
    ul: "ul",
    code: "code"
  }, _provideComponents(), props.components);
  if (!IntroCodeSamples) _missingMdxReference("IntroCodeSamples", false);
  if (!IntroCodeSamples.BasicUsage) _missingMdxReference("IntroCodeSamples.BasicUsage", true);
  if (!IntroCodeSamples.ThemeCreation) _missingMdxReference("IntroCodeSamples.ThemeCreation", true);
  return React.createElement(React.Fragment, null, React.createElement(Banner, null, React.createElement(Graph), React.createElement(_components.h1, {
    id: "theme-ui-the-design-graph-framework"
  }, "Theme UI: The Design Graph Framework"), React.createElement(_components.p, null, "Theme UI is a library for creating themeable user interfaces based on\nconstraint-based design principles. Build custom component libraries, design\nsystems, web applications, Gatsby themes, and more with a flexible API for\nbest-in-class developer ergonomics."), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/getting-started"
  }, "Documentation"), "\n", React.createElement(_components.a, {
    href: "https://github.com/system-ui/theme-ui"
  }, "GitHub"))), "\n", React.createElement(Container, null, React.createElement(ReadmeBadges), React.createElement(Tiles, {
    columns: 2
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.h2, {
    id: "create-your-theme"
  }, "Create your theme"), "\n"), "\n"), React.createElement(IntroCodeSamples.ThemeCreation), React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.h2, {
    id: "style-your-ui"
  }, "Style your UI"), "\n"), "\n"), React.createElement(IntroCodeSamples.BasicUsage)), React.createElement(Tiles, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.h2, {
    id: "ergonomic"
  }, "Ergonomic"), "\n", "Best-in-class developer ergonomics let you style your application quickly and\nconsistently based on your theme"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.h2, {
    id: "themeable"
  }, "Themeable"), "\n", "Quickly and easily reference values from your theme throughout your entire\napplication, on any component"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.h2, {
    id: "constraint-based"
  }, "Constraint-based"), "\n", "Use color, typography, and layout scales rooted in constraint-based design\nprinciples"), "\n")), React.createElement(Testimonials), React.createElement(Tiles, {
    sx: {
      fontWeight: 'bold',
      a: {
        display: 'block',
        color: 'inherit',
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'underline'
        }
      }
    }
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/getting-started/#sx-prop"
  }, "Theme-aware ", React.createElement(_components.code, null, "sx"), " prop for CSS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/components"
  }, "30+ Built-in primitive UI components")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/mdx/styling"
  }, "Simple, expressive MDX styling")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/getting-started/#sx-prop"
  }, "Style with or without creating components")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/getting-started/#responsive-styles"
  }, "Easy, mobile-first responsive styles")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/color-modes"
  }, "Built-in dark mode")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/gatsby-plugin"
  }, "Plugin for Gatsby sites and themes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/theming"
  }, "Robust theming API")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/theme-spec"
  }, "Backed with a Theme Specification")), "\n")), React.createElement(Box, {
    py: 6
  }, React.createElement(_components.h2, {
    id: "get-started"
  }, "Get Started"), React.createElement(Button, {
    as: "a",
    href: "/getting-started",
    variant: "black"
  }, "Explore the Docs"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
