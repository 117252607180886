/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import TypographyLayout from '../../components/typography-layout';
import Lorem from '../../components/lorem.mdx';
const MDXLayout = TypographyLayout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    h2: "h2",
    pre: "pre",
    em: "em",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "theme-uitypography"
  }, "@theme-ui/typography"), "\n", React.createElement(_components.p, null, "This page uses themes from Typography.js to populate the ", React.createElement(_components.code, null, "theme"), " object. Select\na theme from the controls above to view a live demo of each one."), "\n", React.createElement(_components.h2, {
    id: "usage"
  }, "Usage"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i @theme-ui/typography\n")), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "toTheme"), " function to create a base ", React.createElement(_components.code, null, "theme"), " object based on\nTypography.js options or a preset Typography.js theme."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { ThemeUIProvider } from 'theme-ui'\nimport { toTheme } from '@theme-ui/typography'\nimport wp2016 from 'typography-theme-wordpress-2016'\n\nconst theme = toTheme(wp2016)\n\nexport default (props) => (\n  <ThemeUIProvider theme={theme}>{props.children}</ThemeUIProvider>\n)\n")), "\n", React.createElement(_components.h2, {
    id: "extending-the-typographyjs-theme"
  }, "Extending the Typography.js theme"), "\n", React.createElement(_components.p, null, "Use a deep merge utility, like the ", React.createElement(_components.code, null, "deepmerge"), " package, to extend or override\nthe returned theme object."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { toTheme } from '@theme-ui/typography'\nimport wp2016 from 'typography-theme-wordpress-2016'\nimport merge from 'deepmerge'\n\nconst theme = merge(toTheme(wp2016), {\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n  },\n})\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Demo content below")), "\n", React.createElement(_components.hr), "\n", React.createElement(Lorem));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
