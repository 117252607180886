/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    hr: "hr",
    h1: "h1",
    pre: "pre",
    h2: "h2",
    a: "a",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("strong", null, React.createElement(_components.p, null, React.createElement(_components.code, null, "gatsby-theme-ui-blog"), " package was deprecated after Theme UI v0.3.1.")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "gatsby-theme-ui-blog"
  }, "gatsby-theme-ui-blog"), "\n", React.createElement(_components.p, null, "Minimal Gatsby MDX blog theme built with Theme UI. Use this as a starting point\nfor building custom blog themes with Theme UI."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i gatsby-theme-ui-blog\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// gatsby-config.js\nmodule.exports = {\n  plugins: ['gatsby-theme-ui-blog'],\n}\n")), "\n", React.createElement(_components.h2, {
    id: "shadowing"
  }, "Shadowing"), "\n", React.createElement(_components.p, null, "Shadow the following components to customize styles and page layout:"), "\n", React.createElement(_components.p, null, "| Component                            | Description                                                 | Props                                       |\n| ------------------------------------ | ----------------------------------------------------------- | ------------------------------------------- | -------------------- |\n| ", React.createElement(_components.code, null, "src/gatsby-theme-ui-blog/posts.js"), "  | Index page for all blog posts                               | ", React.createElement(_components.code, null, "{ posts }"), "                                 |\n| ", React.createElement(_components.code, null, "src/gatsby-theme-ui-blog/post.js"), "   | Full blog post article page                                 | ", React.createElement(_components.code, null, "{ title, date, children, keywords, tags }"), " |\n| ", React.createElement(_components.code, null, "src/gatsby-theme-ui-blog/layout.js"), " | Wrapping layout component for the Post and Posts components |                                             | all page-level props |"), "\n", React.createElement(_components.h2, {
    id: "theming"
  }, "Theming"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "gatsby-plugin-theme-ui"), " to override or customize the theme."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i gatsby-plugin-theme-ui\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// gatsby-config.js\nmodule.exports = {\n  plugins: ['gatsby-theme-ui-blog', 'gatsby-plugin-theme-ui'],\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// shadow src/gatsby-plugin-theme-ui/index.js\nimport base from 'gatsby-theme-ui-blog/src/gatsby-plugin-theme-ui'\n\nconst theme = {\n  ...base,\n  colors: {\n    text: 'white',\n    background: 'black',\n    primary: 'cyan',\n    secondary: 'magenta',\n  },\n}\n\nexport default theme\n")), "\n", React.createElement(_components.h2, {
    id: "options"
  }, "Options"), "\n", React.createElement(_components.p, null, "This theme is based off of ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/gatsby-theme-blog-core"
  }, "gatsby-theme-blog-core"), " and uses all the same\noptions."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Key"), React.createElement(_components.th, null, "Default value"), React.createElement(_components.th, null, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "basePath")), React.createElement(_components.td, null, React.createElement(_components.code, null, "/")), React.createElement(_components.td, null, "Root url for all blog posts")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "contentPath")), React.createElement(_components.td, null, React.createElement(_components.code, null, "content/posts")), React.createElement(_components.td, null, "Location of blog posts")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "assetPath")), React.createElement(_components.td, null, React.createElement(_components.code, null, "content/assets")), React.createElement(_components.td, null, "Location of assets")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "mdx")), React.createElement(_components.td, null, React.createElement(_components.code, null, "true")), React.createElement(_components.td, null, "Configure ", React.createElement(_components.code, null, "gatsby-plugin-mdx"), " (if your website already is using the plugin pass ", React.createElement(_components.code, null, "false"), " to turn this off)")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
