/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    pre: "pre",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "paragraph"
  }, "Paragraph"), "\n", React.createElement(_components.p, null, "Primitive typographic component meant to display text blocks, defaults to ", React.createElement(_components.code, null, "<p>"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Paragraph } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Paragraph>Hello World!</Paragraph>\n<Paragraph variant=\"block\">\n  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nunc elit,\n  feugiat ut velit eget, pellentesque accumsan libero. Duis interdum ante sed\n  ipsum imperdiet congue nec non mauris. Quisque ac ultricies nunc. Sed nisi\n  diam, suscipit eu laoreet a, bibendum nec mauris. Aliquam tristique rhoncus\n  ipsum scelerisque egestas. Maecenas rhoncus malesuada mauris, vel porta ex\n  tincidunt non. Duis ac sapien in felis fermentum tristique. Nam a erat\n  faucibus, varius magna ac, interdum tortor. Aenean quis varius mi, nec\n  molestie neque. In dignissim diam in velit pharetra cursus. Integer gravida,\n  arcu non sagittis iaculis, justo leo facilisis augue, consequat mollis risus\n  erat quis velit. In turpis nibh, ornare ut facilisis id, dignissim non enim.\n  Fusce maximus elit sit amet dolor aliquet, et tristique elit eleifend. Aenean\n  ornare tortor sem, at sollicitudin ipsum scelerisque a.\n</Paragraph>\n<Paragraph as=\"small\" sx={{ fontStyle: 'italic' }}>Something a bit less important</Paragraph>\n")), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "as"), " prop to set the correct HTML element independent from styling."), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Text style variants can be defined in the ", React.createElement(_components.code, null, "theme.text"), " object.\nThe Paragraph component uses ", React.createElement(_components.code, null, "theme.text.paragraph"), " as its default variant style."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme variants\n{\n  text: {\n    block: {\n      variant: 'paragraph',\n      my: 2,\n      textAlign: 'justify',\n      textAlignLast: 'start',\n      textJustify: 'auto',\n    },\n  }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
