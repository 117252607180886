/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    code: "code",
    p: "p",
    h3: "h3",
    a: "a",
    strong: "strong",
    pre: "pre",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    em: "em"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "api"
  }, "API"), "\n", React.createElement(_components.h2, {
    id: "theme-ui"
  }, React.createElement(_components.code, null, "theme-ui")), "\n", React.createElement(_components.p, null, "The core ", React.createElement(_components.code, null, "theme-ui"), " package exports the following modules."), "\n", React.createElement(_components.h3, {
    id: "jsx-runtime"
  }, React.createElement(_components.code, null, "/jsx-runtime")), "\n", React.createElement(_components.p, null, "While not being available in the ", React.createElement(_components.code, null, "theme-ui"), " package directly, there are two\nseparate entry points for React create element functions intended to be used in\nthe\n", React.createElement(_components.a, {
    href: "https://reactjs.org/blog/2020/09/22/introducing-the-new-jsx-transform.html"
  }, "automatic JSX runtime"), "\nwith a custom pragma comment. They add support for the ", React.createElement(_components.code, null, "sx"), " prop, which uses\nEmotion's create element functions internally and parse style objects with the\nTheme UI ", React.createElement(_components.code, null, "css"), " utility."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Usage:")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    {...props}\n    sx={{\n      color: 'primary',\n    }}\n  />\n)\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Imports for use without JSX:")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { jsx, jsxs } from 'theme-ui/jsx-runtime'\nimport { jsxDEV } from 'theme-ui/jsx-dev-runtime'\n")), "\n", React.createElement(_components.h3, {
    id: "themeuiprovider"
  }, React.createElement(_components.code, null, "ThemeUIProvider")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "ThemeUIProvider"), " component is a wrapper around Emotion's ", React.createElement(_components.code, null, "ThemeUIProvider"), "\nand MDX's ", React.createElement(_components.code, null, "MDXProvider"), ". It provides default styled components to MDX content\nthat pick up values from ", React.createElement(_components.code, null, "theme.styles"), ". It also handles color mode state."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Prop"), React.createElement(_components.th, null, "Type"), React.createElement(_components.th, null, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "theme")), React.createElement(_components.td, null, "Object"), React.createElement(_components.td, null, "Theming context object")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "components")), React.createElement(_components.td, null, "Object"), React.createElement(_components.td, null, "Optional custom MDX components")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "children")), React.createElement(_components.td, null, "Node"), React.createElement(_components.td, null, "React children")))), "\n", React.createElement(_components.h3, {
    id: "usethemeui"
  }, React.createElement(_components.code, null, "useThemeUI")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "useThemeUI"), " hook returns the full Theme UI context object, which includes\n", React.createElement(_components.code, null, "theme"), ", and color mode."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const context = useThemeUI()\nconst { theme, components, colorMode, setColorMode } = context\n")), "\n", React.createElement(_components.h3, {
    id: "usecolormode"
  }, React.createElement(_components.code, null, "useColorMode")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "useColorMode"), " hook returns an array with two values: the current color mode\nstate and an updater function. The color mode state can be any string and should\nmatch the name of a color mode defined in ", React.createElement(_components.code, null, "theme.colors.modes"), " or be a name for\nthe default color mode."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const [colorMode, setColorMode] = useColorMode()\n")), "\n", React.createElement(_components.h3, {
    id: "basestyles"
  }, React.createElement(_components.code, null, "BaseStyles")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "BaseStyles"), " component can be used to style child content that isn’t\ngenerated from MDX. This can be useful with content from a CMS or Markdown."), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "Note that this will increase the CSS specificity of child elements, and you may\nrun into issues with the cascade (i.e. specificity) when using this component.\nThis component should ", React.createElement(_components.em, null, "not"), " be used at the root of your application, as it will\noverride custom & component styling.")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<BaseStyles>\n  <h1>\n    Styled based on <code>theme.styles</code>\n  </h1>\n</BaseStyles>\n")), "\n", React.createElement(_components.h3, {
    id: "context"
  }, React.createElement(_components.code, null, "Context")), "\n", React.createElement(_components.p, null, "The React context used in Theme UI."), "\n", React.createElement(_components.h3, {
    id: "css"
  }, React.createElement(_components.code, null, "css")), "\n", React.createElement(_components.p, null, "As an alternative to using the ", React.createElement(_components.code, null, "sx"), " prop, the ", React.createElement(_components.code, null, "css"), " utility is intended for use\nwith the Emotion ", React.createElement(_components.code, null, "css"), " prop. It will attempt to use values from the ", React.createElement(_components.code, null, "theme"), "\nobject for many common CSS properties, and will fall back to raw values when\nthere is no corresponding theme value."), "\n", React.createElement(_components.p, null, "To see a list of supported CSS properties, see the ", React.createElement(_components.a, {
    href: "/sx-prop"
  }, React.createElement(_components.code, null, "sx"), " prop docs"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "css(styleObject)(theme)\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Usage:")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div css={css(styles)} />\n")), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Argument"), React.createElement(_components.th, null, "Type"), React.createElement(_components.th, null, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "styleObject")), React.createElement(_components.td, null, "Object"), React.createElement(_components.td, null, "a theme-aware style object with support for responsive array values and Styled System prop shortcuts")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "theme")), React.createElement(_components.td, null, "Object"), React.createElement(_components.td, null, "the Emotion theming context object")))), "\n", React.createElement(_components.h3, {
    id: "get"
  }, React.createElement(_components.code, null, "get")), "\n", React.createElement(_components.p, null, "An existential getter function used internally to extract a field (or object)\nfrom a theme object."), "\n", React.createElement(_components.h3, {
    id: "getobjectwithvariants"
  }, React.createElement(_components.code, null, "getObjectWithVariants")), "\n", React.createElement(_components.p, null, "Similar to the ", React.createElement(_components.code, null, "get"), " function above, but will also resolve any ", React.createElement(_components.code, null, "variant"), " field\nif found inside the object."), "\n", React.createElement(_components.h3, {
    id: "merge"
  }, React.createElement(_components.code, null, "merge")), "\n", React.createElement(_components.p, null, "A function to deeply merge themes."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Usage")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import dark from '@theme-ui/preset-dark'\n\nconst theme = merge(dark, {\n  fontWeights: {\n    body: 500,\n    heading: 700,\n    bold: 900,\n  },\n})\n")), "\n", React.createElement(_components.h3, {
    id: "initializecolormode"
  }, React.createElement(_components.code, null, "InitializeColorMode")), "\n", React.createElement(_components.p, null, "this component can be rendered in HTML before the main application to help\nprevent the flash of colors that can occur during rehydration."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Usage")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<InitializeColorMode />\n")), "\n", React.createElement(_components.p, null, "This component is used internally by\n", React.createElement(_components.a, {
    href: "/packages/gatsby-plugin"
  }, React.createElement(_components.code, null, "gatsby-plugin-theme-ui")), ", but if you’re using Next.js\nor another React framework with static or server-side rendering, you’ll want to\nadd it to the root level of your application."), "\n", React.createElement(_components.h2, {
    id: "components"
  }, "Components"), "\n", React.createElement(_components.p, null, "For component API docs, see ", React.createElement(_components.a, {
    href: "/components"
  }, "Components"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
