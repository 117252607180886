/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "hooks"
  }, "Hooks"), "\n", React.createElement(_components.p, null, "Theme UI includes hooks for changing the color mode state and for using the Theme UI context directly."), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "To learn more about how React Hooks work, please see the ", React.createElement(_components.a, {
    href: "https://reactjs.org/docs/hooks-intro.html"
  }, "official React docs"), ".")), "\n", React.createElement(_components.h2, {
    id: "usethemeui"
  }, React.createElement(_components.code, null, "useThemeUI")), "\n", React.createElement(_components.p, null, "To access the ", React.createElement(_components.code, null, "context"), " object directly in a component, use the ", React.createElement(_components.code, null, "useThemeUI"), " hook."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { useThemeUI } from 'theme-ui'\n\nexport default (props) => {\n  const context = useThemeUI()\n  const { theme, components, colorMode, setColorMode } = context\n\n  return <pre>{JSON.stringify(theme, null, 2)}</pre>\n}\n")), "\n", React.createElement(_components.h2, {
    id: "usecolormode"
  }, React.createElement(_components.code, null, "useColorMode")), "\n", React.createElement(_components.p, null, "To change the color mode state in your application, use the ", React.createElement(_components.code, null, "useColorMode"), " hook."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { useColorMode } from 'theme-ui'\n\nexport default (props) => {\n  const [colorMode, setColorMode] = useColorMode()\n  return (\n    <button\n      onClick={(e) => {\n        setColorMode(colorMode === 'light' ? 'dark' : 'light')\n      }}>\n      Toggle {colorMode === 'light' ? 'Dark' : 'Light'}\n    </button>\n  )\n}\n")), "\n", React.createElement(_components.p, null, "Learn more in the ", React.createElement(_components.a, {
    href: "/color-modes"
  }, "color modes docs"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
