/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    a: "a",
    pre: "pre",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "styling-mdx-content"
  }, "Styling MDX Content"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "theme.styles"), " object combined with ", React.createElement(_components.code, null, "useThemeStylesWithMdx"), " hook is the\nprimary way to style content in MDX documents. This allows you to add\ntypographic styles to Markdown without the need to pollute the global scope.\nStyles within this object have access to other values in the theme object."), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.a, {
    href: "https://mdxjs.com/packages/react/#use"
  }, React.createElement(_components.code, null, "MDXProvider")), " above the level\nof MDX pages, or at the root level of your project, such as in ", React.createElement(_components.code, null, "pages/_app.js"), "\nfor Next.js."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { MDXProvider, useMDXComponents } from '@mdx-js/react'\nimport { useThemedStylesWithMdx } from '@theme-ui/mdx'\nimport { ThemeUIProvider } from 'theme-ui'\n\nconst theme = {\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n  },\n  fonts: {\n    body: 'system-ui, sans-serif',\n    heading: 'Georgia, serif',\n  },\n  fontWeights: {\n    heading: 700,\n  },\n  styles: {\n    h1: {\n      fontSize: 32,\n      fontFamily: 'heading',\n      fontWeight: 'heading',\n      color: 'primary',\n      mt: 4,\n      mb: 2,\n    },\n  },\n}\n\nfunction Provider({ children, theme, components }) {\n  const componentsWithStyles = useThemedStylesWithMdx(\n    useMDXComponents(components)\n  )\n\n  return (\n    <ThemeUIProvider theme={theme}>\n      <MDXProvider components={componentsWithStyles}>{children}</MDXProvider>\n    </ThemeUIProvider>\n  )\n}\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "These styles will ", React.createElement(_components.strong, null, "not"), " apply to HTML elements used outside of MDX. To reuse\nthese styles outside of MDX, see the ", React.createElement(_components.a, {
    href: "/mdx/themed"
  }, "Themed component"), " docs.")), "\n", React.createElement(_components.h2, {
    id: "typographyjs"
  }, "Typography.js"), "\n", React.createElement(_components.p, null, "To use ", React.createElement(_components.a, {
    href: "https://github.com/KyleAMathews/typography.js"
  }, "Typography.js"), " themes with Theme UI, install the\n", React.createElement(_components.code, null, "@theme-ui/typography"), " package and any Typography.js theme."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i @theme-ui/typography typography-theme-wordpress-2016\n")), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "toTheme"), " utility to convert the theme for use in Theme UI."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme with Typography.js\nimport { toTheme } from '@theme-ui/typography'\nimport wordpress2016 from 'typography-theme-wordpress-2016'\nimport merge from 'lodash.merge'\n\nconst typography = toTheme(wordpress2016)\n\nexport default merge(typography, {\n  // optional style overrides go here\n})\n")), "\n", React.createElement(_components.p, null, "Content in MDX documents will be rendered with styles from the Typography.js\ntheme. The ", React.createElement(_components.code, null, "toTheme"), " function accepts the same\n", React.createElement(_components.a, {
    href: "https://github.com/KyleAMathews/typography.js#configuration"
  }, "Typography.js configuration options"), "\nas the core library, allowing you to create custom themes with this approach as\nwell."), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "Theme UI does not currently use colors from Typography.js.")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "To style content that is not generated with MDX with ", React.createElement(_components.code, null, "theme.styles"), ", such as\nfrom a CMS or Markdown, see the ", React.createElement(_components.a, {
    href: "/api/#base-styles"
  }, React.createElement(_components.code, null, "BaseStyles")), " component.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
