/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "flexbox-sidebar"
  }, "Flexbox Sidebar"), "\n", React.createElement(_components.p, null, "Create a sidebar layout with flexbox that automatically stacks when the container is narrow."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "",
    xray: ""
  }, "<div\n  sx={{\n    display: 'flex',\n    flexWrap: 'wrap',\n  }}>\n  <aside\n    sx={{\n      flexGrow: 1,\n      flexBasis: 'sidebar',\n    }}>\n    Sidebar\n  </aside>\n  <main\n    sx={{\n      flexGrow: 99999,\n      flexBasis: 0,\n      minWidth: 320,\n    }}>\n    Main\n  </main>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme\nexport default {\n  sizes: {\n    sidebar: 256,\n  },\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    sx={{\n      display: 'flex',\n      flexWrap: 'wrap',\n    }}>\n    <aside\n      sx={{\n        flexGrow: 1,\n        flexBasis: 'sidebar',\n      }}>\n      Sidebar\n    </aside>\n    <main\n      sx={{\n        flexGrow: 99999,\n        flexBasis: 0,\n        minWidth: 320,\n      }}>\n      {props.children}\n    </main>\n  </div>\n)\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
