/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    pre: "pre",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "css-grid"
  }, "CSS Grid"), "\n", React.createElement(_components.p, null, "CSS Grid Layout is a powerful way to handle complex two-dimensional layouts.\nUsing Theme UI's ", React.createElement(_components.code, null, "sx"), " prop gives you quick access to your space scale as well as\na quick way to change styles responsively."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<div\n  sx={{\n    display: 'grid',\n    gridGap: 4,\n    gridTemplateColumns: ['auto', '1fr 256px'],\n  }}\n>\n  <main>Main</main>\n  <aside>Sidebar</aside>\n</div>\n")), "\n", React.createElement(_components.p, null, "Use a wrapping ", React.createElement(_components.code, null, "<div>"), " element with the ", React.createElement(_components.code, null, "sx"), " prop to control the layout of\ndirect child elements."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    sx={{\n      display: 'grid',\n      gridGap: 4, // theme.space[4]\n      // use arrays for mobile-first responsive styles\n      gridTemplateColumns: [\n        'auto', // default to a stacked layout on small screens\n        '1fr 256px', // use columns for larger screens\n      ],\n    }}\n  >\n    <main>{props.children}</main>\n    <div>Sidebar</div>\n  </div>\n)\n")), "\n", React.createElement(_components.p, null, "See also: ", React.createElement(_components.a, {
    href: "/components/grid"
  }, "Grid component")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
