/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    code: "code",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "custom-cacheprovider"
  }, "Custom CacheProvider"), "\n", React.createElement(_components.h2, {
    id: "style-container"
  }, "Style container"), "\n", React.createElement(_components.p, null, "You may come across a situation where you want to inject the generated styles\ninto a different element than the current document head (an iframe perhaps)."), "\n", React.createElement(_components.p, null, "By using the CacheProvider from ", React.createElement(_components.code, null, "@emotion/react"), " and ", React.createElement(_components.code, null, "createCache"), " from\n", React.createElement(_components.code, null, "@emotion/cache"), " you can specify the target container element."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { ThemeUIProvider } from 'theme-ui'\nimport { CacheProvider } from '@emotion/react'\nimport createCache from '@emotion/cache'\n\n/**\n * @see https://emotion.sh/docs/@emotion/cache\n */\nconst cache = createCache({\n  container: document.getElementById('my-cool-iframe'),\n})\n\nconst theme = {\n  colors: {\n    text: 'tomato',\n  },\n}\n\nexport default ({ children }) => {\n  return (\n    <CacheProvider value={cache}>\n      <ThemeUIProvider theme={theme}>{children}</ThemeUIProvider>\n    </CacheProvider>\n  )\n}\n")), "\n", React.createElement(_components.h3, {
    id: "with-react-frame-component"
  }, "With react-frame-component"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { ThemeUIProvider } from 'theme-ui'\nimport { CacheProvider } from '@emotion/react'\nimport createCache from '@emotion/cache'\nimport Iframe, { FrameContextConsumer } from 'react-frame-component'\n\nconst theme = {\n  colors: {\n    text: 'tomato',\n  },\n}\n\nexport default ({ children }) => {\n  return (\n    <Iframe initialContent=\"IFRAME_CONTENT\">\n      <FrameContextConsumer>\n        {(frameContext) => {\n          const cache = createCache({\n            container: frameContext.document.head,\n          })\n          return (\n            <CacheProvider value={cache}>\n              <ThemeUIProvider theme={theme}>{children}</ThemeUIProvider>\n            </CacheProvider>\n          )\n        }}\n      </FrameContextConsumer>\n    </Iframe>\n  )\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
