/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "styled"
  }, "Styled"), "\n", React.createElement(_components.p, null, "As of Theme UI 0.6, ", React.createElement(_components.code, null, "Styled"), " components dict was renamed to ", React.createElement(_components.code, null, "Themed"), " to avoid\nconfusion with ", React.createElement(_components.code, null, "styled"), " components constructor function from ", React.createElement(_components.code, null, "@emotion/styled"), "."), "\n", React.createElement(_components.p, null, "As of Theme UI 0.15, ", React.createElement(_components.code, null, "Themed"), " components have moved to the optional\n", React.createElement(_components.code, null, "@theme-ui/mdx"), " package."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/mdx/themed"
  }, "Go to the docs for Themed"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
