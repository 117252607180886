/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Cards} from '../..';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    code: "code",
    pre: "pre",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "mdx"
  }, "MDX"), "\n", React.createElement(_components.p, null, "Theme UI is designed to work seamlessly with ", React.createElement(_components.a, {
    href: "https://mdxjs.com/"
  }, "MDX"), ", a format\nallowing you to write JSX in your Markdown files & use Markdown files as React\ncomponents. This makes it easy to style text content with Theme UI and add\nthemed, interactive components like charts or sliders to content without writing\nprose in JSX."), "\n", React.createElement(_components.p, null, "While many Theme UI projects use MDX, as of ", React.createElement(_components.code, null, "v0.15"), " it’s not included in the\ndefault ", React.createElement(_components.code, null, "theme-ui"), " package to reduce bundle size. Install the ", React.createElement(_components.code, null, "@theme-ui/mdx"), "\npackage alongside ", React.createElement(_components.code, null, "theme-ui"), " to use MDX with Theme UI."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i @theme-ui/mdx @mdx-js/react\n")), "\n", React.createElement(Cards, {
    width: 320
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/mdx/styling"
  }, "Styling MDX Content"), " Overview of using Theme UI components with\nMDX"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/mdx/themed"
  }, "Themed"), " Use Theme UI text styles outside MDX directly"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/mdx/components"
  }, "MDX Components"), " Add custom React components to the MDX"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/mdx/layout-components"
  }, "MDX Layout Components"), " Use Theme UI to create MDX\nlayouts"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/mdx/syntax-highlighting"
  }, "Syntax Highlighting"), " Add syntax highlighting to MDX\ncode blocks"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/mdx/linked-headings"
  }, "Linked Headings"), " Add linked headings to MDX"), "\n")), "\n", React.createElement(_components.p, null, "For the full API reference,\n", React.createElement(_components.a, {
    href: "/packages/mdx"
  }, "visit the ", React.createElement(_components.code, null, "@theme-ui/mdx"), " package docs"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
