/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "flexbox-layout"
  }, "Flexbox Layout"), "\n", React.createElement(_components.p, null, "Create a simple page layout component with header and footer."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true",
    xray: "true"
  }, "<div\n  sx={{\n    display: 'flex',\n    flexDirection: 'column',\n    // set this to `minHeight: '100vh'` for full viewport height\n    minHeight: 256,\n  }}>\n  <header\n    sx={{\n      width: '100%',\n    }}>\n    Header\n  </header>\n  <main\n    sx={{\n      width: '100%',\n      flex: '1 1 auto',\n    }}>\n    Main\n  </main>\n  <footer\n    sx={{\n      width: '100%',\n    }}>\n    Footer\n  </footer>\n</div>\n")), "\n", React.createElement(_components.p, null, "Flexbox styles are used to ensure the footer always renders at the bottom of the page, even when there isn't enough content to fill the viewport."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    sx={{\n      display: 'flex',\n      flexDirection: 'column',\n      minHeight: '100vh',\n    }}>\n    <header\n      sx={{\n        width: '100%',\n      }}>\n      Header\n    </header>\n    <main\n      sx={{\n        width: '100%',\n        flex: '1 1 auto',\n      }}>\n      {props.children}\n    </main>\n    <footer\n      sx={{\n        width: '100%',\n      }}>\n      Footer\n    </footer>\n  </div>\n)\n")), "\n", React.createElement(_components.h2, {
    id: "centered-containers"
  }, "Centered Containers"), "\n", React.createElement(_components.p, null, "Centered, max-width containers can be added to the layout."), "\n", React.createElement(_components.p, null, "First, add a ", React.createElement(_components.code, null, "container"), " size to your theme.\nThis will be used for the max-width of the content."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme\nexport default {\n  sizes: {\n    container: 768,\n  },\n}\n")), "\n", React.createElement(_components.p, null, "Next create a ", React.createElement(_components.code, null, "Container"), " component and use it within the layout."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nconst Container = (props) => (\n  <div\n    {...props}\n    sx={{\n      maxWidth: 'container',\n      mx: 'auto',\n      px: 3,\n    }}\n  />\n)\n\nexport default (props) => (\n  <div\n    sx={{\n      display: 'flex',\n      flexDirection: 'column',\n      minHeight: '100vh',\n    }}>\n    <header\n      sx={{\n        width: '100%',\n      }}>\n      <Container>Header</Container>\n    </header>\n    <main\n      sx={{\n        width: '100%',\n        flex: '1 1 auto',\n      }}>\n      <Container>{props.children}</Container>\n    </main>\n    <footer\n      sx={{\n        width: '100%',\n      }}>\n      <Container>Footer</Container>\n    </footer>\n  </div>\n)\n")), "\n", React.createElement(_components.h2, {
    id: "make-it-themeable"
  }, "Make it Themeable"), "\n", React.createElement(_components.p, null, "Add ", React.createElement(_components.code, null, "variant"), " keys to the layout components to allow the styles to be customized in the theme object."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nconst Container = (props) => (\n  <div\n    {...props}\n    sx={{\n      maxWidth: 'container',\n      mx: 'auto',\n      px: 3,\n    }}\n  />\n)\n\nexport default (props) => (\n  <div\n    sx={{\n      display: 'flex',\n      flexDirection: 'column',\n      minHeight: '100vh',\n      variant: 'layout.root',\n    }}>\n    <header\n      sx={{\n        width: '100%',\n        variant: 'layout.header',\n      }}>\n      <Container>Header</Container>\n    </header>\n    <main\n      sx={{\n        width: '100%',\n        flex: '1 1 auto',\n        variant: 'layout.main',\n      }}>\n      <Container>{props.children}</Container>\n    </main>\n    <footer\n      sx={{\n        width: '100%',\n        variant: 'layout.footer',\n      }}>\n      <Container>Footer</Container>\n    </footer>\n  </div>\n)\n")), "\n", React.createElement(_components.p, null, "With the ", React.createElement(_components.code, null, "variant"), " keys added above, you can adjust the layout styles at the theme level."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme\nexport default {\n  sizes: {\n    container: 768,\n  },\n  layout: {\n    header: {\n      color: 'white',\n      bg: 'black',\n    },\n    footer: {\n      bg: 'gray',\n    },\n  },\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
