import { jsx } from '@theme-ui/core';
import { css, get } from '@theme-ui/css';
import { useMemo } from 'react';

// mdx components
const tags = ['p', 'b', 'i', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'pre', 'code', 'ol', 'ul', 'li', 'blockquote', 'hr', 'em', 'table', 'tr', 'th', 'td', 'em', 'strong', 'del',
// mdx
'inlineCode', 'thematicBreak',
// other
'div',
// theme-ui
'root'];
const aliases = {
  inlineCode: 'code',
  thematicBreak: 'hr',
  root: 'div'
};
const isAlias = x => x in aliases;
const alias = n => isAlias(n) ? aliases[n] : n;

/**
 * Extracts styles from `theme.styles` object
 */
const themed = key => theme => css(get(theme, `styles.${key}`))(theme);
const createThemedComponent = (name, variant) => {
  const variantStyles = themed(variant);
  const component = props => {
    const extraStyles = {};
    if (name === 'th' || name === 'td') {
      const {
        align
      } = props;
      if (align !== 'char') extraStyles.textAlign = align;
    }
    return jsx(name, {
      ...props,
      css: [props.css, variantStyles, extraStyles].filter(Boolean)
    });
  };
  component.displayName = `Themed(${name})`;
  return component;
};
const defaultMdxComponents = {};
const Themed = {};
tags.forEach(tag => {
  const component = createThemedComponent(alias(tag), tag);
  defaultMdxComponents[tag] = component;
  Themed[tag] = component;
});

function wrapComponent(value, key) {
  const component = props => jsx(value, {
    ...props,
    css: themed(key)
  });
  component.displayName = "MdxComponents('" + key + "')";
  return component;
}

/** @internal */
const wrapComponentsWithThemeStyles = comps => {
  const next = {
    ...defaultMdxComponents
  };

  // We enrich user's components with the styles from theme.styles.
  // Example: `components.p` will get the styles from `theme.styles.p` as className.
  for (const key of Object.keys(comps)) {
    const value = comps[key];
    next[key] = typeof value === 'object' ? wrapComponentsWithThemeStyles(value) : wrapComponent(value, key);
  }
  return next;
};

/**
 * @example
 * import { MDXProvider, useMDXComponents } from '@mdx-js/react'
 *
 * function MyMdxProvider({ children }) {
 *   const components = useThemedStylesWithMdx(useMDXComponents())
 *
 *   return <MDXProvider components={components}>{children}</MDXProvider>
 * }
 */
function useThemedStylesWithMdx(outerComponents) {
  return useMemo(() => wrapComponentsWithThemeStyles({
    ...defaultMdxComponents,
    ...outerComponents
  }), [outerComponents]);
}

export { Themed, defaultMdxComponents, themed, useThemedStylesWithMdx };
