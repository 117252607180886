/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "alert"
  }, "Alert"), "\n", React.createElement(_components.p, null, "Component for displaying messages, notifications, or other application state."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Alert } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Alert>\n  Beep boop, this is an alert!\n  <Close ml=\"auto\" mr={-2} />\n</Alert>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Alert variant='secondary' mb={2}>Secondary</Alert>\n<Alert variant='accent' mb={2}>Accent</Alert>\n<Alert variant='highlight' mb={2}>Highlight</Alert>\n")), "\n", React.createElement(_components.p, null, "If screen readers should immediately start reading the alert aloud,\n", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Alert_Role"
  }, "add ", React.createElement(_components.code, null, "role=\"alert\"")), "."), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Alert variants can be defined in ", React.createElement(_components.code, null, "theme.alerts"), ".\nThe Alert component uses ", React.createElement(_components.code, null, "theme.alerts.primary"), " as its default variant."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme variants\n{\n  alerts: {\n    primary: {\n      color: 'background',\n      bg: 'primary',\n    },\n    muted: {\n      color: 'text',\n      bg: 'muted',\n    },\n  },\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
