/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    a: "a",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "grid"
  }, "Grid"), "\n", React.createElement(_components.p, null, "CSS grid layout component to arrange direct child elements in a tiled grid layout."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "import { Grid } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Grid width={[128, null, 192]}>\n  <Box bg=\"primary\">Box</Box>\n  <Box bg=\"muted\">Box</Box>\n  <Box bg=\"primary\">Box</Box>\n  <Box bg=\"muted\">Box</Box>\n</Grid>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Grid gap={2} columns={[2, null, 4]}>\n  <Box bg=\"primary\">Box</Box>\n  <Box bg=\"muted\">Box</Box>\n  <Box bg=\"primary\">Box</Box>\n  <Box bg=\"muted\">Box</Box>\n</Grid>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Grid gap={2} columns={[2, '1fr 2fr']}>\n  <Box bg=\"primary\">Box</Box>\n  <Box bg=\"muted\">Box</Box>\n  <Box bg=\"primary\">Box</Box>\n  <Box bg=\"muted\">Box</Box>\n</Grid>\n")), "\n", React.createElement(_components.h2, {
    id: "props"
  }, "Props"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "Grid"), " component includes custom props for adjusting the grid layout.\nEach prop can use the ", React.createElement(_components.a, {
    href: "/sx-prop#responsive-values"
  }, "responsive array"), " syntax for mobile-first responsive styles."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Prop"), React.createElement(_components.th, null, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "width")), React.createElement(_components.td, null, "Minimum width of child elements")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "gap")), React.createElement(_components.td, null, "Space between child elements")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "columns")), React.createElement(_components.td, null, "Number of (equally-sized) columns, or string with ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns"
  }, "grid syntax"), " for the layout (cannot be used in conjunction with the ", React.createElement(_components.code, null, "width"), " prop)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "repeat")), React.createElement(_components.td, null, "Mode for column sizing, used in conjunction with the ", React.createElement(_components.code, null, "width"), " prop. Can either be ", React.createElement(_components.code, null, "fit"), " or ", React.createElement(_components.code, null, "fill"), " (", React.createElement(_components.code, null, "fit"), " is default)")))), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "You can define additional CSS grid layouts by adding variants to the ", React.createElement(_components.code, null, "theme.grids"), " object.\nThese styles can be used to create a wide variety of different reusable layouts."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
