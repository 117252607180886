/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2",
    code: "code",
    em: "em",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "motivation"
  }, "Motivation"), "\n", React.createElement(_components.p, null, "One of the primary motivations behind Theme UI is to make building themeable,\r\nconstraint-based user interfaces in React as simple and as interoperable as\r\npossible. This library builds upon years of work on various libraries, including\r\n", React.createElement(_components.a, {
    href: "https://github.com/basscss/basscss"
  }, "Basscss"), ", ", React.createElement(_components.a, {
    href: "https://tachyons.io"
  }, "Tachyons"), ", ", React.createElement(_components.a, {
    href: "https://rebassjs.org"
  }, "Rebass"), ", ", React.createElement(_components.a, {
    href: "https://styled-system.com"
  }, "Styled System"), ", and various other\r\ninternal style guides and design systems. While some of the ideas encapsulated\r\nwithin this library may seem familiar, the intent here is to combine modern CSS\r\ntooling into a single \"mini-framework\" with good guidance on how to fall into\r\nthe pit of success for creating white-label products, themed component\r\nlibraries, and even full websites."), "\n", React.createElement(_components.h2, {
    id: "mdx"
  }, "MDX"), "\n", React.createElement(_components.p, null, "MDX has, in my opinion, quickly become one of the best ways to render Markdown\r\nin React. The ability to render custom React components for any Markdown element\r\nwith the ", React.createElement(_components.code, null, "MDXProvider"), " is a very powerful API and has the potential to open up\r\nhow Markdown is leveraged in ways I think we'll continue to see evolve over the\r\ncoming years."), "\n", React.createElement(_components.p, null, "While the final rendered HTML ", React.createElement(_components.em, null, "can"), " be styled using global CSS or a wrapping\r\nelement with child selectors, there are certainly drawbacks to this approach,\r\nand they can lead to unexpected styling bugs when composing themes together.\r\nUsing the ", React.createElement(_components.code, null, "MDXProvider"), " to render custom styled components in MDX is a great way\r\nto avoid this, but the overhead for applying styles in this way can be a lot of\r\nwork, even with UI component libraries like Rebass or Material UI. Theme UI\r\nincludes the ", React.createElement(_components.code, null, "theme.styles"), " API as a light abstraction on top of this, that\r\nhopefully feels familiar to people from diverse backgrounds, even those with\r\nlittle or no experience using MDX."), "\n", React.createElement(_components.p, null, "For examples of previous explorations into this idea, see ", React.createElement(_components.a, {
    href: "https://github.com/rebassjs/mdx"
  }, "Rebass MDX"), ", ", React.createElement(_components.a, {
    href: "https://github.com/jxnblk/mdx-style"
  }, "MDX\r\nStyle"), ", and ", React.createElement(_components.a, {
    href: "https://github.com/jxnblk/mdx-blocks"
  }, "MDX Blocks"), "."), "\n", React.createElement(_components.h2, {
    id: "the-css-and-sx-props"
  }, "The ", React.createElement(_components.code, null, "css"), " and ", React.createElement(_components.code, null, "sx"), " Props"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "css"), " prop is a powerful pattern for styling UI in React. It works like the\r\nbuilt-in ", React.createElement(_components.code, null, "style"), " prop, but it includes some of the best parts of the CSS\r\nlanguage, including media queries and pseudo-classes, and can be authored in\r\nnative JavaScript object literal syntax. The ", React.createElement(_components.code, null, "css"), " prop can be leveraged in a\r\nsimilar way to the ", React.createElement(_components.code, null, "styled"), " higher-order component, but also offers more\r\nflexibility when making one-off, context-specific stylistic changes. The ", React.createElement(_components.code, null, "css"), "\r\nprop also avoids some of the\r\n", React.createElement(_components.a, {
    href: "https://github.com/styled-components/styled-components/issues/439"
  }, "pitfalls of mixing CSS properties with HTML attributes"), "."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "sx"), " prop is a light abstraction on top of the ", React.createElement(_components.code, null, "css"), " prop that can serve as\r\na complete replacement and makes it easier to ensure you use values from your\r\nglobal ", React.createElement(_components.code, null, "theme"), " object."), "\n", React.createElement(_components.h2, {
    id: "why-object-literal-syntax"
  }, "Why Object Literal Syntax"), "\n", React.createElement(_components.p, null, "For many, the choice between using object literal syntax for styles versus\r\ntagged template literals comes down to personal preference, but in the case of\r\nTheme UI, there are some fundamental reasons for using native JavaScript types\r\nfor styles."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "theme"), " object itself is an object, and keeping styles in a similar format\r\nhelps reduce the API surface area. Using and parsing strings that represent\r\nembedded DSLs introduces overhead when mapping over key-value pairs. Theme UI\r\navoids this overhead for reasons related to performance, testing, and overall\r\nbundle size. For some of the ", React.createElement(_components.a, {
    href: "https://facebook.github.io/jsx/"
  }, "same reasons"), "\r\nthat React itself uses JSX (i.e. function calls) instead of tagged template\r\nliterals, Theme UI only includes support for authoring CSS with object literal\r\nsyntax. Additionally, using native JavaScript types has many other benefits that\r\nare outside of the scope of this document."), "\n", React.createElement(_components.h2, {
    id: "why-emotion"
  }, "Why Emotion"), "\n", React.createElement(_components.p, null, "While there are many different solutions to handling CSS in JavaScript, Styled\r\nComponents and Emotion have become the most widely-used industry-standard\r\nlibraries. If you're building a custom component library, either Styled\r\nComponents or Emotion should suit your needs just fine."), "\n", React.createElement(_components.p, null, "For Theme UI, the decision was primarily based on these factors:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Emotion's implementation of the ", React.createElement(_components.code, null, "css"), " prop and the custom JSX pragma allows\r\nfor better integration with Theme UI's ", React.createElement(_components.code, null, "sx"), " prop"), "\n", React.createElement(_components.li, null, "The Emotion API includes more lower-level utilities, like ", React.createElement(_components.code, null, "createEmotion"), " that\r\ncould be leveraged when considering how multiple themes could be composed\r\ntogether"), "\n", React.createElement(_components.li, null, "Emotion's theming context is directly available in ", React.createElement(_components.code, null, "@emotion/react"), ", allowing\r\nthis library to leverage React's context API in different ways"), "\n", React.createElement(_components.li, null, "In the case of Theme UI internals, the ", React.createElement(_components.code, null, "styled"), " higher-order component utility\r\nis not necessarily the best API for creating components, and by not including\r\n", React.createElement(_components.code, null, "@emotion/styled"), " in the core package the bundle size is kept to a minimum –\r\ni.e. most of the same things can be achieved with the ", React.createElement(_components.code, null, "css"), " prop"), "\n"), "\n", React.createElement(_components.h2, {
    id: "how-is-this-different-from-styled-system"
  }, "How is this different from Styled System"), "\n", React.createElement(_components.p, null, "Theme UI's ", React.createElement(_components.code, null, "sx"), " prop was inspired by Styled System, and it uses the same theme\r\nspec that Styled System adheres to. Styled System is a much lower-level API that\r\nis not in any way coupled to React or Emotion. For example, Styled System works\r\nwith Node.js, Vue, Svelte, and many other libraries. Theme UI is intended to be\r\na higher-level abstraction specifically for use in React applications."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
