/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "resources"
  }, "Resources"), "\n", React.createElement(_components.p, null, "Tools, themes, extensions and examples built by the Theme UI community."), "\n", React.createElement(_components.h2, {
    id: "custom-themes"
  }, "Custom themes"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/tabetalt/kit"
  }, React.createElement(_components.strong, null, "@tabetalt/kit")), ":\nUsed in Tabetalt’s e-commerce platform."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://theme.hackclub.com"
  }, React.createElement(_components.strong, null, "@hackclub/theme")), ":\nUsed on ", React.createElement(_components.a, {
    href: "https://hackclub.com/"
  }, "Hack Club’s"), " websites.", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/the-innovation-circuit/theme"
  }, "Forked"), " by ", React.createElement(_components.a, {
    href: "https://innovationcircuit.com"
  }, "the Innovation Circuit"), "."), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/carbonplan/theme"
  }, React.createElement(_components.strong, null, "@carbonplan/theme")), ":\nUsed on ", React.createElement(_components.a, {
    href: "https://carbonplan.org"
  }, "Carbonplan’s"), " websites."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/UNDataForum/design-system/tree/master/packages/preset"
  }, React.createElement(_components.strong, null, "@undataforum/preset")), ":\nUsed on ", React.createElement(_components.a, {
    href: "https://undataforum.org/"
  }, "United Nations World Data Forum"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/lachlanjc/theme-ui-preset-geist"
  }, React.createElement(_components.strong, null, "theme-ui-preset-geist")), ":\nInspired by/adapted from ", React.createElement(_components.a, {
    href: "https://geist-ui.dev"
  }, "Geist UI"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/nice-boys/theme-ui-preset-nice-boys"
  }, React.createElement(_components.strong, null, "theme-ui-preset-nice-boys"))), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/indivorg/theme"
  }, React.createElement(_components.strong, null, "indivorg/theme")), ":\nUsed in Indiv's mobile and web applications."), "\n"), "\n", React.createElement(_components.h2, {
    id: "component-kits"
  }, "Component kits"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/ccontrols/component-controls/tree/master/ui/components"
  }, React.createElement(_components.strong, null, "@component-controls/components")), ":\nComponents built for Theme UI."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/cartolab-gis/elements"
  }, React.createElement(_components.strong, null, "@cartolab/elements")), ":\nThemeable React components for map-based web applications."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/carbonplan/components"
  }, React.createElement(_components.strong, null, "@carbonplan/components")), ":\nShared React components for ", React.createElement(_components.a, {
    href: "https://carbonplan.org"
  }, "Carbonplan"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/UNDataForum/design-system/tree/master/packages/components"
  }, React.createElement(_components.strong, null, "@undataforum/components")), ":\nShared React components for UN Data Forum."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/pixelmord/prestyled"
  }, React.createElement(_components.strong, null, "prestyled"))), "\n"), "\n", React.createElement(_components.h2, {
    id: "theme-previewers"
  }, "Theme previewers"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://theme-ui-gallery.netlify.app"
  }, React.createElement(_components.strong, null, "Theme UI Gallery")), ":\nThemed components live previewer."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.skin-ui.com/"
  }, React.createElement(_components.strong, null, "Skin UI")), ":\nTheme UI live previewer & code editor."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oh-my-theme.com/"
  }, React.createElement(_components.strong, null, "Oh My Theme")), ":\nTheme UI preset previewer."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://components.ai/theme-ui/"
  }, React.createElement(_components.strong, null, "Components.ai Theme Generator")), ":\nGenerate, preview, & export random themes."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/Palmaswell/controlled-styled-editor"
  }, React.createElement(_components.strong, null, "Controlled Styled Editor")), ":\nEdit your React components based on System UI constrained design principles."), "\n"), "\n", React.createElement(_components.h2, {
    id: "project-templates"
  }, "Project templates"), "\n", React.createElement(_components.h3, {
    id: "nextjs"
  }, "Next.js"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/lachlanjc/next-theme-starter"
  }, React.createElement(_components.strong, null, "@lachlanjc/next-theme-starter")), ":\nNext.js project for getting started with MDX & Theme UI."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/hackclub/theme-starter"
  }, React.createElement(_components.strong, null, "@hackclub/theme-starter")), ":\nNext.js project using ", React.createElement(_components.a, {
    href: "https://theme.hackclub.com"
  }, "Hack Club Theme"), " & MDX."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/johnpolacek/nextjs-mdx-blog-starter"
  }, React.createElement(_components.strong, null, "Next.js MDX Blog Starter")), ":\nNext.js blog starter for building blogs with MDX, Theme UI, & Vercel."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mattjennings/nextjs-starter"
  }, React.createElement(_components.strong, null, "@mattjennings/nextjs-starter")), ":\nNext.js project using Theme UI & Framer Motion."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/freddydumont/theme-ui-next-boilerplate"
  }, React.createElement(_components.strong, null, "Next.js Frontend Starter Pack")), ":\nNext.js site template using TypeScript, Jest, & Theme UI."), "\n"), "\n", React.createElement(_components.h3, {
    id: "remix"
  }, "Remix"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/remix-run/remix/tree/main/examples/theme-ui"
  }, React.createElement(_components.strong, null, "Remix Examples: Theme UI"))), "\n"), "\n", React.createElement(_components.h3, {
    id: "gatsby"
  }, "Gatsby"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/LekoArts/gatsby-themes"
  }, React.createElement(_components.strong, null, "LekoArts Gatsby Themes")), ":\nCollection of Gatsby themes built with Theme UI."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/hew/gatsby-theme-ui-starter"
  }, React.createElement(_components.strong, null, "@hew/gatsby-theme-ui-starter")), ":\nGatsby.js project using Netlify Forms."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/codebushi/gatsby-theme-document"
  }, React.createElement(_components.strong, null, "@codebushi/gatsby-theme-document")), ":\nGatsby.js theme for documentation."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/johno/gatsby-theme-glossary"
  }, React.createElement(_components.strong, null, "gatsby-theme-glossary")), ":\nGatsby.js theme for a glossary to define terminology."), "\n"), "\n", React.createElement(_components.h2, {
    id: "other-projects"
  }, "Other projects"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/samjbmason/theme-ui-native"
  }, React.createElement(_components.strong, null, "theme-ui-native")), ":\nTheme UI adapted for React Native projects."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/nandorojo/dripsy"
  }, React.createElement(_components.strong, null, "Dripsy")), ":\nA responsive, theme-based design system for Expo + React Native Web."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/LekoArts/figma-theme-ui"
  }, React.createElement(_components.strong, null, "Theme UI plugin for Figma")), ":\n", React.createElement(_components.a, {
    href: "https://www.figma.com/c/plugin/797015796747379907"
  }, "Figma plugin"), " to convert a Theme UI config to Figma Styles."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/fabulascode/theme-ui-modifiers"
  }, React.createElement(_components.strong, null, "@fabulas/theme-ui-modifiers")), ":\nLibrary for using BEM-style modifiers on Theme UI components."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mattjennings/react-modal"
  }, React.createElement(_components.strong, null, "@mattjennings/react-modal")), ":\nLibrary for making animated modals with Theme UI & Framer Motion."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://react-layouts.com/"
  }, React.createElement(_components.strong, null, "React Layouts")), ":\nGrab-and-go layouts for React, including Theme UI."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/cobraz/next-theme-ui"
  }, React.createElement(_components.strong, null, "Next Theme UI")), ":\nComponents for using Theme UI with Next.js."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://0phoff.github.io/MDXP"
  }, React.createElement(_components.strong, null, "MDXP")), ":\nMDX-based presentation deck library using the System UI Theme Specification for theming."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
