"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    img: "img",
    code: "code",
    pre: "pre",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uicore",
      children: "@theme-ui/core"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://bundlephobia.com/package/@theme-ui/core",
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://badgen.net/bundlephobia/minzip/@theme-ui/core",
          alt: "Minified Size on Bundlephobia"
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "@theme-ui/core"
      }), " provides minimal React support for lightweight usage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/core @emotion/react\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "usage",
      children: "Usage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/** @jsxImportSource @theme-ui/core */\n\nimport { ThemeProvider } from '@theme-ui/core'\n\nexport function App() {\n  return (\n    <ThemeProvider theme={{ colors: { primary: '#33e' } }}>\n      <h1 sx={{ color: 'primary' }}>Hello</h1>\n    </ThemeProvider>\n  )\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you already use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme-ui"
      }), " package, the batteries-included version of Theme\nUI, you don't need to install ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@theme-ui/core"
      }), " separately — it's already in your\nnode_modules, and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme-ui"
      }), " reexports everything from it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "api",
      children: "API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "jsx"
        }), " — JSX function to create React elements supporting ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sx"
        }), " prop"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ThemeProvider"
        }), " — a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://reactjs.org/docs/context.html",
          children: "context provider"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "useThemeUI"
        }), " — a hook to access your theme"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "merge"
        }), " — an utility function to deeply merge themes together"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@theme-ui/core"
      }), " doesn’t add global or root styles to\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<html>"
      }), "/", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<body>"
      }), ". Refer to the\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://theme-ui.com/guides/global-styles",
        children: "Global Styles docs"
      }), " for how to add\nglobal styles."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "jsx-runtime",
      children: "JSX Runtime"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@theme-ui/core"
      }), " defines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "jsx"
      }), " function and types for it, you can use it to\nconfigure JSX runtime without installing ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme-ui"
      }), " package."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      id: "entry-point-jsx-runtime",
      children: ["Entry point ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/jsx-runtime"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "jsx"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "jsxs"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      id: "entry-point-jsx-dev-runtime",
      children: ["Entry point ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/jsx-dev-runtime"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "jsxDEV"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = MDXContent;
exports.default = _default;