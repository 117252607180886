/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "input"
  }, "Input"), "\n", React.createElement(_components.p, null, "Form input component"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Input } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    live: "true"
  }, "<Input defaultValue=\"Hello\" />\n")), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Input variants can be defined in ", React.createElement(_components.code, null, "theme.forms"), " and the component uses the\n", React.createElement(_components.code, null, "theme.forms.input"), " variant by default."), "\n", React.createElement(_components.h2, {
    id: "autocomplete-styles"
  }, "Autocomplete styles"), "\n", React.createElement(_components.p, null, "Background color of autofilled inputs defaults to ", React.createElement(_components.code, null, "background"), ", and can be\nchanged by setting ", React.createElement(_components.code, null, "autofillBackgroundColor"), " prop."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    live: "true"
  }, "<Input autoComplete=\"given-name\" autofillBackgroundColor=\"highlight\" />\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
