/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "color-mode-toggles"
  }, "Color Mode Toggles"), "\n", React.createElement(_components.p, null, "Create a button to toggle between light and dark ", React.createElement(_components.a, {
    href: "/color-modes"
  }, "color modes"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\nimport { useColorMode } from 'theme-ui'\n\nexport default (props) => {\n  const [mode, setMode] = useColorMode()\n\n  return (\n    <button\n      {...props}\n      onClick={(e) => {\n        const next = mode === 'dark' ? 'light' : 'dark'\n        setMode(next)\n      }}\n    />\n  )\n}\n")), "\n", React.createElement(_components.h2, {
    id: "cycling-through-multiple-modes"
  }, "Cycling through multiple modes"), "\n", React.createElement(_components.p, null, "Create a button to cycle through multiple ", React.createElement(_components.a, {
    href: "/color-modes"
  }, "color modes"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\nimport { useColorMode } from 'theme-ui'\n\nconst modes = ['light', 'dark', 'purple', 'pink']\n\nexport default (props) => {\n  const [mode, setMode] = useColorMode()\n\n  return (\n    <button\n      {...props}\n      onClick={(e) => {\n        const index = modes.indexOf(mode)\n        const next = modes[(index + 1) % modes.length]\n        setMode(next)\n      }}\n    />\n  )\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
