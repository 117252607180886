/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "",
    xray: ""
  }, "<div\n  sx={{\n    display: 'grid',\n    gridGap: 3,\n    gridTemplateColumns: 'repeat(2, 1fr)',\n  }}>\n  <div\n    sx={{\n      gridColumnStart: '1',\n      gridColumnEnd: '3',\n    }}>\n    A\n  </div>\n  <div>B</div>\n  <div>C</div>\n</div>\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
