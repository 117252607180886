import * as P from 'polished';
import { get } from '@theme-ui/css';

/**
 * Get color from theme.colors
 */
const getColor = (theme, color) => {
  if (typeof color === 'object') {
    color = Array.isArray(color) ? color[0] : color.__default;
  }
  if (process.env.NODE_ENV !== 'production') {
    if (color && /^var\(--theme-ui-colors-(.+)\)$/.test(color)) {
      throw new Error('A CSS property was passed to `getColor`. ' + '`theme.colors` contains CSS custom properties. ' + 'Use `theme.rawColors` instead.');
    }
  }
  return get(theme, 'rawColors' in theme ? `rawColors.${color}` : `colors.${color}`, color);
};

/**
 * Darken a color by an amount 0–1
 */
const darken = (c, n) => t => P.darken(n, getColor(t, c));
/**
 * Lighten a color by an amount 0–1
 */
const lighten = (c, n) => t => P.lighten(n, getColor(t, c));
/**
 * Rotate the hue of a color by an amount 0–360
 */
const rotate = (c, d) => t => P.adjustHue(d, getColor(t, c));

/**
 * Set the hue of a color to a degree 0–360
 */
const hue = (c, h) => t => P.setHue(h, getColor(t, c));
/**
 * Set the saturation of a color to an amount 0–1
 */
const saturation = (c, s) => t => P.setSaturation(s, getColor(t, c));
/**
 * Set the lightness of a color to an amount 0–1
 */
const lightness = (c, l) => t => P.setLightness(l, getColor(t, c));
/**
 * Desaturate a color by an amount 0–1
 */
const desaturate = (c, n) => t => P.desaturate(n, getColor(t, c));
/**
 * Saturate a color by an amount 0–1
 */
const saturate = (c, n) => t => P.saturate(n, getColor(t, c));

/**
 * Shade a color by an amount 0–1
 */
const shade = (c, n) => t => P.shade(n, getColor(t, c));
/**
 * Tint a color by an amount 0–1
 */
const tint = (c, n) => t => P.tint(n, getColor(t, c));
const transparentize = (c, n) => t => P.transparentize(n, getColor(t, c));
/**
 * Set the transparency of a color to an amount 0-1
 */
const alpha = (c, n) => t => P.rgba(getColor(t, c), n);

/**
 * Mix two colors by a specific ratio
 */
const mix = (a, b, n = 0.5) => t => P.mix(n, getColor(t, a), getColor(t, b));

/**
 * Get the complement of a color
 */
const complement = c => t => P.complement(getColor(t, c));

/**
 * Get the inverted color
 */
const invert = c => t => P.invert(getColor(t, c));

/**
 * Desaturate the color to grayscale
 */
const grayscale = c => desaturate(c, 1);

export { alpha, complement, darken, desaturate, getColor, grayscale, hue, invert, lighten, lightness, mix, rotate, saturate, saturation, shade, tint, transparentize };
