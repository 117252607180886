/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "button"
  }, "Button"), "\n", React.createElement(_components.p, null, "Primitive button component with variants"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Button } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Button mr={2}>Beep</Button>\n<Button variant='secondary'>Boop</Button>\n<Button ml={2} hidden>Hidden</Button>\n")), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Button variants can be defined in the ", React.createElement(_components.code, null, "theme.buttons"), " object.\nThe Button component uses ", React.createElement(_components.code, null, "theme.buttons.primary"), " as its default variant style."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme variants\n{\n  buttons: {\n    primary: {\n      color: 'background',\n      bg: 'primary',\n      '&:hover': {\n        bg: 'text',\n      }\n    },\n    secondary: {\n      color: 'background',\n      bg: 'secondary',\n    },\n  },\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
