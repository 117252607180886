/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    pre: "pre",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "heading"
  }, "Heading"), "\n", React.createElement(_components.p, null, "Primitive heading component, defaults to ", React.createElement(_components.code, null, "<h2>"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Heading } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Heading>Hello</Heading>\n<Heading as='h3'>Subhead</Heading>\n")), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "as"), " prop to set the correct HTML element independent from styling."), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Text style variants can be defined in the ", React.createElement(_components.code, null, "theme.text"), " object.\nThe Heading component uses ", React.createElement(_components.code, null, "theme.text.heading"), " as its default variant style."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme variants\n{\n  text: {\n    caps: {\n      textTransform: 'uppercase',\n      letterSpacing: '0.2em',\n    },\n    heading: {\n      fontFamily: 'heading',\n      fontWeight: 'heading',\n      lineHeight: 'heading',\n    },\n  }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
