"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    h3: "h3"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uimatch-media",
      children: "@theme-ui/match-media"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "React hooks for referencing theme-ui breakpoints."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/match-media\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "usage",
      children: "Usage"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This package provides two React hooks ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useResponsiveValue"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useBreakpointIndex"
      }), " for referencing responsive values outside of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sx"
      }), " prop.\nNote that this is a client-side-only, JavaScript solution."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { useResponsiveValue, useBreakpointIndex } from '@theme-ui/match-media'\n\nconst MyComponent = () => {\n  // Return literal values:\n  const color = useResponsiveValue(['red', 'green', 'blue'])\n  // Or provide a function to access theme values:\n  const themeColor = useResponsiveValue((theme) => [\n    theme.colors.red,\n    theme.colors.green,\n    theme.colors.blue,\n  ])\n  // `useBreakpointIndex` returns the index of the currently matched media query:\n  const index = useBreakpointIndex()\n\n  return (\n    <div>\n      The current color is: {color}, and the current index is: {index}\n    </div>\n  )\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "server-side-rendering",
      children: "Server side rendering"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Each hook also accepts an options object, used to set a default breakpoint index when rendering on the server."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { useResponsiveValue, useBreakpointIndex } from '@theme-ui/match-media'\n\nconst MyComponent = () => {\n  const color = useResponsiveValue(['red', 'green', 'blue'], {\n    defaultIndex: 1,\n  })\n  const index = useBreakpointIndex({ defaultIndex: 0 })\n\n  return (\n    <div>\n      The current color is: {color}, and the current index is: {index}\n      // Gatsby will output: // The current color is: green, and the current index\n      is: 0\n    </div>\n  )\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each hook uses ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "breakpoints[0]"
      }), " by default, if not specified."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { useResponsiveValue, useBreakpointIndex } from '@theme-ui/match-media'\n\nconst MyComponent = () => {\n  const color = useResponsiveValue(['red', 'green', 'blue'])\n  const index = useBreakpointIndex()\n\n  return (\n    <div>\n      The current color is: {color}, and the current index is: {index}\n      // Gatsby will output: // The current color is: red, and the current index\n      is: 0\n    </div>\n  )\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = MDXContent;
exports.default = _default;