/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "select"
  }, "Select"), "\n", React.createElement(_components.p, null, "Form select component"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Select, Box } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    live: "true"
  }, "<Select defaultValue=\"Hello\">\n  <option>Hello</option>\n  <option>Hi</option>\n  <option>Beep</option>\n  <option>Boop</option>\n</Select>\n")), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Select variants can be defined in ", React.createElement(_components.code, null, "theme.forms"), " and the component uses the\n", React.createElement(_components.code, null, "theme.forms.select"), " variant by default."), "\n", React.createElement(_components.h2, {
    id: "custom-arrow-icon"
  }, "Custom arrow icon"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Select\n  arrow={\n    <Box\n      as=\"svg\"\n      xmlns=\"http://www.w3.org/2000/svg\"\n      width=\"24\"\n      height=\"24\"\n      viewBox=\"0 0 24 24\"\n      fill=\"currentcolor\"\n      sx={{\n        ml: -28,\n        alignSelf: 'center',\n        pointerEvents: 'none',\n      }}>\n      <path d=\"M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z\" />\n    </Box>\n  }\n  defaultValue=\"Hello\">\n  <option>Hello</option>\n  <option>Hi</option>\n  <option>Beep</option>\n  <option>Boop</option>\n</Select>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
