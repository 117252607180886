/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    code: "code",
    pre: "pre",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "object-styles"
  }, "Object Styles"), "\n", React.createElement(_components.p, null, "Authoring CSS in JavaScript object literal syntax can be a great way to catch syntax errors before committing code and use other features of JS syntax in styles.\nIf you're not used to writing styles in object literal syntax though, there are a few key differences to using native CSS syntax."), "\n", React.createElement(_components.h2, {
    id: "camel-case"
  }, "Camel case"), "\n", React.createElement(_components.p, null, "All CSS properties should be written in camel case instead of using the hyphenated form.\nFor example, the CSS ", React.createElement(_components.code, null, "background-color"), " property should be written as ", React.createElement(_components.code, null, "backgroundColor"), "."), "\n", React.createElement(_components.h2, {
    id: "values"
  }, "Values"), "\n", React.createElement(_components.p, null, "Strings in JS must be surrounded by either a single quote, double quote or backticks (", React.createElement(_components.code, null, "'"), ", ", React.createElement(_components.code, null, "\""), ", ", React.createElement("inlineCode", null, "`"), ")."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  backgroundColor: 'tomato',\n}\n")), "\n", React.createElement(_components.h2, {
    id: "numbers"
  }, "Numbers"), "\n", React.createElement(_components.p, null, "Numbers should be written without quotes to use the native JS number type.\nNumbers will generally be converted to pixel values by Emotion."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  fontSize: 32,\n}\n")), "\n", React.createElement(_components.p, null, "This also means that you can use JS math expressions to derive values."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  width: (100 / 3) + '%',\n}\n")), "\n", React.createElement(_components.p, null, "To use other CSS units, use strings as values instead."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  fontSize: '2em',\n}\n")), "\n", React.createElement(_components.h2, {
    id: "commas"
  }, "Commas"), "\n", React.createElement(_components.p, null, "All properties should be separated by commas, not semicolons."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  fontSize: 32,\n  backgroundColor: 'tomato',\n}\n")), "\n", React.createElement(_components.h2, {
    id: "pseudo-elements"
  }, "Pseudo elements"), "\n", React.createElement(_components.p, null, "To use the ", React.createElement(_components.code, null, "content"), " property in a pseudo-element with ", React.createElement(_components.code, null, "::before"), " or ", React.createElement(_components.code, null, "::after"), ", be sure to wrap the string value with quotes in order for it to be parsed correctly as CSS."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  \"::before\": {\n    content: '\"\"',\n    display: 'block',\n    width: 32,\n    height: 32,\n    backgroundColor: 'tomato',\n  }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "vendor-prefixes"
  }, "Vendor prefixes"), "\n", React.createElement(_components.p, null, "To use CSS properties with ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Glossary/Vendor_Prefix"
  }, "vendor prefixes"), ",\nomit the dashes and capitalize the first letter of the prefix."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  WebkitTextStroke: 'white',\n  WebkitTextStrokeWidth: '3px',\n  WebkitTextFillColor: 'transparent'\n}\n")), "\n", React.createElement(_components.p, null, "For more on using objects in JS, see ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Working_with_Objects"
  }, "MDN's guide on Working with Objects"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
