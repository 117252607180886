/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "header-a4"
  }, "Header A4"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<header\n  sx={{\n    display: 'grid',\n    gridGap: 3,\n    gridTemplateColumns: 'repeat(3, 1fr)',\n    px: 3,\n    py: 4,\n    alignItems: 'center',\n    variant: 'styles.header',\n  }}>\n  <button\n    title=\"Toggle Menu\"\n    sx={{\n      appearance: 'none',\n      width: 32,\n      height: 32,\n      m: 0,\n      p: 1,\n      color: 'inherit',\n      bg: 'transparent',\n      border: 0,\n      ':focus': {\n        outline: '2px solid',\n      },\n      ':hover': {\n        color: 'primary',\n      },\n    }}>\n    <svg\n      xmlns=\"http://www.w3.org/2000/svg\"\n      width=\"24\"\n      height=\"24\"\n      fill=\"currentcolor\"\n      viewBox=\"0 0 24 24\"\n      sx={{\n        display: 'block',\n        margin: 0,\n      }}>\n      <path d=\"M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z\" />\n    </svg>\n  </button>\n  <div\n    sx={{\n      display: 'flex',\n      alignItems: 'center',\n      justifyContent: 'center',\n    }}>\n    <Link\n      to=\"/\"\n      sx={{\n        variant: 'styles.navlink',\n        px: 3,\n        py: 1,\n        textTransform: 'uppercase',\n        letterSpacing: '0.1em',\n        border: '4px solid',\n        color: 'primary',\n      }}>\n      Home\n    </Link>\n  </div>\n  <div\n    sx={{\n      display: 'flex',\n      alignItems: 'center',\n      justifyContent: 'flex-end',\n    }}>\n    <Link\n      to=\"/blog\"\n      sx={{\n        variant: 'styles.navlink',\n        ml: 3,\n        py: 3,\n      }}>\n      Blog\n    </Link>\n    <Link\n      to=\"/about\"\n      sx={{\n        variant: 'styles.navlink',\n        ml: 3,\n        py: 3,\n      }}>\n      About\n    </Link>\n  </div>\n</header>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
