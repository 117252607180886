/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "header-a1"
  }, "Header A1"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<header\n  sx={{\n    display: 'flex',\n    alignItems: 'center',\n    variant: 'styles.header',\n  }}>\n  <Link\n    to=\"/\"\n    sx={{\n      variant: 'styles.navlink',\n      p: 2,\n    }}>\n    Hello\n  </Link>\n  <div sx={{ mx: 'auto' }} />\n  <Link\n    to=\"/blog\"\n    sx={{\n      variant: 'styles.navlink',\n      p: 2,\n    }}>\n    Blog\n  </Link>\n  <Link\n    to=\"/about\"\n    sx={{\n      variant: 'styles.navlink',\n      p: 2,\n    }}>\n    About\n  </Link>\n</header>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
