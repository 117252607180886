/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "footer-a2"
  }, "Footer A2"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<footer\n  sx={{\n    fontSize: 1,\n    color: 'background',\n    bg: 'text',\n    variant: 'styles.footer',\n  }}>\n  <div\n    sx={{\n      display: 'flex',\n      flexWrap: 'wrap',\n      alignItems: 'center',\n      maxWidth: 768,\n      mx: 'auto',\n      px: 2,\n      py: 4,\n    }}>\n    <Link to=\"/\" sx={{ variant: 'styles.navlink', p: 2 }}>\n      Home\n    </Link>\n    <Link to=\"/\" sx={{ variant: 'styles.navlink', p: 2 }}>\n      Blog\n    </Link>\n    <Link to=\"/\" sx={{ variant: 'styles.navlink', p: 2 }}>\n      About\n    </Link>\n    <div sx={{ mx: 'auto' }} />\n    <div sx={{ p: 2 }}>© 2019 Jane Doe</div>\n  </div>\n</footer>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
