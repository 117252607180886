"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    a: "a",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uipresets",
      children: "@theme-ui/presets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Theme UI includes an optional presets package that can be used as examples or as a starting point for extending your own themes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/presets\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-jsx",
        children: "// example theme\nimport { funk } from '@theme-ui/presets'\n\nexport default {\n  ...funk,\n  styles: {\n    ...funk.styles,\n  },\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view an example of the built-in presets, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://theme-ui.com/demo",
        children: "Demo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Currently, the following presets are available for use:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/base",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "base"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/system",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "system"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/funk",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "funk"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/future",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "future"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/roboto",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "roboto"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/dark",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "dark"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/deep",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "deep"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/swiss",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "swiss"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/tosh",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "tosh"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/bootstrap",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "bootstrap"
          })
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/bulma",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "bulma"
          })
        }), " (WIP)"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/tailwind",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "tailwind"
          })
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://theme-ui.com/presets/sketchy",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "sketchy"
          })
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://themeui-sketchy.netlify.app/",
          children: "Demo website"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "contributing",
      children: "Contributing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["We'd love to have more presets added to the Theme UI project.\nIf you'd like to add a new preset or improve upon the existing ones, please open an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/issues",
        children: "issue"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/pulls",
        children: "pull request"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = MDXContent;
exports.default = _default;