export type SidebarLink = readonly [
  text: string,
  href: string,
  children?: SidebarLink[]
]
export type Sidebar = SidebarLink[]
export const sidebar: Sidebar = [
  [
    'Getting Started',
    '/getting-started',
    [['Getting Started with Gatsby', '/getting-started/gatsby']],
  ],
  ['Theming', '/theming'],
  ['The sx Prop', '/sx-prop'],
  ['Color Modes', '/color-modes'],
  ['Hooks', '/hooks'],
  ['API', '/api'],
  ['Theme Specification', '/theme-spec'],
  ['Demo', '/demo'],
  ['Resources', '/resources'],
  [
    'MDX',
    '/mdx',
    [
      ['Styling MDX', '/mdx/styling'],
      ['Themed', '/mdx/themed'],
      ['Custom Components', '/mdx/components'],
      ['Layout Components', '/mdx/layout-components'],
      ['Syntax Highlighting', '/mdx/syntax-highlighting'],
      ['Linked Headings', '/mdx/linked-headings'],
    ],
  ],
  [
    'Components',
    '/components',
    [
      ['Variants', '/components/variants'],
      ['Alert', '/components/alert'],
      ['AspectImage', '/components/aspect-image'],
      ['AspectRatio', '/components/aspect-ratio'],
      ['Avatar', '/components/avatar'],
      ['Badge', '/components/badge'],
      ['Box', '/components/box'],
      ['Button', '/components/button'],
      ['Card', '/components/card'],
      ['Checkbox', '/components/checkbox'],
      ['Close', '/components/close'],
      ['Container', '/components/container'],
      ['Divider', '/components/divider'],
      ['Donut', '/components/donut'],
      ['Embed', '/components/embed'],
      ['Field', '/components/field'],
      ['Flex', '/components/flex'],
      ['Forms', '/components/forms'],
      ['Grid', '/components/grid'],
      ['Heading', '/components/heading'],
      ['IconButton', '/components/icon-button'],
      ['Image', '/components/image'],
      ['Input', '/components/input'],
      ['Label', '/components/label'],
      ['Link', '/components/link'],
      ['MenuButton', '/components/menu-button'],
      ['Message', '/components/message'],
      ['NavLink', '/components/nav-link'],
      ['Paragraph', '/components/paragraph'],
      ['Progress', '/components/progress'],
      ['Radio', '/components/radio'],
      ['Select', '/components/select'],
      ['Slider', '/components/slider'],
      ['Spinner', '/components/spinner'],
      ['Switch', '/components/switch'],
      ['Text', '/components/text'],
      ['Textarea', '/components/textarea'],
    ],
  ],
  [
    'Packages',
    '/packages',
    [
      ['@theme-ui/css', '/packages/css'],
      ['@theme-ui/core', '/packages/core'],
      ['@theme-ui/components', '/packages/components'],
      ['@theme-ui/mdx', '/packages/mdx'],
      ['@theme-ui/global', '/packages/global'],
      ['@theme-ui/presets', '/packages/presets'],
      ['@theme-ui/prism', '/packages/prism'],
      ['@theme-ui/color', '/packages/color'],
      ['@theme-ui/style-guide', '/packages/style-guide'],
      ['gatsby-plugin-theme-ui', '/packages/gatsby-plugin'],
      ['gatsby-theme-style-guide', '/packages/gatsby-theme-style-guide'],
      ['@theme-ui/typography', '/packages/typography'],
      ['@theme-ui/match-media', '/packages/match-media'],
      ['@theme-ui/tailwind', '/packages/tailwind'],
    ],
  ],
  [
    'Guides',
    '/guides',
    [
      ['How it Works', '/guides/how-it-works'],
      ['JSX Pragma', '/guides/jsx-pragma'],
      ['Motivation', '/guides/motivation'],
      ['Object Styles', '/guides/object-styles'],
      ['Variants', '/guides/variants'],
      ['Layouts', '/guides/layouts'],
      ['Keyframes', '/guides/keyframes'],
      ['Styled Components', '/guides/styled-components'],
      ['Responsive Typography', '/guides/responsive-typography'],
      ['Nested ThemeUIProviders', '/guides/nested-theme-providers'],
      ['Merging Themes', '/guides/merging-themes'],
      ['Custom CacheProvider', '/guides/custom-cache-provider'],
      ['Theme Color Meta Tag', '/guides/theme-color-meta-tag'],
      ['Color Mode Toggles', '/guides/color-mode-toggles'],
      ['Global Styles', '/guides/global-styles'],
      ['TypeScript', '/guides/typescript'],
    ],
  ],
  [
    'Recipes',
    '/recipes',
    [
      ['Gatsby Link', '/recipes/gatsby-link'],
      ['Flexbox Layout', '/recipes/flexbox-layout'],
      ['CSS Grid', '/recipes/css-grid'],
      ['Centered Container', '/recipes/centered-container'],
      ['Tiled Layout', '/recipes/tiled-layout'],
      ['Stack Layout', '/recipes/stack'],
      ['Flexbox Sidebar', '/recipes/flexbox-sidebar'],
      ['Button', '/recipes/button'],
      ['Header A1', '/recipes/header-a1'],
      ['Header A2', '/recipes/header-a2'],
      ['Header A3', '/recipes/header-a3'],
      ['Header A4', '/recipes/header-a4'],
      ['Header A5', '/recipes/header-a5'],
      ['Footer A1', '/recipes/footer-a1'],
      ['Footer A2', '/recipes/footer-a2'],
      ['Footer A3', '/recipes/footer-a3'],
      ['Footer A4', '/recipes/footer-a4'],
      ['PostList A1', '/recipes/post-list-a1'],
      ['PostList A2', '/recipes/post-list-a2'],
      ['MDX Table Align', '/recipes/mdx-table-align'],
    ],
  ],
  ['Migrating', '/migrating'],
]

export default sidebar
