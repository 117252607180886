"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    strong: "strong",
    em: "em",
    pre: "pre"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uiglobal",
      children: "@theme-ui/global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wrapper around the Emotion ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Global"
      }), " component, made Theme UI theme-aware."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Note:"
      }), " ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["This package is included in the main ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-ui"
        }), " package and a\nseparate installation is not required unless you’re using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/core"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/global @theme-ui/core @emotion/react\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-jsx",
        children: "import Global from '@theme-ui/global'\n\nexport default (props) => (\n  <Global\n    styles={{\n      button: {\n        m: 0,\n        bg: 'primary',\n        color: 'background',\n        border: 0,\n      },\n    }}\n  />\n)\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = MDXContent;
exports.default = _default;