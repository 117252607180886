/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    pre: "pre",
    code: "code",
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "badge"
  }, "Badge"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Badge } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Badge>Badge</Badge>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Heading>\n  Components\n  <Badge variant=\"accent\">New</Badge>\n  <Badge variant=\"outline\" ml={1}>\n    Cool\n  </Badge>\n</Heading>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Avatar src={images.logo} sx={{ backgroundColor: 'white' }} />\n<Badge variant='circle' ml={-3} mt={-3}>16</Badge>\n")), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Badge variants can be defined in ", React.createElement(_components.code, null, "theme.badges"), ".\nThe Badge component uses ", React.createElement(_components.code, null, "theme.badges.primary"), " as its default variant."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme variants\n{\n  badges: {\n    primary: {\n      color: 'background',\n      bg: 'primary',\n    },\n    outline: {\n      color: 'primary',\n      bg: 'transparent',\n      boxShadow: 'inset 0 0 0 1px',\n    },\n  },\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
