/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Cards} from '../..';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "guides"
  }, "Guides"), "\n", React.createElement(Cards, {
    width: 320
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/how-it-works"
  }, "How it Works"), " Implementation details for the core\nlibrary"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/jsx-pragma"
  }, "JSX Pragma"), " Using JSX pragma to enable the ", React.createElement(_components.code, null, "sx"), " prop"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/motivation"
  }, "Motivation"), " Project background and prior art"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/object-styles"
  }, "Object Styles"), " Authoring styles with object literal\nsyntax"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/variants"
  }, "Variants"), " Create stylistic theme-based variations"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/layouts"
  }, "Layouts"), " Creating themeable page layouts"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/styled-components"
  }, "Styled Components"), " Create reusable styled\ncomponents"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/responsive-typography"
  }, "Responsive Typography"), " Style content\nresponsively"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/nested-theme-providers"
  }, "Nested ThemeUIProviders"), " Add contextual theme\nand stylistic changes"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/merging-themes"
  }, "Merging Themes"), " Merging theme objects together with\nJavaScript"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/custom-cache-provider"
  }, "Custom CacheProvider"), " Customise the styles\ngenerated and where they are injected"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/theme-color-meta-tag"
  }, "Theme Color Meta Tag"), " Use theme colors for the\ntheme color meta tag"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/color-mode-toggles"
  }, "Color Mode Toggles"), " Create buttons to switch\ncolor modes"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/global-styles"
  }, "Global Styles"), " Add global styles with Emotion"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guides/typescript"
  }, "TypeScript"), " Using Theme UI with TypeScript"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
