/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    em: "em",
    code: "code",
    pre: "pre",
    a: "a",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "To add groups of styles based on theme values, you can take advantage of the\n", React.createElement(_components.em, null, "Variants"), " feature. Variants allow you to define the styles used across multiple\nbuttons, typographic elements, or any element in your ", React.createElement(_components.code, null, "theme"), " object."), "\n", React.createElement(_components.p, null, "For example, you can define ", React.createElement(_components.code, null, "primary"), " and ", React.createElement(_components.code, null, "secondary"), " variants for buttons and\nuse colors from the theme."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme with variants\n{\n  colors: {\n    primary: '#07c',\n    secondary: '#639',\n  },\n  buttons: {\n    primary: {\n      color: 'white',\n      bg: 'primary',\n    },\n    secondary: {\n      color: 'white',\n      bg: 'secondary',\n    },\n  },\n}\n")), "\n", React.createElement(_components.p, null, "With the ", React.createElement(_components.code, null, "theme"), " object above, the ", React.createElement(_components.code, null, "buttons"), " variants can be referenced by any\ntag through ", React.createElement(_components.a, {
    href: "/sx-prop"
  }, "the ", React.createElement(_components.code, null, "sx"), " prop"), ". Inside ", React.createElement(_components.code, null, "sx"), ", the variant should begin\nwith a top-level key from the theme, then use dot notation to access nested\nobjects."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<button sx={{ variant: 'buttons.primary' }} />\n")), "\n", React.createElement(_components.p, null, "When using the ", React.createElement(_components.a, {
    href: "/components"
  }, "built-in components"), ", you can use the ", React.createElement(_components.code, null, "variant"), "\nprop directly on components, instead of inside the ", React.createElement(_components.code, null, "sx"), " object."), "\n", React.createElement(_components.p, null, "Most ", React.createElement(_components.a, {
    href: "/components/variants"
  }, "components have their own variant group"), " (“variant\ngroup” being a top-level theme key, e.g. the\n", React.createElement(_components.a, {
    href: "/components/button"
  }, React.createElement(_components.code, null, "Button"), " component"), " uses ", React.createElement(_components.code, null, "buttons"), "), and some also have a\ndefault variant they’ll utilize from that variant group (e.g. ", React.createElement(_components.code, null, "Button"), " defaults\nto using ", React.createElement(_components.code, null, "primary"), ")."), "\n", React.createElement(_components.p, null, "This means using ", React.createElement(_components.code, null, "<Button />"), " without specifying a variant has the same result\nas the snippet above, since the default variant name (", React.createElement(_components.code, null, "primary"), ") matches a\nvariant name inside the ", React.createElement(_components.code, null, "buttons"), " group. If you want a different variant from\nthe same group, you can give its key without specifying the group:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button variant=\"secondary\" />\n")), "\n", React.createElement(_components.p, null, "You can also use variants outside of a component’s default variant group with\ndot notation. This is especially useful for combining layout components without\nfurther nesting your DOM, such as adding container styles to a grid or flexbox.\nBy using the ", React.createElement(_components.a, {
    href: "/components/grid"
  }, React.createElement(_components.code, null, "Grid"), " component"), " here with a variant, you’re\nable to use ", React.createElement(_components.code, null, "Grid"), "’s props combined with the variant styles."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Grid variant=\"layouts.container\" columns={3} gap={3} />\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "Variants can use any name you choose, and deeply nested objects can be accessed\nwith dot notation. You could use this to organize more complex variants, like\n", React.createElement(_components.code, null, "<Button variant=\"outline.small\" />"), ", which would access the\n", React.createElement(_components.code, null, "buttons.outline.small"), " object from the theme.")), "\n", React.createElement(_components.h2, {
    id: "color-modes"
  }, "Color Modes"), "\n", React.createElement(_components.p, null, "Variants will also work with ", React.createElement(_components.a, {
    href: "/color-modes"
  }, "color modes"), ". With the example\nbelow, the primary button will adapt its colors based on the current color mode."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme with button variants and color modes\n{\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#0c7',\n    modes: {\n      dark: {\n        text: '#000',\n        background: '#fff',\n        primary: '#0c7',\n      },\n    },\n  },\n  buttons: {\n    primary: {\n      color: 'background', // use the page background color for an inverted effect\n      bg: 'primary',\n    },\n  }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "typography"
  }, "Typography"), "\n", React.createElement(_components.p, null, "Variants can also be used to create typographic styles, similar to how graphics\napplications store text styles. This allows you to define core typographic\nvalues and use them as complete styles, but still override individual values\nwhen needed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme with typographic variants\n{\n  fonts: {\n    body: 'system-ui, sans-serif',\n    heading: 'Poppins, sans-serif',\n    monospace: 'Menlo, monospace',\n  },\n  lineHeights: {\n    body: 1.5,\n    heading: 1.125,\n  },\n  fontWeights: {\n    body: 400,\n    heading: 900,\n    bold: 700,\n  },\n  letterSpacings: {\n    heading: '-0.05em',\n    caps: '0.1em',\n  },\n  text: {\n    heading: {\n      fontFamily: 'heading',\n      fontWeight: 'heading',\n      lineHeight: 'heading',\n      letterSpacing: 'heading',\n    },\n    display: {\n      fontFamily: 'heading',\n      fontWeight: 'heading',\n      lineHeight: 'heading',\n      letterSpacing: 'heading',\n      fontSize: [ 5, 6, 7 ],\n    },\n    caps: {\n      textTransform: 'uppercase',\n      letterSpacing: 'caps',\n    },\n  },\n}\n")), "\n", React.createElement(_components.p, null, "These variants can then be used in the ", React.createElement(_components.code, null, "theme.styles"), " object, with the ", React.createElement(_components.code, null, "sx"), "\nprop, or with the ", React.createElement(_components.a, {
    href: "/components/text"
  }, React.createElement(_components.code, null, "Text")), " or ", React.createElement(_components.a, {
    href: "/components/heading"
  }, React.createElement(_components.code, null, "Heading")), "\ncomponents."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "// example usage of typographic variants\n<h1 sx={{ variant: 'text.display' }} />\n<p sx={{ variant: 'text.caps' }} />\n<h2\n  sx={{\n    variant: 'text.heading',\n    // overriding the default styles\n    fontWeight: 'body',\n  }}\n/>\n")), "\n", React.createElement(_components.h2, {
    id: "styles-variants"
  }, React.createElement(_components.code, null, "styles"), " variants"), "\n", React.createElement(_components.p, null, "Variants inside the ", React.createElement(_components.code, null, "styles"), " object, while usable through the same mechanisms as\nregular variants, are also used for other Theme UI APIs."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The entire ", React.createElement(_components.a, {
    href: "/theme-spec#styles"
  }, React.createElement(_components.code, null, "styles"), " object"), " can style child HTML elements\nor Markdown content, via the ", React.createElement(_components.a, {
    href: "/api/#basestyles"
  }, React.createElement(_components.code, null, "BaseStyles"), " component")), "\n", React.createElement(_components.li, null, "If the ", React.createElement(_components.a, {
    href: "/theming#configuration-flags"
  }, React.createElement(_components.code, null, "useRootStyles"), " configuration option"), " is\nenabled, the ", React.createElement(_components.code, null, "styles.root"), " object will style the ", React.createElement(_components.code, null, "<html>"), " element"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/mdx/themed"
  }, React.createElement(_components.code, null, "Themed"), " components"), " will use their respective variant from\ninside ", React.createElement(_components.code, null, "styles"), ", e.g. ", React.createElement(_components.code, null, "<Themed.h2>"), " uses ", React.createElement(_components.code, null, "styles.h2")), "\n"), "\n", React.createElement(_components.h2, {
    id: "themeable-layout-components"
  }, "Themeable layout components"), "\n", React.createElement(_components.p, null, "Variants can also be used to create themeable layout components. This is\nespecially useful when creating ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.org/docs/themes/"
  }, "Gatsby themes"), ", where you'd like certain\nparts of your page layout to be customizable."), "\n", React.createElement(_components.p, null, "Using the ", React.createElement(_components.code, null, "variant"), " key in the ", React.createElement(_components.code, null, "sx"), " prop allows you to define styles for a\ncomponent that can optionally be overridden from the ", React.createElement(_components.code, null, "theme"), " object. When the\nvariant is undefined in the ", React.createElement(_components.code, null, "theme"), ", no additional styles are applied to the\nelement."), "\n", React.createElement(_components.p, null, "Read the ", React.createElement(_components.a, {
    href: "/guides/layouts"
  }, "Layouts Guide"), " to learn more."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
