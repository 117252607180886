/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import IntroCodeSamples from '../../components/intro-code-samples';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    a: "a",
    h2: "h2",
    em: "em"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!IntroCodeSamples) _missingMdxReference("IntroCodeSamples", false);
  if (!IntroCodeSamples.BasicUsage) _missingMdxReference("IntroCodeSamples.BasicUsage", true);
  if (!IntroCodeSamples.ThemeCreation) _missingMdxReference("IntroCodeSamples.ThemeCreation", true);
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "getting-started"
  }, "Getting Started"), "\n", React.createElement(_components.p, null, "Install Theme UI."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm install theme-ui @emotion/react\n")), "\n", React.createElement(_components.p, null, "Create a theme object to include custom color, typography, and layout values."), "\n", React.createElement(IntroCodeSamples.ThemeCreation), "\n", React.createElement(_components.p, null, "Pass your ", React.createElement(_components.code, null, "theme"), " object to ", React.createElement(_components.code, null, "ThemeUIProvider"), "."), "\n", React.createElement(IntroCodeSamples.BasicUsage), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "If you’re using Gatsby, see the\n", React.createElement(_components.a, {
    href: "/getting-started/gatsby"
  }, "Getting Started with Gatsby"), " page.")), "\n", React.createElement(_components.h2, {
    id: "sx-prop"
  }, React.createElement(_components.code, null, "sx"), " prop"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "sx"), " prop throughout your application to add styles based on your theme\nto any component. Enable the ", React.createElement(_components.code, null, "sx"), " prop by adding the\n", React.createElement(_components.code, null, "/** @jsxImportSource theme-ui */"), " comment to the top of your file or\nconfiguring ", React.createElement(_components.code, null, "jsxImportSource"), " in your Babel config."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "sx"), " prop lets you add any valid CSS to an element, while using values from\nyour theme to keep styles consistent. You can think of the style object that the\n", React.createElement(_components.code, null, "sx"), " prop accepts as a ", React.createElement(_components.em, null, "superset"), " of CSS."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    sx={{\n      fontWeight: 'bold',\n      fontSize: 4, // picks up value from `theme.fontSizes[4]`\n      color: 'primary', // picks up value from `theme.colors.primary`\n    }}\n  >\n    Hello\n  </div>\n)\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "Confused about this ", React.createElement(_components.code, null, "@jsxImportSource"), " comment? Read more about\n", React.createElement(_components.a, {
    href: "/guides/jsx-pragma"
  }, "JSX Pragma"), ".")), "\n", React.createElement(_components.h2, {
    id: "responsive-styles"
  }, "Responsive styles"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "sx"), " prop also supports using arrays as values to change properties\nresponsively with a mobile-first approach."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    sx={{\n      // applies width 100% to all viewport widths,\n      // width 50% above the first breakpoint,\n      // and 25% above the next breakpoint\n      width: ['100%', '50%', '25%'],\n    }}\n  />\n)\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "This API originated in ", React.createElement(_components.a, {
    href: "https://styled-system.com"
  }, "Styled System"), " and is intended as\n", React.createElement(_components.a, {
    href: "https://styled-system.com/guides/array-props"
  }, "a terser syntax for applying responsive styles"), "\nacross a singular dimension. The values used for media queries can be defined in\na ", React.createElement(_components.a, {
    href: "/theming/#breakpoints"
  }, React.createElement(_components.code, null, "theme.breakpoints")), " array.")), "\n", React.createElement(_components.p, null, "If you prefer standard CSS syntax, you can use nested objects for responsive\nstyles as well."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div\n  sx={{\n    width: '100%',\n    '@media screen and (min-width: 40em)': {\n      width: '50%',\n    },\n  }}\n/>\n")), "\n", React.createElement(_components.h2, {
    id: "components"
  }, "Components"), "\n", React.createElement(_components.p, null, "Theme UI includes a library of primitive UI components, which can be completely\ncustomized with your theme and include support for\n", React.createElement(_components.a, {
    href: "/components/variants"
  }, "variants"), ". You can use variants to encapsulate more\ncomplex, component-specific styles that can be changed with props. Import and\nuse these components to handle layout, images, forms, and more."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\nimport { Box, Label, Input, Button } from 'theme-ui'\n\nexport default (props) => (\n  <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>\n    <Label htmlFor=\"search\">Search</Label>\n    <Input id=\"search\" name=\"search\" {...props} />\n    <Button>Go</Button>\n  </Box>\n)\n")), "\n", React.createElement(_components.p, null, "See the ", React.createElement(_components.a, {
    href: "/components"
  }, "Components"), " documentation for more."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
