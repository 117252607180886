/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "switch"
  }, "Switch"), "\n", React.createElement(_components.p, null, "Form switch input component"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Switch } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Switch label=\"Enable email alerts?\" />\n")), "\n", React.createElement(_components.h2, {
    id: "input-state-style-checked"
  }, "Input state style ", React.createElement(_components.code, null, "&:checked")), "\n", React.createElement(_components.p, null, "The Switch component renders several elements, among other things an\n", React.createElement(_components.code, null, "<input type=\"checkbox\" />"), ", the state of which can be used to update the style."), "\n", React.createElement(_components.p, null, "However, since this input is hidden by CSS (the \"visual switch\" is a Box),\napplying for instance the ", React.createElement(_components.code, null, "&:checked"), " CSS selector will not show any difference."), "\n", React.createElement(_components.p, null, "To show different style based on the input state, you can use\n", React.createElement(_components.a, {
    href: "https://emotion.sh/docs/styled#nesting-components"
  }, "Emotion’s ", React.createElement(_components.code, null, "&"), " selector")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Switch\n  label=\"Enable email alerts?\"\n  sx={{\n    bakgroundColor: 'gray',\n    // This will not be visible since the input is hidden\n    // '&:checked': {\n    //   backgroundColor: 'primary'\n    // },\n    // This will be visible\n    'input:checked ~ &': {\n      backgroundColor: 'primary',\n    },\n  }}\n/>\n")), "\n", React.createElement(_components.h2, {
    id: "external-label"
  }, "External label"), "\n", React.createElement(_components.p, null, "Even though the Switch component already renders a label, but you can also\nopt-in for an external label instead."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Box, Flex, Label, Switch } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Flex\n  sx={{\n    justifyContent: 'space-between',\n    alignItems: 'center',\n    py: 4,\n  }}>\n  <Label htmlFor=\"enable-email-alerts\" sx={{ flex: 1 }}>\n    Enable email alerts?\n  </Label>\n  <Box>\n    <Switch id=\"enable-email-alerts\" />\n  </Box>\n</Flex>\n")), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Switch variants can be defined in ", React.createElement(_components.code, null, "theme.forms"), " and the component uses the\n", React.createElement(_components.code, null, "theme.forms.switch"), " variant by default."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
