/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "nested-theme-providers"
  }, "Nested Theme Providers"), "\n", React.createElement(_components.p, null, "While in most cases, you'll be using a ", React.createElement(_components.code, null, "ThemeUIProvider"), " component at the root\nof your application to set a site-wide theme, nested Theme Providers are a\npowerful way to adjust styles contextually."), "\n", React.createElement(_components.p, null, "For example, when building a Gatsby theme, you may want to set some base\nthematic values that the user can easily override to match their site, but keep\nsome theme-specific styles for use only in the pages that your Gatsby theme\ngenerates. Or, you might want to have a global theme, but adjust styles in a\nsmall section of a page to render with a dark color scheme."), "\n", React.createElement(_components.h2, {
    id: "how-contexts-merge"
  }, "How contexts merge"), "\n", React.createElement(_components.p, null, "When adding a nested Theme Provider, it will inherit the ", React.createElement(_components.code, null, "theme"), " and\n", React.createElement(_components.code, null, "components"), " object from its parent. The ", React.createElement(_components.code, null, "theme"), " object will be deeply merged\nwith the outer context's theme. The ", React.createElement(_components.code, null, "components"), " object will override any outer\ncontext components, but apply styling based on ", React.createElement(_components.code, null, "theme.styles"), ". The inner Theme\nProvider will not create a new color mode state, but inherit this from the\nparent."), "\n", React.createElement(_components.h2, {
    id: "functional-themes"
  }, "Functional Themes"), "\n", React.createElement(_components.p, null, "The nested ", React.createElement(_components.code, null, "ThemeUIProvider"), " component can use a functional theme to avoid deep\nmerging the objects or to control the way the two objects are merged in a more\ngranular way."), "\n", React.createElement(_components.h2, {
    id: "gatsby-themes"
  }, "Gatsby themes"), "\n", React.createElement(_components.p, null, "When building a Gatsby theme, you should use\n", React.createElement(_components.a, {
    href: "/packages/gatsby-plugin"
  }, React.createElement(_components.code, null, "gatsby-plugin-theme-ui")), " for parts of your theme that\nshould be overridden or merged with other themes. If you need to specify a\nGatsby theme-specific subtheme, use a nested Theme Provider in a layout\ncomponent that only renders around pages that you control. This allows the end\nuser to change their site-level theme without affecting custom styles you might\nneed in your theme. The end user can still shadow any components in your theme\nif they need to."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
