/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "flex"
  }, "Flex"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "Flex"), " component to create flexbox layouts."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Flex } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Flex>\n  <Box p={2} bg=\"primary\" color=\"white\" sx={{ flex: '1 1 auto' }}>\n    Flex\n  </Box>\n  <Box p={2} bg=\"muted\">\n    Box\n  </Box>\n</Flex>\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "Flex"), " component is identical to the ", React.createElement(_components.code, null, "Box"), " component, but with\n", React.createElement(_components.code, null, "display: flex"), " set. If you need to alter the ", React.createElement(_components.code, null, "display"), " property, use the ", React.createElement(_components.code, null, "Box"), "\ncomponent instead.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
