/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "static-css"
  }, "Static CSS"), "\n", React.createElement(_components.p, null, "Certain projects have constraints that require using static CSS rather\nthan CSS-in-JS. Theme UI contains (experimental) libraries to transform\na theme config to the proper format for other CSS libraries."), "\n", React.createElement(_components.p, null, "This allows you to use the Theme UI implementation in React-based projects\nand share the same theme or design tokens in your design system with other projects so you\ncan incrementally adopt Theme UI."), "\n", React.createElement(_components.h2, {
    id: "get-started"
  }, "Get started"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/css/tailwind"
  }, "Tailwind")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/css/tachyons"
  }, "Tachyons")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/system-ui/theme-ui/issues/103"
  }, "TODO: Bootstrap")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/system-ui/theme-ui/issues/104"
  }, "TODO: Bulma")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
