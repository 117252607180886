/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "button"
  }, "Button"), "\n", React.createElement(_components.p, null, "Create a simple button component with variants."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<button\n  sx={{\n    appearance: 'none',\n    display: 'inline-block',\n    textAlign: 'center',\n    lineHeight: 'inherit',\n    textDecoration: 'none',\n    fontSize: 'inherit',\n    fontWeight: 'bold',\n    m: 0,\n    px: 3,\n    py: 2,\n    border: 0,\n    borderRadius: 4,\n    variant: 'buttons.primary',\n  }}>\n  Button\n</button>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nconst Button = ({ variant = 'primary', ...props }) => (\n  <button\n    {...props}\n    sx={{\n      appearance: 'none',\n      display: 'inline-block',\n      textAlign: 'center',\n      lineHeight: 'inherit',\n      textDecoration: 'none',\n      fontSize: 'inherit',\n      fontWeight: 'bold',\n      m: 0,\n      px: 3,\n      py: 2,\n      border: 0,\n      borderRadius: 4,\n      // pass variant prop to sx\n      variant: `buttons.${variant}`,\n    }}\n  />\n)\n\nexport default Button\n")), "\n", React.createElement(_components.p, null, "With the above component, button variants can be defined in ", React.createElement(_components.code, null, "theme.buttons"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme\nexport default {\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    secondary: '#639',\n    gray: '#555',\n  },\n  buttons: {\n    primary: {\n      color: 'background',\n      bg: 'primary',\n    },\n    secondary: {\n      color: 'background',\n      bg: 'secondary',\n    },\n    gray: {\n      color: 'background',\n      bg: 'gray',\n    },\n  },\n}\n")), "\n", React.createElement(_components.p, null, "See also: ", React.createElement(_components.a, {
    href: "/components/button"
  }, "Button component")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
