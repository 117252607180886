"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uitailwind",
      children: "@theme-ui/tailwind"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Generate static CSS for use outside of React with Theme UI and Tailwind.css."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "why",
      children: "Why?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Though Theme UI comes with its own implementation, project constraints might\nrequire pure utility CSS output. This library allows you to leverage a Theme UI\ntheme that is used elsewhere and transforms it to the configuration that\nTailwind expects. The best of both worlds."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "installation",
      children: "Installation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/tailwind\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "usage",
      children: "Usage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Transform your Theme UI compliant theme config with the library:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// tailwind.config.js\nconst toTailwind = require('@theme-ui/tailwind')\nconst theme = require('./theme') // Path to Theme UI config\n\nmodule.exports = toTailwind(theme)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Then, use the Tailwind CLI:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "tailwind build input.css -o output.css\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = MDXContent;
exports.default = _default;