/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "syntax-highlighting"
  }, "Syntax Highlighting"), "\n", React.createElement(_components.p, null, "To support syntax highlighting libraries like ", React.createElement(_components.a, {
    href: "https://github.com/PrismJS/prism"
  }, "Prism.js"), ", colors and other\nstyles can be added to ", React.createElement(_components.code, null, "theme.styles.pre"), " to target child elements with class\nselectors. Prism.js adds ", React.createElement(_components.code, null, "<span>"), " elements with class names that can be used as\nchild selectors."), "\n", React.createElement(_components.p, null, "Install the Theme UI Prism component."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i @theme-ui/prism\n")), "\n", React.createElement(_components.p, null, "Next, add the Prism component to your MDX components list. If you’re using\nGatsby and ", React.createElement(_components.code, null, "gatsby-plugin-theme-ui"), ", this file is\n", React.createElement(_components.code, null, "src/gatsby-plugin-theme-ui/components.js"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    filename: "components.js"
  }, "import Prism from '@theme-ui/prism'\n\nconst components = {\n  pre: ({ children }) => <>{children}</>,\n  code: Prism,\n}\n\nexport default components\n")), "\n", React.createElement(_components.p, null, "In your theme, import and add a syntax theme from the Prism package."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    filename: "src/gatsby-plugin-theme-ui/index.js"
  }, "import nightOwl from '@theme-ui/prism/presets/night-owl'\n\nconst theme = {\n  styles: {\n    pre: {\n      ...nightOwl,\n    },\n  },\n}\n\nexport default theme\n")), "\n", React.createElement(_components.p, null, "You can also construct your own syntax theme inline, using theme colors:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  colors: {\n    red: '#ec3750',\n    green: '#33d6a6',\n    blue: '#338eda',\n    gray: '#666',\n  },\n  styles: {\n    pre: {\n      '.comment, .prolog, .doctype, .cdata, .punctuation, .operator, .entity, .url': {\n        // theme.colors and other values can be referenced\n        color: 'gray'\n      },\n      '.property, .tag, .boolean, .number, .constant, .symbol, .deleted, .function, .class-name, .regex, .important, .variable':\n        {\n          color: 'red'\n        },\n      '.atrule, .attr-value, .keyword': {\n        color: 'blue'\n      },\n      '.selector, .attr-name, .string, .char, .builtin, .inserted': {\n        color: 'green'\n      }\n    }\n  }\n}\n}\n")), "\n", React.createElement(_components.p, null, "For more details, check out the ", React.createElement(_components.a, {
    href: "/packages/prism"
  }, "Prism package docs"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
