/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Cards} from '../..';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "packages"
  }, "Packages"), "\n", React.createElement(_components.p, null, "In addition to the batteries-included library ", React.createElement(_components.code, null, "theme-ui"), ", Theme UI includes\r\nseveral optional packages."), "\n", React.createElement(Cards, {
    width: 320
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/css"
  }, "@theme-ui/css")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/core"
  }, "@theme-ui/core")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/components"
  }, "@theme-ui/components")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/mdx"
  }, "@theme-ui/mdx")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/presets"
  }, "@theme-ui/presets")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/prism"
  }, "@theme-ui/prism")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/color"
  }, "@theme-ui/color")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/style-guide"
  }, "@theme-ui/style-guide")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/gatsby-plugin"
  }, "gatsby-plugin-theme-ui")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/gatsby-theme-style-guide"
  }, "gatsby-theme-style-guide")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/typography"
  }, "@theme-ui/typography")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/match-media"
  }, "@theme-ui/match-media")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/sidenav"
  }, "@theme-ui/sidenav")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/packages/tailwind"
  }, "@theme-ui/tailwind")), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
