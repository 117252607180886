/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "aspectratio"
  }, "AspectRatio"), "\n", React.createElement(_components.p, null, "Component for maintaining a fluid-width aspect ratio"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { AspectRatio } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<AspectRatio\n  ratio={16 / 9}\n  sx={{\n    p: 4,\n    display: 'flex',\n    alignItems: 'center',\n    justifyContent: 'center',\n    color: 'background',\n    bg: 'primary',\n  }}>\n  <Heading>Aspect Ratio</Heading>\n</AspectRatio>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<AspectRatio ratio={16 / 9}>\n  <Image\n    src={images.flatiron}\n    sx={{\n      objectFit: 'cover',\n    }}\n  />\n</AspectRatio>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
