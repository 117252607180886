/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "field"
  }, "Field"), "\n", React.createElement(_components.p, null, "Wrapper around primitive form control components"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Field } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Field label=\"Email\" name=\"email\" defaultValue=\"\" />\n")), "\n", React.createElement(_components.h2, {
    id: "props"
  }, "Props"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Name"), React.createElement(_components.th, null, "Type"), React.createElement(_components.th, null, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "as")), React.createElement(_components.td, null, "Component"), React.createElement(_components.td, null, "form control to render, default ", React.createElement(_components.code, null, "Input"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "label")), React.createElement(_components.td, null, "String"), React.createElement(_components.td, null, "Text for ", React.createElement(_components.code, null, "Label"), " component")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "name")), React.createElement(_components.td, null, "String"), React.createElement(_components.td, null, "Used for the ", React.createElement(_components.code, null, "for"), ", ", React.createElement(_components.code, null, "id"), ", and ", React.createElement(_components.code, null, "name"), " attributes")))), "\n", React.createElement(_components.p, null, "All other props are passed directly to the form control."), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "Field"), " component uses the same variants as the other ", React.createElement(_components.a, {
    href: "/components/forms"
  }, "form components"), " that it is composed of."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
