/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {TypeScale, TypeStyle, ColorPalette} from '@theme-ui/style-guide';
import Readme from '../../../../style-guide/README.md';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Readme), "\n", React.createElement(_components.h2, {
    id: "demo"
  }, "Demo"), "\n", React.createElement(_components.h3, {
    id: "type-specimens"
  }, "Type Specimens"), "\n", React.createElement(TypeStyle, {
    fontSize: 7
  }, "System Font (Body)"), "\n", React.createElement(TypeStyle, {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    fontSize: 7
  }, React.createElement(_components.p, null, "Heading")), "\n", React.createElement(TypeStyle, {
    fontFamily: "monospace",
    fontSize: 7
  }, React.createElement(_components.p, null, "Monospace")), "\n", React.createElement(_components.h3, {
    id: "type-scale"
  }, "Type Scale"), "\n", React.createElement(TypeScale, {
    fontWeight: "heading"
  }), "\n", React.createElement(_components.h3, {
    id: "color-palette"
  }, "Color Palette"), "\n", React.createElement(ColorPalette, {
    omit: ['modes', 'header']
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
