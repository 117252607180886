/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "postlist-a2"
  }, "PostList A2"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<ul\n  sx={{\n    listStyle: 'none',\n    display: 'grid',\n    gridGap: 3,\n    gridTemplateColumns: 'repeat(auto-fit, minmax(256px, 1fr))',\n    m: 0,\n    px: 3,\n    py: 4,\n  }}>\n  {posts.map((post) => (\n    <li key={post.id} sx={{}}>\n      <Themed.h2\n        sx={{\n          m: 0,\n        }}>\n        <Link\n          to={post.slug}\n          sx={{\n            color: 'inherit',\n            textDecoration: 'none',\n            ':hover,:focus': {\n              color: 'primary',\n              textDecoration: 'underline',\n            },\n          }}>\n          {post.title}\n        </Link>\n      </Themed.h2>\n      <small sx={{ fontWeight: 'bold' }}>{post.date}</small>\n      <Themed.p\n        sx={{\n          m: 0,\n        }}>\n        {post.excerpt}\n      </Themed.p>\n    </li>\n  ))}\n</ul>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
