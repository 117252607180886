/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    pre: "pre",
    code: "code",
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "image"
  }, "Image"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Image } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Image src={images.flatiron} />\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Image src={images.logo} variant=\"avatar\" />\n")), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Image style variants can be defined in the ", React.createElement(_components.code, null, "theme.images"), " object."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme variants\n{\n  images: {\n    avatar: {\n      width: 48,\n      height: 48,\n      borderRadius: 99999,\n    },\n  }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
