/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "gatsby-link"
  }, "Gatsby Link"), "\n", React.createElement(_components.p, null, "Add an active style to ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.org/docs/linking-between-pages/"
  }, "Gatsby Link"), " or other React router link components.\nImport the Theme UI ", React.createElement(_components.a, {
    href: "/guides/jsx-pragma"
  }, "custom JSX pragma"), " for styling\nthe router link components directly, without using wrapper components."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<Link\n  to=\"/recipes/gatsby-link/\"\n  activeClassName=\"active\"\n  sx={{\n    color: 'inherit',\n    '&.active': {\n      color: 'primary',\n    },\n  }}>\n  Link\n</Link>\n")), "\n", React.createElement(_components.p, null, "Full code for this example:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\nimport { Link } from 'gatsby'\n\nexport default (props) => (\n  <Link\n    {...props}\n    activeClassName=\"active\"\n    sx={{\n      color: 'inherit',\n      '&.active': {\n        color: 'primary',\n      },\n    }}\n  />\n)\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
