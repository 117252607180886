"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uicolor",
      children: "@theme-ui/color"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Color manipulation utilities for Theme UI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/color\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Import utilities from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@theme-ui/color"
      }), " package and use them with colors in\nthe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sx"
      }), " prop."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/** @jsxImportSource theme-ui */\nimport { darken, lighten } from '@theme-ui/color'\n\nexport default (props) => (\n  <div\n    {...props}\n    sx={{\n      color: darken('primary', 0.25),\n      bg: lighten('primary', 0.875),\n    }}\n  />\n)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "api",
      children: "API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "getcolor",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "getColor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { getColor } from '@theme-ui/color'\n// getColor(theme, 'primary')\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "darken",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "darken"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Darken a color by an amount 0–1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { darken } from '@theme-ui/color'\n// darken('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "lighten",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "lighten"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lighten a color by an amount 0–1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { lighten } from '@theme-ui/color'\n// lighten('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "rotate",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "rotate"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rotate the hue of a color by an amount 0–360"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { rotate } from '@theme-ui/color'\n// rotate('primary', degrees)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "hue",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hue"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Set the hue of a color to a degree 0–360"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { hue } from '@theme-ui/color'\n// hue('primary', degrees)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "saturation",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "saturation"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Set the saturation of a color to an amount 0–1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { saturation } from '@theme-ui/color'\n// saturation('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "lightness",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "lightness"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Set the lightness of a color to an amount 0–1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { lightness } from '@theme-ui/color'\n// lightness('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "desaturate",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "desaturate"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Desaturate a color by an amount 0–1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { desaturate } from '@theme-ui/color'\n// desaturate('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "saturate",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "saturate"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saturate a color by an amount 0–1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { saturate } from '@theme-ui/color'\n// saturate('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "shade",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "shade"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Shade a color by an amount 0–1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { shade } from '@theme-ui/color'\n// shade('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "tint",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "tint"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tint a color by an amount 0–1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { tint } from '@theme-ui/color'\n// tint('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "alpha",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "alpha"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Set the transparency of a color to an amount 0-1"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { alpha } from '@theme-ui/color'\n// alpha('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "transparentize",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "transparentize"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Similar to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alpha"
      }), ", but decreases opacity by the given amount."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { transparentize } from '@theme-ui/color'\n// transparentize('primary', amount)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "mix",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "mix"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mix two colors by a specific ratio"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { mix } from '@theme-ui/color'\n// mix('primary', 'secondary', ratio)\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "complement",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "complement"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Get the complement of a color"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { complement } from '@theme-ui/color'\n// complement('primary')\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "invert",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "invert"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Get the inverted color"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { invert } from '@theme-ui/color'\n// invert('primary')\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "grayscale",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "grayscale"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Desaturate the color to grayscale"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { grayscale } from '@theme-ui/color'\n// grayscale('primary')\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "advanced-usage",
      children: "Advanced Usage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you want to do something more complex, such as setting up gradients, you can\ndo that with some extra workarounds."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "We can take the result of any of the above helper functions (which return a\nfunction) and call it with the theme object to generate a string in place. This\nis useful for interpolating values into complex CSS declarations:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-jsx",
        children: "<MyComponentWithBackground\n  sx={{\n    backgroundImage: (t) => `\n      linear-gradient(\n        to bottom,\n        ${alpha('primary', 0.5)(t)},\n        ${alpha('secondary', 0.5)(t)}\n      )\n    `,\n  }}\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "related",
      children: "Related"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://polished.js.org",
          children: "Polished"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = MDXContent;
exports.default = _default;