/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Note} from '../..';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    strong: "strong",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "forms"
  }, "Forms"), "\n", React.createElement(_components.p, null, "Several primitive components are included for building accessible forms."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import {\n  Label,\n  Input,\n  Select,\n  Textarea,\n  Radio,\n  Checkbox,\n  Slider,\n} from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Box as=\"form\" onSubmit={(e) => e.preventDefault()}>\n  <Label htmlFor=\"username\">Username</Label>\n  <Input name=\"username\" id=\"username\" mb={3} />\n  <Label htmlFor=\"password\">Password</Label>\n  <Input type=\"password\" name=\"password\" id=\"password\" mb={3} />\n  <Box>\n    <Label mb={3}>\n      <Checkbox />\n      Remember me\n    </Label>\n  </Box>\n  <Label htmlFor=\"sound\">Sound</Label>\n  <Select name=\"sound\" id=\"sound\" mb={3}>\n    <option>Beep</option>\n    <option>Boop</option>\n    <option>Blip</option>\n  </Select>\n  <Label htmlFor=\"comment\">Comment</Label>\n  <Textarea name=\"comment\" id=\"comment\" rows={6} mb={3} />\n  <Flex mb={3}>\n    <Label>\n      <Radio name=\"letter\" /> Alpha\n    </Label>\n    <Label>\n      <Radio name=\"letter\" /> Bravo\n    </Label>\n    <Label>\n      <Radio name=\"letter\" /> Charlie\n    </Label>\n  </Flex>\n  <Label>Slider</Label>\n  <Slider mb={3} />\n  <Button>Submit</Button>\n</Box>\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Tip:"), "\nEach form field needs to include a ", React.createElement(_components.code, null, "<label>"), " element with an ", React.createElement(_components.code, null, "htmlFor"), " prop that matches its field.\nThe entire form should be wrapped in a ", React.createElement(_components.code, null, "<form>"), " element.")), "\n", React.createElement(_components.h2, {
    id: "theming"
  }, "Theming"), "\n", React.createElement(_components.p, null, "Many of the included form components (e.g. Checkbox, Radio, Slider) use 3 colors for their default appearance:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "gray"), ", for the default state"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "primary"), ", for the active state"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "highlight"), ", for the focus outline"), "\n"), "\n", React.createElement(_components.p, null, "If you’re only looking to change the colors, providing custom values for these colors in ", React.createElement(_components.code, null, "theme.colors"), " is the easiest path."), "\n", React.createElement(_components.h3, {
    id: "further-customization"
  }, "Further customization"), "\n", React.createElement(_components.p, null, "Add styles for form elements in the ", React.createElement(_components.code, null, "theme.forms"), " object."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme\n{\n  forms: {\n    label: {\n      fontSize: 1,\n      fontWeight: 'bold',\n    },\n    input: {\n      borderColor: 'gray',\n      '&:focus': {\n        borderColor: 'primary',\n        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,\n        outline: 'none',\n      },\n    },\n    select: {\n      borderColor: 'gray',\n      '&:focus': {\n        borderColor: 'primary',\n        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,\n        outline: 'none',\n      },\n    },\n    textarea: {\n      borderColor: 'gray',\n      '&:focus': {\n        borderColor: 'primary',\n        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,\n        outline: 'none',\n      },\n    },\n    slider: {\n      bg: 'muted',\n    },\n  },\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
