/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "custom-mdx-components"
  }, "Custom MDX Components"), "\n", React.createElement(_components.p, null, "To enrich components you’re passing to MDX context with styles defined in\n", React.createElement(_components.a, {
    href: "./styling"
  }, React.createElement(_components.code, null, "theme.styles")), ", use ", React.createElement(_components.code, null, "useThemedStylesWithMdx"), " with your\n", React.createElement(_components.a, {
    href: "https://mdxjs.com/packages/react/#use"
  }, React.createElement(_components.code, null, "MDXProvider")), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "import { MDXProvider, useMDXComponents, MDXComponents } from '@mdx-js/react'\nimport { useThemedStylesWithMdx } from '@theme-ui/mdx'\nimport type { ComponentPropsWithoutRef, ReactNode } from 'react'\nimport { ThemeUIProvider } from 'theme-ui'\nimport { h2, Note, TweetEmbed } from './components'\n\nconst components: MDXComponents = {\n  h2,\n  Note,\n  TweetEmbed,\n}\n\nconst theme = {\n  styles: {\n    h2: {\n      fontWeight: 700,\n    },\n  },\n}\n\nfunction Provider({ children }: { children: React.ReactNode }) {\n  const componentsWithStyles = useThemedStylesWithMdx(\n    useMDXComponents(components)\n  )\n\n  return (\n    <ThemeUIProvider theme={theme}>\n      <MDXProvider components={componentsWithStyles}>{children}</MDXProvider>\n    </ThemeUIProvider>\n  )\n}\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "For a list of keys that can be used in the ", React.createElement(_components.code, null, "components"), " object, see the\n", React.createElement(_components.a, {
    href: "https://mdxjs.com/table-of-components/"
  }, "MDX docs for components"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
