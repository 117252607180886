/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    pre: "pre",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "linked-headings"
  }, "Linked Headings"), "\n", React.createElement(_components.p, null, "Create anchor-linked headings in MDX."), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "remark-slug"), " with MDX to create IDs based on the heading’s content. If\nyou’re using Gatsby, add this to your ", React.createElement(_components.code, null, "gatsby-plugin-mdx"), " options."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    filename: "gatsby-config.js"
  }, "const remarkSlug = require('remark-slug')\n\nmodule.exports = {\n  plugins: [\n    {\n      resolve: 'gatsby-plugin-mdx',\n      options: {\n        remarkPlugins: [remarkSlug],\n      },\n    },\n  ],\n}\n")), "\n", React.createElement(_components.p, null, "If you’re using ", React.createElement(_components.a, {
    href: "https://contentlayer.io"
  }, "Contentlayer"), ", you can use the\n", React.createElement(_components.code, null, "remarkSlug"), " plugin in your ", React.createElement(_components.code, null, "contentlayer.config.js"), " file."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    filename: "contentlayer.config.js"
  }, "import { defineDocumentType, makeSource } from 'contentlayer/source-files'\nimport remarkSlug from 'remark-slug'\n\nexport const Post = defineDocumentType(() => ({\n  name: 'Post',\n  filePathPattern: '*.mdx',\n  contentType: 'mdx',\n  fields: {},\n}))\n\nexport default makeSource({\n  contentDirPath: 'posts',\n  documentTypes: [Post],\n  mdx: {\n    remarkPlugins: [remarkSlug],\n    rehypePlugins: [],\n  },\n})\n")), "\n", React.createElement(_components.p, null, "Next, create a components module to pass to MDX context. If you’re using\n", React.createElement(_components.code, null, "gatsby-plugin-theme-ui"), ", add a ", React.createElement(_components.code, null, "src/gatsby-plugin-theme-ui/components.js"), " file.\nIf you’re not using Gatsby, pass these components to the\n", React.createElement(_components.a, {
    href: "https://mdxjs.com/packages/react/#use"
  }, React.createElement(_components.code, null, "MDXProvider"), " component"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    filename: "src/gatsby-plugin-theme-ui/components.js"
  }, "/** @jsxImportSource theme-ui */\n\nconst heading = (Tag) => (props) => {\n  if (!props.id) return <Tag {...props} />\n\n  return (\n    <Tag {...props}>\n      <a href={`#${props.id}`}>{props.children}</a>\n    </Tag>\n  )\n}\n\nconst components = {\n  h1: heading('h1'),\n  h2: heading('h2'),\n  h3: heading('h3'),\n  h4: heading('h4'),\n  h5: heading('h5'),\n  h6: heading('h6'),\n}\n\nexport default components\n")), "\n", React.createElement(_components.p, null, "Or with TypeScript:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx",
    filename: "components.tsx"
  }, "import type { ComponentPropsWithoutRef } from 'react'\nimport { Themed } from '@theme-ui/mdx'\n\nconst createHeadingWithLink =\n  (Level: 'h2' | 'h3' | 'h4' | 'h5' | 'h6') =>\n  (props: ComponentPropsWithoutRef<'h2'>) =>\n    (\n      <Level {...props}>\n        {props.id && (\n          <a\n            href={`#${props.id}`}\n            sx={{\n              color: 'inherit',\n              textDecoration: 'none',\n              ':hover': {\n                textDecoration: 'underline',\n              },\n            }}\n          >\n            {props.children}\n          </a>\n        )}\n      </Level>\n    )\n\nexport const components = {\n  h2: createHeadingWithLink('h2'),\n  h3: createHeadingWithLink('h3'),\n  h4: createHeadingWithLink('h4'),\n  h5: createHeadingWithLink('h5'),\n  h6: createHeadingWithLink('h6'),\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
