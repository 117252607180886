import { useThemeUI, get } from 'theme-ui';
import { jsx, jsxs } from '@theme-ui/core/jsx-runtime';
import { getColor } from '@theme-ui/color';
import Color from 'color';
import { createElement } from '@theme-ui/core';

const useTheme = () => useThemeUI().theme;

const Card = props => jsx("div", {
  ...props,
  sx: {
    variant: 'styles.Card'
  }
});

const TypeStyle = ({
  fontSize = 5,
  fontFamily = 'body',
  lineHeight = 'body',
  fontWeight = 'body',
  children = 'Aa',
  truncate = true,
  ...props
}) => {
  return jsx(Card, {
    ...props,
    children: children,
    sx: {
      fontFamily,
      fontSize,
      lineHeight,
      fontWeight,
      ...(truncate ? {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      } : {})
    }
  });
};

const getValue = fontSize => typeof fontSize === 'number' ? `${fontSize}px` : fontSize;
const TypeScale = ({
  reverse = true,
  ...props
}) => {
  const theme = useTheme() || {};
  const fontSizeEntries = reverse ? Object.entries(theme.fontSizes || []).reverse() : Object.entries(theme.fontSizes || []);
  return jsx("div", {
    sx: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline'
    },
    children: fontSizeEntries.map(([key, val]) => {
      if (!val || typeof val === 'object') {
        // TODO: `theme.fontSizes` can be a deeply nested object.
        // We should either update the types or recursively render here.
        return null;
      }
      return jsx(TypeStyle, {
        fontSize: key,
        sx: {
          mr: 3
        },
        children: getValue(val),
        ...props
      }, key);
    })
  });
};

const HeadingStyle = props => jsx(TypeStyle, {
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
  ...props
});

const FontFamily = ({
  name
}) => {
  const {
    fonts
  } = useTheme();
  return get(fonts, name);
};

const toHex = raw => {
  try {
    return Color(raw).hex();
  } catch (e) {
    return;
  }
};

const ColorSwatch = ({
  color,
  name,
  size = 128,
  label = true,
  ...props
}) => {
  const theme = useTheme();
  const value = getColor(theme, color);
  return jsxs("div", {
    ...props,
    title: `${toHex(value)}`,
    children: [jsx("div", {
      sx: {
        width: size,
        height: size,
        bg: color
      }
    }), label && jsx("div", {
      sx: {
        py: 2
      },
      children: name || color
    })]
  });
};

const join = (...args) => args.filter(Boolean).join('.');
const ColorRow = ({
  colors,
  name,
  omit = ['modes'],
  render,
  size,
  ...props
}) => {
  return jsx("div", {
    children: jsx("div", {
      sx: {
        display: 'flex',
        flexWrap: 'wrap'
      },
      children: Object.keys(colors).map(key => {
        const color = colors[key];
        if (!color || omit.includes(key)) return false;
        const id = join(name, key);
        if (typeof color === 'object') {
          return createElement(ColorRow, {
            ...props,
            key: key,
            name: id,
            colors: color,
            size: size,
            omit: omit
          });
        }
        const swatch = createElement(ColorSwatch, {
          ...props,
          key: key,
          name: id,
          color: id,
          size: size,
          sx: {
            m: 2
          }
        });
        if (typeof render === 'function') {
          return render({
            swatch,
            color,
            key,
            name: id
          });
        }
        return swatch;
      })
    })
  });
};
const ColorPalette = ({
  omit,
  mode,
  ...props
}) => {
  const theme = useTheme();
  let colors = theme.colors;
  if (mode && colors.modes) {
    colors = colors.modes[mode] || colors;
  }
  return jsx("div", {
    style: {
      marginLeft: -8,
      marginRight: -8
    },
    children: jsx(ColorRow, {
      ...props,
      omit: omit,
      colors: colors
    })
  });
};

const ThemeCard = props => {
  return jsxs(Card, {
    ...props,
    sx: {
      color: 'text',
      bg: 'background'
    },
    children: [jsx(TypeStyle, {}), jsx(ColorPalette, {
      label: false,
      size: 32
    })]
  });
};

export { ColorPalette, ColorSwatch, FontFamily, HeadingStyle, ThemeCard, TypeScale, TypeStyle, useTheme };
