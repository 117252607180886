/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    a: "a",
    pre: "pre"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "global-styles"
  }, "Global Styles"), "\n", React.createElement(_components.p, null, "Theme UI offers a ", React.createElement(_components.code, null, "Global"), " component (that wraps Emotion’s) for adding global\nCSS with theme-based values."), "\n", React.createElement(_components.p, null, "By default (or, unless the\n", React.createElement(_components.a, {
    href: "/theming#configuration-flags"
  }, React.createElement(_components.code, null, "useRootStyles"), " configuration option"), "is\ndisabled), the ", React.createElement(_components.code, null, "ThemeUIProvider"), " component will apply styles in\n", React.createElement(_components.code, null, "theme.styles.root"), " to the ", React.createElement(_components.code, null, "<html>"), " element. It will also apply ", React.createElement(_components.code, null, "color"), " and\n", React.createElement(_components.code, null, "background-color"), " styles based on ", React.createElement(_components.code, null, "theme.colors.text"), " and\n", React.createElement(_components.code, null, "theme.colors.background"), " respectively."), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "Generally, you should try to avoid adding CSS to global scope. Many styles can\nbe safely encapsulated into a component without the need for global styles.")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { Global } from 'theme-ui'\n\nexport default (props) => (\n  <Global\n    styles={{\n      button: {\n        m: 0,\n        bg: 'primary',\n        color: 'background',\n        border: 0,\n      },\n    }}\n  />\n)\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "If you are upgrading from a version of theme-ui older that v0.6.0, be aware the\nimport package has changed from ", React.createElement(_components.code, null, "@emotion/core"), " to ", React.createElement(_components.code, null, "@emotion/react"), ". For more\ninformation see the\n", React.createElement(_components.a, {
    href: "https://theme-ui.com/migrating/#breaking-changes"
  }, "Migration Notes for 0.6"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
