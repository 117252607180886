import { jsx } from '@theme-ui/core';
import { css } from '@theme-ui/css';
import { Global as Global$1 } from '@emotion/react';

const Global = ({
  styles
}) => jsx(Global$1, {
  styles: emotionTheme => {
    const theme = emotionTheme;
    return css(styles)(theme);
  }
});

export { Global as default };
