/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "how-it-works"
  }, "How it Works"), "\n", React.createElement(_components.p, null, "Theme UI combines several libraries together to form a mini-framework for\nstyling applications."), "\n", React.createElement(_components.p, null, "Theme UI is built with:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://emotion.sh"
  }, "Emotion"), ": used to generate isolated CSS with theming context"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mdxjs.com"
  }, "MDX"), ": used to provide custom Emotion styled components to MDX documents,\nwithout polluting the global CSS scope"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/KyleAMathews/typography.js"
  }, "Typography.js"), ": optionally used for creating rich typographic styles with a\nsimple, high-level API"), "\n"), "\n", React.createElement(_components.h2, {
    id: "themeuiprovider"
  }, "ThemeUIProvider"), "\n", React.createElement(_components.p, null, "The Theme UI ", React.createElement(_components.code, null, "ThemeUIProvider"), " component wraps Emotion's ", React.createElement(_components.code, null, "ThemeProvider"), ", which\nadds the ", React.createElement(_components.code, null, "theme"), " object to context for use with Emotion."), "\n", React.createElement(_components.h2, {
    id: "custom-components"
  }, "Custom Components"), "\n", React.createElement(_components.p, null, "When using the ", React.createElement(_components.code, null, "components"), " prop to add custom MDX components, each component is\nwrapped with the ", React.createElement(_components.code, null, "sx"), " prop to allow the component to pick up values from the\n", React.createElement(_components.code, null, "theme.styles"), " object."), "\n", React.createElement(_components.h2, {
    id: "jsx-pragma"
  }, "JSX Pragma"), "\n", React.createElement(_components.p, null, "The Theme UI JSX function wraps the Emotion JSX function, converting the ", React.createElement(_components.code, null, "sx"), "\nprop to a call to ", React.createElement(_components.code, null, "@theme-ui/css"), ". The two examples below yield the same\nresults:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "// with Emotion's JSX function\n// this is typically handled with the Emotion Babel preset\n/** @jsxImportSource @emotion/react */\nimport { css } from 'theme-ui'\n\nexport default (props) => (\n  <div\n    {...props}\n    css={css({\n      color: 'white',\n      bg: 'primary',\n      fontSize: 4,\n    })}\n  />\n)\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "// with Theme UI's JSX function\n/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    {...props}\n    sx={{\n      color: 'white',\n      bg: 'primary',\n      fontSize: 4,\n    }}\n  />\n)\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "Confused about this ", React.createElement(_components.code, null, "@jsxImportSource"), " comment? Read more about\n", React.createElement(_components.a, {
    href: "/guides/jsx-pragma"
  }, "JSX Pragma"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
