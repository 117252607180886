/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "text"
  }, "Text"), "\n", React.createElement(_components.p, null, "Primitive typographic component."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Text } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Text variant='caps'>Hello</Text>\n<Text\n  sx={{\n    fontSize: 2,\n    fontWeight: 'bold',\n    display: 'block'\n  }}\n>\n  How's it going?\n</Text>\n")), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Text style variants can be defined in the ", React.createElement(_components.code, null, "theme.text"), " object. The Text\ncomponent uses ", React.createElement(_components.code, null, "theme.text.default"), " as its default variant style."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme variants\n{\n  text: {\n    default: {\n      color: 'text',\n      fontSize: 3,\n    },\n    caps: {\n      textTransform: 'uppercase',\n      letterSpacing: '0.2em',\n    },\n    heading: {\n      fontFamily: 'heading',\n      fontWeight: 'heading',\n      lineHeight: 'heading',\n    },\n  }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
