/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    em: "em",
    h2: "h2",
    code: "code",
    pre: "pre",
    ul: "ul",
    li: "li",
    a: "a",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "color-modes"
  }, "Color Modes"), "\n", React.createElement(_components.p, null, "Color modes can be used to create a user-configurable ", React.createElement(_components.em, null, "dark mode"), " or any number\nof other color modes."), "\n", React.createElement(_components.h2, {
    id: "defining-colors"
  }, "Defining colors"), "\n", React.createElement(_components.p, null, "In the ", React.createElement(_components.code, null, "theme.colors"), " object, add a nested ", React.createElement(_components.code, null, "modes"), " object that will contain keys\nfor optional color modes."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme colors\n{\n  config: {\n    initialColorModeName: 'light',\n  },\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n        primary: '#0cf',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.p, null, "In your components, using e.g. ", React.createElement(_components.code, null, "sx={{ color: 'text' }}"), " will automatically pick up the current color mode, with no adaptation necessary, even if you add more color modes later."), "\n", React.createElement(_components.h2, {
    id: "initial-colors"
  }, "Initial colors"), "\n", React.createElement(_components.p, null, "The colors defined at the root of the ", React.createElement(_components.code, null, "colors"), " are swapped out whenever the\ncolor mode changes change. This is to allow for reference like\n", React.createElement(_components.code, null, "theme.colors.primary"), " to return:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "colors.primary"), ", when the color mode is set to its initial mode"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "colors.modes.dark.primary"), ", when the color mode is set to ", React.createElement(_components.code, null, "dark")), "\n"), "\n", React.createElement(_components.p, null, "By default, this works by setting CSS Custom Properties for each theme color, then when the color mode is changed, updating the properties. This makes color modes SSR-safe, since the generated CSS for your components doesn’t rely on knowing the user’s color mode to render. See below how to access the raw color values or disable the use off Custom Properties."), "\n", React.createElement(_components.h2, {
    id: "setting-the-color-mode"
  }, "Setting the color mode"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.a, {
    href: "/hooks#usecolormode"
  }, React.createElement(_components.code, null, "useColorMode")), " hook in your application to change\nthe color mode. This value will be stored in ", React.createElement(_components.code, null, "localStorage"), " and used whenever\nthe page is loaded."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { useColorMode } from 'theme-ui'\n\nexport default (props) => {\n  const [colorMode, setColorMode] = useColorMode()\n  return (\n    <header>\n      <button\n        onClick={(e) => {\n          setColorMode(colorMode === 'default' ? 'dark' : 'default')\n        }}\n      >\n        Toggle {colorMode === 'default' ? 'Dark' : 'Light'}\n      </button>\n    </header>\n  )\n}\n")), "\n", React.createElement(_components.p, null, "See our ", React.createElement(_components.a, {
    href: "/guides/color-mode-toggles"
  }, "guide to color mode toggles"), " for more."), "\n", React.createElement(_components.h2, {
    id: "applying-page-wide-colors"
  }, "Applying page-wide colors"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "ThemeUIProvider"), " component will automatically apply color mode styles (by setting ", React.createElement(_components.code, null, "color"), " and ", React.createElement(_components.code, null, "background-color"), ") to the\n", React.createElement(_components.code, null, "<html>"), " element."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { ThemeUIProvider } from 'theme-ui'\nimport theme from './theme'\n\nexport default (props) => (\n  <ThemeUIProvider theme={theme}>{props.children}</ThemeUIProvider>\n)\n")), "\n", React.createElement(_components.p, null, "To disable this behavior, add the ", React.createElement(_components.code, null, "useRootStyles: false"), " flag to your theme."), "\n", React.createElement(_components.p, null, "If you’d like to apply your theme color to the browser, ", React.createElement(_components.a, {
    href: "/guides/theme-color-meta-tag"
  }, "see our guide to the theme color meta tag"), "."), "\n", React.createElement(_components.h3, {
    id: "gatsby-plugin"
  }, "Gatsby plugin"), "\n", React.createElement(_components.p, null, "For use in a Gatsby site, install and use ", React.createElement(_components.code, null, "gatsby-plugin-theme-ui"), " to add the\nThemeUIProvider to the root of your application. The plugin will also help\nprevent the flash of colors that can happen during page load when a user has a\nnon-default color mode set."), "\n", React.createElement(_components.p, null, "This plugin will look for a ", React.createElement(_components.code, null, "src/gatsby-plugin-theme-ui/index.js"), " file to import\nand pass to the ThemeUIProvider."), "\n", React.createElement(_components.p, null, "See the ", React.createElement(_components.a, {
    href: "/packages/gatsby-plugin"
  }, "Gatsby plugin docs"), " for more info & examples."), "\n", React.createElement(_components.h2, {
    id: "configuration"
  }, "Configuration"), "\n", React.createElement(_components.p, null, "Theme UI includes a few advanced configuration options for color modes."), "\n", React.createElement(_components.h3, {
    id: "responding-to-the-prefers-color-scheme-media-query"
  }, "Responding to the ", React.createElement(_components.code, null, "prefers-color-scheme"), " media query"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "useColorSchemeMediaQuery"), " option on the theme configuration initializes a\ncolor mode based on the ", React.createElement(_components.code, null, "prefers-color-scheme"), " media query. This will set the\ninitial color mode to ", React.createElement(_components.code, null, "dark"), " when ", React.createElement(_components.code, null, "@media (prefers-color-scheme: dark)"), " matches,\nor ", React.createElement(_components.code, null, "light"), " when ", React.createElement(_components.code, null, "@media (prefers-color-scheme: light)"), " matches. If you do not\nhave a color mode named ", React.createElement(_components.code, null, "dark"), " or ", React.createElement(_components.code, null, "light"), ", this will have no effect. This is\nenabled by default."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  config: {\n    useColorSchemeMediaQuery: false,\n  },\n  colors: {\n    text: '#000',\n    background: '#fff',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.p, null, "To enable the color mode to update when a user's current ", React.createElement(_components.code, null, "prefers-color-scheme"), " media query value changes, set ", React.createElement(_components.code, null, "useColorSchemeMediaQuery"), " to ", React.createElement(_components.code, null, "'system'"), "."), "\n", React.createElement(_components.h3, {
    id: "disable-persisting-color-mode-on-localstorage"
  }, "Disable persisting color mode on ", React.createElement(_components.code, null, "localStorage")), "\n", React.createElement(_components.p, null, "To disable ", React.createElement(_components.code, null, "localStorage"), ", add the ", React.createElement(_components.code, null, "useLocalStorage: false"), " flag to your theme\nconfiguration."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  config: {\n    useLocalStorage: false\n  },\n  colors: {\n    text: '#000',\n    background: '#fff',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.h3, {
    id: "set-a-custom-color-mode-for-printing"
  }, "Set a custom color mode for printing"), "\n", React.createElement(_components.p, null, "By default, when printing a webpage, browsers will use the current color mode\nenabled. This means if a user is currently using a dark or colored-background\nmode, their printed page will share that styling."), "\n", React.createElement(_components.p, null, "If you’d like to set a color\nmode to be used on printing, such as light mode, set that color mode with the configuration option\n", React.createElement(_components.code, null, "printColorModeName"), ", set to one of your ", React.createElement(_components.code, null, "colors.modes"), " names, the\n", React.createElement(_components.code, null, "initialColorModeName"), " value, or the string ", React.createElement(_components.code, null, "'initial'"), "."), "\n", React.createElement(_components.p, null, "This option sets your color mode in the ", React.createElement(_components.code, null, "@media print"), " media query, so there’s\nno additional client-side JavaScript for printing."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  config: {\n    initialColorModeName: 'light',\n    printColorModeName: 'light',\n  },\n  colors: {\n    text: '#000',\n    background: '#fff',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.h3, {
    id: "turn-off-custom-properties"
  }, "Turn off custom properties"), "\n", React.createElement(_components.p, null, "Theme UI uses ", React.createElement(_components.a, {
    href: "https://caniuse.com/#feat=css-variables"
  }, "CSS custom properties"), "\nunder the hood to help prevent the flash of color on load. If you’re targeting\nbrowsers that don't support custom properties, you can turn off this setting with ", React.createElement(_components.code, null, "useCustomProperties: false"), ".\nThis will cause the colors to flash on initial page load."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme colors\n{\n  config: {\n    useCustomProperties: false,\n  },\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n        primary: '#0cf',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "advanced-accessing-theme-colors-explicitly"
  }, "Advanced: Accessing theme colors explicitly"), "\n", React.createElement(_components.h3, {
    id: "colors-object"
  }, "Colors object"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "colors"), " object contains Custom CSS Properties"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  colors: {\n    text: 'var(--theme-ui-colors-text)',\n    background: 'var(--theme-ui-colors-background)',\n    primary: 'var(--theme-ui-colors-primary)',\n  }\n}\n")), "\n", React.createElement(_components.h3, {
    id: "rawcolors-object"
  }, "rawColors object"), "\n", React.createElement(_components.p, null, "If you need to pass original value somewhere where CSS Properties (e.g. WebGL\nCanvas) won't work, use ", React.createElement(_components.code, null, "rawColors"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  rawColors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n  }\n}\n")), "\n", React.createElement(_components.p, null, "All colors found in ", React.createElement(_components.code, null, "colors.modes"), " will be referenced by their key in the\ncontext object ", React.createElement(_components.code, null, "rawColors.modes"), "."), "\n", React.createElement(_components.h3, {
    id: "with-the-sx-prop"
  }, "With the ", React.createElement(_components.code, null, "sx"), " prop"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "export default (props) => (\n  <div\n    sx={(theme) => ({\n      color: theme.rawColors.modes?.dark?.text\n      bg: theme.rawColors.modes?.dark?.bg\n    })}\n  />\n)\n")), "\n", React.createElement(_components.h3, {
    id: "with-theme-ui-context"
  }, "With Theme UI context"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.a, {
    href: "/hooks#usethemeui"
  }, React.createElement(_components.code, null, "useThemeUI")), " hook to access the context object\ndirectly in a component. The theme object it includes contains ", React.createElement(_components.code, null, "colors"), " and ", React.createElement(_components.code, null, "rawColors"), "."), "\n", React.createElement("details", null, React.createElement("summary", null, "Code example"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { useThemeUI } from 'theme-ui'\n\nexport default (props) => {\n  const { theme: { rawColors }, setColorMode } = useThemeUI()\n\n  return Object.entries(rawColors?.modes).map(([mode, values]) => ({\n    <Button\n      sx={{ bg: values.background, color: values.text }}\n      onClick={() => setColorMode(mode)}\n    >\n      {mode}\n    </Button>\n  }))\n}\n\n// OUTPUT\n\n<Button>light</Button>\n<Button>dark</Button>\n<Button>deep</Button>\n// ...\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
