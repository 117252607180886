/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "embed"
  }, "Embed"), "\n", React.createElement(_components.p, null, "Responsive iframe for video embeds."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Embed } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    live: "true"
  }, "<Embed src=\"https://www.youtube.com/embed/GNCd_ERZvZM\" />\n")), "\n", React.createElement(_components.p, null, "Props besides ", React.createElement(_components.a, {
    href: "/sx-prop"
  }, React.createElement(_components.code, null, "sx")), " will be passed to the ", React.createElement(_components.code, null, "iframe"), " itself, since\nusing a ", React.createElement(_components.a, {
    href: "https://accessibility.18f.gov/iframes/"
  }, React.createElement(_components.code, null, "title"), " prop"), " or other labels are recommended\nfor accessibility."), "\n", React.createElement(_components.p, null, "Embed variants can be defined anywhere in the ", React.createElement(_components.code, null, "theme"), " object."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
