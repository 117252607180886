/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "header-a3"
  }, "Header A3"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: ""
  }, "<header\n  sx={{\n    display: 'grid',\n    gridGap: 3,\n    maxWidth: 768,\n    mx: 'auto',\n    px: 3,\n    py: 3,\n    gridAutoFlow: 'row',\n    gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(3, 1fr)'],\n    variant: 'styles.header',\n  }}>\n  <div\n    sx={{\n      display: 'flex',\n      alignItems: 'center',\n      justifyContent: 'center',\n      gridColumnStart: [1, 2],\n      gridColumnEnd: [3, 3],\n      order: [0, 1],\n    }}>\n    <Link to=\"/\" title=\"Home\">\n      <img\n        alt=\"UI Logo\"\n        src=\"https://contrast.now.sh/cff/40f?size=48&fontSize=2&baseline=2&fontWeight=900&radius=32&text=UI\"\n      />\n      <span\n        sx={{\n          position: 'absolute',\n          width: 1,\n          height: 1,\n          overflow: 'hidden',\n          top: -9999,\n        }}>\n        Home\n      </span>\n    </Link>\n  </div>\n  <div\n    sx={{\n      display: 'flex',\n      alignItems: 'center',\n      justifyContent: 'flex-start',\n    }}>\n    <Link\n      to=\"/work\"\n      sx={{\n        variant: 'styles.navlink',\n        p: 2,\n      }}>\n      Work\n    </Link>\n    <Link\n      to=\"/blog\"\n      sx={{\n        variant: 'styles.navlink',\n        p: 2,\n      }}>\n      Blog\n    </Link>\n  </div>\n  <div\n    sx={{\n      display: 'flex',\n      alignItems: 'center',\n      justifyContent: 'flex-end',\n      order: 2,\n    }}>\n    <Link\n      to=\"/about\"\n      sx={{\n        variant: 'styles.navlink',\n        p: 2,\n      }}>\n      About\n    </Link>\n    <Link\n      to=\"/contact\"\n      sx={{\n        variant: 'styles.navlink',\n        p: 2,\n      }}>\n      Contact\n    </Link>\n  </div>\n</header>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
