/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "container"
  }, "Container"), "\n", React.createElement(_components.p, null, "Centered, max-width layout component"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Container } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Container p={4} bg=\"muted\">\n  Centered container\n</Container>\n")), "\n", React.createElement(_components.p, null, "The Container component's max-width can be defined in ", React.createElement(_components.code, null, "theme.sizes.container"), "."), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Container variants can be defined in the ", React.createElement(_components.code, null, "theme.layout"), " object.\nThe Container component uses ", React.createElement(_components.code, null, "theme.layout.container"), " as its default variant style."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
