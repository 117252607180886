/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    em: "em",
    code: "code",
    a: "a",
    h3: "h3",
    h4: "h4",
    strong: "strong",
    pre: "pre",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "theme-color-meta-tag"
  }, "Theme Color Meta Tag"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Learn more about ", React.createElement(_components.code, null, "<meta />"), " theme color on\n", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta/name/theme-color"
  }, "MDN"), "\nand ", React.createElement(_components.a, {
    href: "https://css-tricks.com/meta-theme-color-and-trickery/"
  }, "CSS Tricks"), ".")), "\n", React.createElement(_components.h3, {
    id: "with-usethemeui"
  }, "with ", React.createElement(_components.code, null, "useThemeUI")), "\n", React.createElement(_components.p, null, "Grab your theme from the context with ", React.createElement(_components.code, null, "useThemeUI"), ", pick either a background\nor accent color from the palette, then use your framework’s method of adding\na meta tag to add to ", React.createElement(_components.code, null, "<head />"), "."), "\n", React.createElement(_components.h4, {
    id: "react-helmet-in-gatsby"
  }, React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "https://github.com/nfl/react-helmet"
  }, React.createElement(_components.code, null, "react-helmet")), " in Gatsby")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { Helmet } from 'react-helmet'\nimport { useThemeUI } from 'theme-ui'\n\nfunction Example() {\n  const { theme } = useThemeUI()\n\n  return (\n    <Helmet>\n      <meta name=\"theme-color\" content={theme.colors.primary} />\n    </Helmet>\n  )\n}\n")), "\n", React.createElement(_components.h4, {
    id: "nexthead-in-nextjs"
  }, React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "https://nextjs.org/docs/api-reference/next/head"
  }, React.createElement(_components.code, null, "next/head")), " in Next.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "import Head from 'next/head'\n\nfunction Example() {\n  const { theme } = useThemeUI()\n\n  return (\n    <div>\n      <Head>\n        <title>My page title</title>\n        <meta name=\"theme-color\" content={theme.colors.background} />\n      </Head>\n      <p>Hello world!</p>\n    </div>\n  )\n}\n")), "\n", React.createElement(_components.h3, {
    id: "using-css-custom-properties"
  }, "using CSS Custom Properties"), "\n", React.createElement(_components.p, null, "You can use custom CSS Properties added by ", React.createElement(_components.code, null, "theme-ui"), " to access the colors from\na static HTML file."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<meta\n  name=\"theme-color\"\n  content=\"var(--theme-ui-colors-primary)\"\n  media=\"(prefers-color-scheme: light)\"\n/>\n<meta\n  name=\"theme-color\"\n  content=\"var(--theme-ui-colors-background)\"\n  media=\"(prefers-color-scheme: dark)\"\n/>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Take note that ", React.createElement(_components.code, null, "@theme-ui/core"), " does not create CSS custom properties. This\nrecipe works only with the ", React.createElement(_components.code, null, "theme-ui"), " umbrella package.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
