/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    a: "a",
    em: "em",
    pre: "pre",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "themed-component"
  }, "Themed component"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "Themed"), " components dict to render UI with styles from\n", React.createElement(_components.a, {
    href: "/theming#styles"
  }, React.createElement(_components.code, null, "theme.styles")), " ", React.createElement(_components.em, null, "outside"), " of MDX or inside of more complex\ncomponents meant for embedding in MDX."), "\n", React.createElement(_components.p, null, "For example, if you'd like to reuse heading styles in a React component, you can\nuse the ", React.createElement(_components.code, null, "Themed.h1"), " component to render an ", React.createElement(_components.code, null, "<h1>"), " element with styles from\n", React.createElement(_components.code, null, "theme.styles.h1"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "import type { ComponentPropsWithoutRef } from 'react'\nimport { Themed } from '@theme-ui/mdx'\n\nexport const components = {\n  h2: (props: ComponentPropsWithoutRef<'h2'>) => (\n    <h2 {...props}>\n      {props.id ? (\n        <Themed.a href={`#${props.id}`}>{props.children}</a>\n      ) : (\n        props.children\n      )}\n    </h2>\n  ),\n}\n\nexport const theme = {\n  styles: {\n    a: {\n      textDecoration: 'none',\n      ':hover': {\n        textDecoration: 'underline',\n      },\n    },\n  },\n}\n")), "\n", React.createElement(_components.p, null, "For a full list of keys available, see the\n", React.createElement(_components.a, {
    href: "/theme-spec#styles"
  }, "Theme Specification docs"), "."), "\n", React.createElement(_components.h2, {
    id: "global-styles"
  }, "Global styles"), "\n", React.createElement(_components.p, null, "To add base typographic styles to the ", React.createElement(_components.code, null, "<html>"), " element, add styles to\n", React.createElement(_components.code, null, "theme.styles.root"), ". See the ", React.createElement(_components.a, {
    href: "/theming/#root-styles"
  }, "theming docs"), " for more\ninformation, including how to disable this."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
