/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "layouts"
  }, "Layouts"), "\n", React.createElement(_components.p, null, "By leveraging the ", React.createElement(_components.a, {
    href: "/guides/variants"
  }, "variants"), " feature, you can create custom themeable layout components.\nThe components can include some default styles,\nbut still allow users to override styles such as colors or typography, by changing values in the theme object."), "\n", React.createElement(_components.p, null, "To start, create a layout component that will wrap pages in your application.\nFeel free to customize the content and default styles however you like."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example src/layout.js\n/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    sx={{\n      display: 'flex',\n      flexDirection: 'column',\n      minHeight: '100vh',\n    }}>\n    <header\n      sx={{\n        width: '100%',\n        display: 'flex',\n        alignItems: 'center',\n      }}>\n      Header content\n    </header>\n    <main\n      sx={{\n        width: '100%',\n        flex: '1 1 auto',\n      }}>\n      <div\n        sx={{\n          maxWidth: 768,\n          mx: 'auto',\n          px: 3,\n        }}>\n        {props.children}\n      </div>\n    </main>\n    <footer\n      sx={{\n        width: '100%',\n      }}>\n      Footer content\n    </footer>\n  </div>\n)\n")), "\n", React.createElement(_components.p, null, "Next use the ", React.createElement(_components.code, null, "variant"), " property to name parts of the layout."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example src/layout.js\n/** @jsxImportSource theme-ui */\n\nexport default (props) => (\n  <div\n    sx={{\n      display: 'flex',\n      flexDirection: 'column',\n      minHeight: '100vh',\n      variant: 'layout.root',\n    }}>\n    <header\n      sx={{\n        width: '100%',\n        display: 'flex',\n        alignItems: 'center',\n        variant: 'layout.header',\n      }}>\n      Header content\n    </header>\n    <main\n      sx={{\n        width: '100%',\n        flex: '1 1 auto',\n        variant: 'layout.main',\n      }}>\n      <div\n        sx={{\n          maxWidth: 768,\n          mx: 'auto',\n          px: 3,\n          variant: 'layout.container',\n        }}>\n        {props.children}\n      </div>\n    </main>\n    <footer\n      sx={{\n        width: '100%',\n        variant: 'layout.footer',\n      }}>\n      Footer content\n    </footer>\n  </div>\n)\n")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "variant"), " properties above will look for styles in ", React.createElement(_components.code, null, "theme.layout"), ".\nIf no styles are defined in the theme, the default styles will remain unchanged."), "\n", React.createElement(_components.p, null, "If a user wants to customize the layout styles, they can add overrides to their theme object.\nFor example, the following will add custom colors to the header and footer."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example user theme\nimport colors from './colors'\n\nexport default {\n  colors,\n  layout: {\n    header: {\n      color: 'white',\n      backgroundColor: 'black',\n    },\n    footer: {\n      color: 'white',\n      backgroundColor: 'black',\n    },\n  },\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
