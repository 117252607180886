exports.components = {
  "component---gatsby-theme-style-guide-src-template-js": () => import("./../../../../gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---gatsby-theme-style-guide-src-template-js" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-api-mdx": () => import("./../../../src/pages/api.mdx" /* webpackChunkName: "component---src-pages-api-mdx" */),
  "component---src-pages-color-modes-mdx": () => import("./../../../src/pages/color-modes.mdx" /* webpackChunkName: "component---src-pages-color-modes-mdx" */),
  "component---src-pages-components-alert-mdx": () => import("./../../../src/pages/components/alert.mdx" /* webpackChunkName: "component---src-pages-components-alert-mdx" */),
  "component---src-pages-components-aspect-image-mdx": () => import("./../../../src/pages/components/aspect-image.mdx" /* webpackChunkName: "component---src-pages-components-aspect-image-mdx" */),
  "component---src-pages-components-aspect-ratio-mdx": () => import("./../../../src/pages/components/aspect-ratio.mdx" /* webpackChunkName: "component---src-pages-components-aspect-ratio-mdx" */),
  "component---src-pages-components-avatar-mdx": () => import("./../../../src/pages/components/avatar.mdx" /* webpackChunkName: "component---src-pages-components-avatar-mdx" */),
  "component---src-pages-components-badge-mdx": () => import("./../../../src/pages/components/badge.mdx" /* webpackChunkName: "component---src-pages-components-badge-mdx" */),
  "component---src-pages-components-box-mdx": () => import("./../../../src/pages/components/box.mdx" /* webpackChunkName: "component---src-pages-components-box-mdx" */),
  "component---src-pages-components-button-mdx": () => import("./../../../src/pages/components/button.mdx" /* webpackChunkName: "component---src-pages-components-button-mdx" */),
  "component---src-pages-components-card-mdx": () => import("./../../../src/pages/components/card.mdx" /* webpackChunkName: "component---src-pages-components-card-mdx" */),
  "component---src-pages-components-checkbox-mdx": () => import("./../../../src/pages/components/checkbox.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-mdx" */),
  "component---src-pages-components-close-mdx": () => import("./../../../src/pages/components/close.mdx" /* webpackChunkName: "component---src-pages-components-close-mdx" */),
  "component---src-pages-components-container-mdx": () => import("./../../../src/pages/components/container.mdx" /* webpackChunkName: "component---src-pages-components-container-mdx" */),
  "component---src-pages-components-divider-mdx": () => import("./../../../src/pages/components/divider.mdx" /* webpackChunkName: "component---src-pages-components-divider-mdx" */),
  "component---src-pages-components-donut-mdx": () => import("./../../../src/pages/components/donut.mdx" /* webpackChunkName: "component---src-pages-components-donut-mdx" */),
  "component---src-pages-components-embed-mdx": () => import("./../../../src/pages/components/embed.mdx" /* webpackChunkName: "component---src-pages-components-embed-mdx" */),
  "component---src-pages-components-field-mdx": () => import("./../../../src/pages/components/field.mdx" /* webpackChunkName: "component---src-pages-components-field-mdx" */),
  "component---src-pages-components-flex-mdx": () => import("./../../../src/pages/components/flex.mdx" /* webpackChunkName: "component---src-pages-components-flex-mdx" */),
  "component---src-pages-components-forms-mdx": () => import("./../../../src/pages/components/forms.mdx" /* webpackChunkName: "component---src-pages-components-forms-mdx" */),
  "component---src-pages-components-grid-mdx": () => import("./../../../src/pages/components/grid.mdx" /* webpackChunkName: "component---src-pages-components-grid-mdx" */),
  "component---src-pages-components-heading-mdx": () => import("./../../../src/pages/components/heading.mdx" /* webpackChunkName: "component---src-pages-components-heading-mdx" */),
  "component---src-pages-components-icon-button-mdx": () => import("./../../../src/pages/components/icon-button.mdx" /* webpackChunkName: "component---src-pages-components-icon-button-mdx" */),
  "component---src-pages-components-image-mdx": () => import("./../../../src/pages/components/image.mdx" /* webpackChunkName: "component---src-pages-components-image-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../../../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-components-input-mdx": () => import("./../../../src/pages/components/input.mdx" /* webpackChunkName: "component---src-pages-components-input-mdx" */),
  "component---src-pages-components-label-mdx": () => import("./../../../src/pages/components/label.mdx" /* webpackChunkName: "component---src-pages-components-label-mdx" */),
  "component---src-pages-components-link-mdx": () => import("./../../../src/pages/components/link.mdx" /* webpackChunkName: "component---src-pages-components-link-mdx" */),
  "component---src-pages-components-menu-button-mdx": () => import("./../../../src/pages/components/menu-button.mdx" /* webpackChunkName: "component---src-pages-components-menu-button-mdx" */),
  "component---src-pages-components-message-mdx": () => import("./../../../src/pages/components/message.mdx" /* webpackChunkName: "component---src-pages-components-message-mdx" */),
  "component---src-pages-components-nav-link-mdx": () => import("./../../../src/pages/components/nav-link.mdx" /* webpackChunkName: "component---src-pages-components-nav-link-mdx" */),
  "component---src-pages-components-paragraph-mdx": () => import("./../../../src/pages/components/paragraph.mdx" /* webpackChunkName: "component---src-pages-components-paragraph-mdx" */),
  "component---src-pages-components-progress-mdx": () => import("./../../../src/pages/components/progress.mdx" /* webpackChunkName: "component---src-pages-components-progress-mdx" */),
  "component---src-pages-components-radio-mdx": () => import("./../../../src/pages/components/radio.mdx" /* webpackChunkName: "component---src-pages-components-radio-mdx" */),
  "component---src-pages-components-select-mdx": () => import("./../../../src/pages/components/select.mdx" /* webpackChunkName: "component---src-pages-components-select-mdx" */),
  "component---src-pages-components-slider-mdx": () => import("./../../../src/pages/components/slider.mdx" /* webpackChunkName: "component---src-pages-components-slider-mdx" */),
  "component---src-pages-components-spinner-mdx": () => import("./../../../src/pages/components/spinner.mdx" /* webpackChunkName: "component---src-pages-components-spinner-mdx" */),
  "component---src-pages-components-switch-mdx": () => import("./../../../src/pages/components/switch.mdx" /* webpackChunkName: "component---src-pages-components-switch-mdx" */),
  "component---src-pages-components-text-mdx": () => import("./../../../src/pages/components/text.mdx" /* webpackChunkName: "component---src-pages-components-text-mdx" */),
  "component---src-pages-components-textarea-mdx": () => import("./../../../src/pages/components/textarea.mdx" /* webpackChunkName: "component---src-pages-components-textarea-mdx" */),
  "component---src-pages-components-variants-mdx": () => import("./../../../src/pages/components/variants.mdx" /* webpackChunkName: "component---src-pages-components-variants-mdx" */),
  "component---src-pages-demo-mdx": () => import("./../../../src/pages/demo.mdx" /* webpackChunkName: "component---src-pages-demo-mdx" */),
  "component---src-pages-design-graph-mdx": () => import("./../../../src/pages/design-graph.mdx" /* webpackChunkName: "component---src-pages-design-graph-mdx" */),
  "component---src-pages-getting-started-gatsby-mdx": () => import("./../../../src/pages/getting-started/gatsby.mdx" /* webpackChunkName: "component---src-pages-getting-started-gatsby-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-guides-color-mode-toggles-mdx": () => import("./../../../src/pages/guides/color-mode-toggles.mdx" /* webpackChunkName: "component---src-pages-guides-color-mode-toggles-mdx" */),
  "component---src-pages-guides-custom-cache-provider-mdx": () => import("./../../../src/pages/guides/custom-cache-provider.mdx" /* webpackChunkName: "component---src-pages-guides-custom-cache-provider-mdx" */),
  "component---src-pages-guides-global-styles-mdx": () => import("./../../../src/pages/guides/global-styles.mdx" /* webpackChunkName: "component---src-pages-guides-global-styles-mdx" */),
  "component---src-pages-guides-how-it-works-mdx": () => import("./../../../src/pages/guides/how-it-works.mdx" /* webpackChunkName: "component---src-pages-guides-how-it-works-mdx" */),
  "component---src-pages-guides-index-mdx": () => import("./../../../src/pages/guides/index.mdx" /* webpackChunkName: "component---src-pages-guides-index-mdx" */),
  "component---src-pages-guides-jsx-pragma-mdx": () => import("./../../../src/pages/guides/jsx-pragma.mdx" /* webpackChunkName: "component---src-pages-guides-jsx-pragma-mdx" */),
  "component---src-pages-guides-keyframes-mdx": () => import("./../../../src/pages/guides/keyframes.mdx" /* webpackChunkName: "component---src-pages-guides-keyframes-mdx" */),
  "component---src-pages-guides-layouts-mdx": () => import("./../../../src/pages/guides/layouts.mdx" /* webpackChunkName: "component---src-pages-guides-layouts-mdx" */),
  "component---src-pages-guides-merging-themes-mdx": () => import("./../../../src/pages/guides/merging-themes.mdx" /* webpackChunkName: "component---src-pages-guides-merging-themes-mdx" */),
  "component---src-pages-guides-motivation-mdx": () => import("./../../../src/pages/guides/motivation.mdx" /* webpackChunkName: "component---src-pages-guides-motivation-mdx" */),
  "component---src-pages-guides-nested-theme-providers-mdx": () => import("./../../../src/pages/guides/nested-theme-providers.mdx" /* webpackChunkName: "component---src-pages-guides-nested-theme-providers-mdx" */),
  "component---src-pages-guides-object-styles-mdx": () => import("./../../../src/pages/guides/object-styles.mdx" /* webpackChunkName: "component---src-pages-guides-object-styles-mdx" */),
  "component---src-pages-guides-responsive-typography-mdx": () => import("./../../../src/pages/guides/responsive-typography.mdx" /* webpackChunkName: "component---src-pages-guides-responsive-typography-mdx" */),
  "component---src-pages-guides-styled-components-mdx": () => import("./../../../src/pages/guides/styled-components.mdx" /* webpackChunkName: "component---src-pages-guides-styled-components-mdx" */),
  "component---src-pages-guides-theme-color-meta-tag-mdx": () => import("./../../../src/pages/guides/theme-color-meta-tag.mdx" /* webpackChunkName: "component---src-pages-guides-theme-color-meta-tag-mdx" */),
  "component---src-pages-guides-typescript-mdx": () => import("./../../../src/pages/guides/typescript.mdx" /* webpackChunkName: "component---src-pages-guides-typescript-mdx" */),
  "component---src-pages-guides-variants-mdx": () => import("./../../../src/pages/guides/variants.mdx" /* webpackChunkName: "component---src-pages-guides-variants-mdx" */),
  "component---src-pages-hooks-mdx": () => import("./../../../src/pages/hooks.mdx" /* webpackChunkName: "component---src-pages-hooks-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-layout-mdx": () => import("./../../../src/pages/layout.mdx" /* webpackChunkName: "component---src-pages-layout-mdx" */),
  "component---src-pages-logos-mdx": () => import("./../../../src/pages/logos.mdx" /* webpackChunkName: "component---src-pages-logos-mdx" */),
  "component---src-pages-mdx-components-mdx": () => import("./../../../src/pages/mdx/components.mdx" /* webpackChunkName: "component---src-pages-mdx-components-mdx" */),
  "component---src-pages-mdx-index-mdx": () => import("./../../../src/pages/mdx/index.mdx" /* webpackChunkName: "component---src-pages-mdx-index-mdx" */),
  "component---src-pages-mdx-layout-components-mdx": () => import("./../../../src/pages/mdx/layout-components.mdx" /* webpackChunkName: "component---src-pages-mdx-layout-components-mdx" */),
  "component---src-pages-mdx-linked-headings-mdx": () => import("./../../../src/pages/mdx/linked-headings.mdx" /* webpackChunkName: "component---src-pages-mdx-linked-headings-mdx" */),
  "component---src-pages-mdx-styling-mdx": () => import("./../../../src/pages/mdx/styling.mdx" /* webpackChunkName: "component---src-pages-mdx-styling-mdx" */),
  "component---src-pages-mdx-syntax-highlighting-mdx": () => import("./../../../src/pages/mdx/syntax-highlighting.mdx" /* webpackChunkName: "component---src-pages-mdx-syntax-highlighting-mdx" */),
  "component---src-pages-mdx-themed-mdx": () => import("./../../../src/pages/mdx/themed.mdx" /* webpackChunkName: "component---src-pages-mdx-themed-mdx" */),
  "component---src-pages-migrating-mdx": () => import("./../../../src/pages/migrating.mdx" /* webpackChunkName: "component---src-pages-migrating-mdx" */),
  "component---src-pages-packages-color-mdx": () => import("./../../../src/pages/packages/color.mdx" /* webpackChunkName: "component---src-pages-packages-color-mdx" */),
  "component---src-pages-packages-components-mdx": () => import("./../../../src/pages/packages/components.mdx" /* webpackChunkName: "component---src-pages-packages-components-mdx" */),
  "component---src-pages-packages-core-mdx": () => import("./../../../src/pages/packages/core.mdx" /* webpackChunkName: "component---src-pages-packages-core-mdx" */),
  "component---src-pages-packages-css-mdx": () => import("./../../../src/pages/packages/css.mdx" /* webpackChunkName: "component---src-pages-packages-css-mdx" */),
  "component---src-pages-packages-gatsby-plugin-mdx": () => import("./../../../src/pages/packages/gatsby-plugin.mdx" /* webpackChunkName: "component---src-pages-packages-gatsby-plugin-mdx" */),
  "component---src-pages-packages-gatsby-theme-style-guide-mdx": () => import("./../../../src/pages/packages/gatsby-theme-style-guide.mdx" /* webpackChunkName: "component---src-pages-packages-gatsby-theme-style-guide-mdx" */),
  "component---src-pages-packages-gatsby-theme-ui-blog-mdx": () => import("./../../../src/pages/packages/gatsby-theme-ui-blog.mdx" /* webpackChunkName: "component---src-pages-packages-gatsby-theme-ui-blog-mdx" */),
  "component---src-pages-packages-global-mdx": () => import("./../../../src/pages/packages/global.mdx" /* webpackChunkName: "component---src-pages-packages-global-mdx" */),
  "component---src-pages-packages-index-mdx": () => import("./../../../src/pages/packages/index.mdx" /* webpackChunkName: "component---src-pages-packages-index-mdx" */),
  "component---src-pages-packages-match-media-mdx": () => import("./../../../src/pages/packages/match-media.mdx" /* webpackChunkName: "component---src-pages-packages-match-media-mdx" */),
  "component---src-pages-packages-mdx-mdx": () => import("./../../../src/pages/packages/mdx.mdx" /* webpackChunkName: "component---src-pages-packages-mdx-mdx" */),
  "component---src-pages-packages-presets-mdx": () => import("./../../../src/pages/packages/presets.mdx" /* webpackChunkName: "component---src-pages-packages-presets-mdx" */),
  "component---src-pages-packages-prism-mdx": () => import("./../../../src/pages/packages/prism.mdx" /* webpackChunkName: "component---src-pages-packages-prism-mdx" */),
  "component---src-pages-packages-style-guide-mdx": () => import("./../../../src/pages/packages/style-guide.mdx" /* webpackChunkName: "component---src-pages-packages-style-guide-mdx" */),
  "component---src-pages-packages-tachyons-mdx": () => import("./../../../src/pages/packages/tachyons.mdx" /* webpackChunkName: "component---src-pages-packages-tachyons-mdx" */),
  "component---src-pages-packages-tailwind-mdx": () => import("./../../../src/pages/packages/tailwind.mdx" /* webpackChunkName: "component---src-pages-packages-tailwind-mdx" */),
  "component---src-pages-packages-typography-mdx": () => import("./../../../src/pages/packages/typography.mdx" /* webpackChunkName: "component---src-pages-packages-typography-mdx" */),
  "component---src-pages-readme-mdx": () => import("./../../../src/pages/readme.mdx" /* webpackChunkName: "component---src-pages-readme-mdx" */),
  "component---src-pages-recipes-button-mdx": () => import("./../../../src/pages/recipes/button.mdx" /* webpackChunkName: "component---src-pages-recipes-button-mdx" */),
  "component---src-pages-recipes-centered-container-mdx": () => import("./../../../src/pages/recipes/centered-container.mdx" /* webpackChunkName: "component---src-pages-recipes-centered-container-mdx" */),
  "component---src-pages-recipes-css-grid-mdx": () => import("./../../../src/pages/recipes/css-grid.mdx" /* webpackChunkName: "component---src-pages-recipes-css-grid-mdx" */),
  "component---src-pages-recipes-flexbox-layout-mdx": () => import("./../../../src/pages/recipes/flexbox-layout.mdx" /* webpackChunkName: "component---src-pages-recipes-flexbox-layout-mdx" */),
  "component---src-pages-recipes-flexbox-sidebar-mdx": () => import("./../../../src/pages/recipes/flexbox-sidebar.mdx" /* webpackChunkName: "component---src-pages-recipes-flexbox-sidebar-mdx" */),
  "component---src-pages-recipes-footer-a-1-mdx": () => import("./../../../src/pages/recipes/footer-a1.mdx" /* webpackChunkName: "component---src-pages-recipes-footer-a-1-mdx" */),
  "component---src-pages-recipes-footer-a-2-mdx": () => import("./../../../src/pages/recipes/footer-a2.mdx" /* webpackChunkName: "component---src-pages-recipes-footer-a-2-mdx" */),
  "component---src-pages-recipes-footer-a-3-mdx": () => import("./../../../src/pages/recipes/footer-a3.mdx" /* webpackChunkName: "component---src-pages-recipes-footer-a-3-mdx" */),
  "component---src-pages-recipes-footer-a-4-mdx": () => import("./../../../src/pages/recipes/footer-a4.mdx" /* webpackChunkName: "component---src-pages-recipes-footer-a-4-mdx" */),
  "component---src-pages-recipes-gatsby-link-mdx": () => import("./../../../src/pages/recipes/gatsby-link.mdx" /* webpackChunkName: "component---src-pages-recipes-gatsby-link-mdx" */),
  "component---src-pages-recipes-grid-mdx": () => import("./../../../src/pages/recipes/grid.mdx" /* webpackChunkName: "component---src-pages-recipes-grid-mdx" */),
  "component---src-pages-recipes-header-a-1-mdx": () => import("./../../../src/pages/recipes/header-a1.mdx" /* webpackChunkName: "component---src-pages-recipes-header-a-1-mdx" */),
  "component---src-pages-recipes-header-a-2-mdx": () => import("./../../../src/pages/recipes/header-a2.mdx" /* webpackChunkName: "component---src-pages-recipes-header-a-2-mdx" */),
  "component---src-pages-recipes-header-a-3-mdx": () => import("./../../../src/pages/recipes/header-a3.mdx" /* webpackChunkName: "component---src-pages-recipes-header-a-3-mdx" */),
  "component---src-pages-recipes-header-a-4-mdx": () => import("./../../../src/pages/recipes/header-a4.mdx" /* webpackChunkName: "component---src-pages-recipes-header-a-4-mdx" */),
  "component---src-pages-recipes-header-a-5-mdx": () => import("./../../../src/pages/recipes/header-a5.mdx" /* webpackChunkName: "component---src-pages-recipes-header-a-5-mdx" */),
  "component---src-pages-recipes-index-js": () => import("./../../../src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-pages-recipes-mdx-table-align-mdx": () => import("./../../../src/pages/recipes/mdx-table-align.mdx" /* webpackChunkName: "component---src-pages-recipes-mdx-table-align-mdx" */),
  "component---src-pages-recipes-post-list-a-1-mdx": () => import("./../../../src/pages/recipes/post-list-a1.mdx" /* webpackChunkName: "component---src-pages-recipes-post-list-a-1-mdx" */),
  "component---src-pages-recipes-post-list-a-2-mdx": () => import("./../../../src/pages/recipes/post-list-a2.mdx" /* webpackChunkName: "component---src-pages-recipes-post-list-a-2-mdx" */),
  "component---src-pages-recipes-stack-mdx": () => import("./../../../src/pages/recipes/stack.mdx" /* webpackChunkName: "component---src-pages-recipes-stack-mdx" */),
  "component---src-pages-recipes-tiled-layout-mdx": () => import("./../../../src/pages/recipes/tiled-layout.mdx" /* webpackChunkName: "component---src-pages-recipes-tiled-layout-mdx" */),
  "component---src-pages-resources-mdx": () => import("./../../../src/pages/resources.mdx" /* webpackChunkName: "component---src-pages-resources-mdx" */),
  "component---src-pages-static-css-mdx": () => import("./../../../src/pages/static-css.mdx" /* webpackChunkName: "component---src-pages-static-css-mdx" */),
  "component---src-pages-styled-mdx": () => import("./../../../src/pages/styled.mdx" /* webpackChunkName: "component---src-pages-styled-mdx" */),
  "component---src-pages-sx-prop-mdx": () => import("./../../../src/pages/sx-prop.mdx" /* webpackChunkName: "component---src-pages-sx-prop-mdx" */),
  "component---src-pages-testimonials-mdx": () => import("./../../../src/pages/testimonials.mdx" /* webpackChunkName: "component---src-pages-testimonials-mdx" */),
  "component---src-pages-theme-spec-mdx": () => import("./../../../src/pages/theme-spec.mdx" /* webpackChunkName: "component---src-pages-theme-spec-mdx" */),
  "component---src-pages-theming-mdx": () => import("./../../../src/pages/theming.mdx" /* webpackChunkName: "component---src-pages-theming-mdx" */),
  "component---src-templates-preset-js": () => import("./../../../src/templates/preset.js" /* webpackChunkName: "component---src-templates-preset-js" */)
}

