/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "mdx-table-columns-align"
  }, "MDX Table Columns Align"), "\n", React.createElement(_components.p, null, "You can use standard MDX align styling to overwrite the column text align prop"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx"
  }, "| Left | Centre | Right |\n| :--- | :----: | ----: |\n| 1    |   2    |     3 |\n")), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Left"), React.createElement(_components.th, {
    align: "center"
  }, "Centre"), React.createElement(_components.th, {
    align: "right"
  }, "Right"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "1"), React.createElement(_components.td, {
    align: "center"
  }, "2"), React.createElement(_components.td, {
    align: "right"
  }, "3")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
