"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    strong: "strong",
    a: "a",
    ul: "ul",
    li: "li",
    code: "code",
    em: "em",
    pre: "pre",
    h3: "h3"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "migration-guides",
      children: "Migration Guides"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v016",
      children: "v0.16"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "🔥 Breaking:"
      }), " Theme UI now supports and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "depends on"
      }), " TypeScript newer than\n5.1.2 (because of breaking changes to JSX types, see\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/issues/2430#issuecomment-1586197881",
        children: "https://github.com/system-ui/theme-ui/issues/2430#issuecomment-1586197881"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Update ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@types/react"
        }), " to a version published after June 1, 2023."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "JSX Automatic Runtime is highly encouraged to minimize the friction and ensure\nyou don't get weird type errors."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/sidenav"
        }), " package was removed due to low usage and breaking changes\nin ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@types/react"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Deps bumps for 0.16.0, support only new React and TypeScript to avoid type\nerrors ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/system-ui/theme-ui/pull/2432",
          children: "#2432"
        }), "\n(", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/hasparus",
          children: "@hasparus"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ThemeProvider"
        }), " was renamed to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ThemeUIProvider"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Deprecate/rename ThemeProvider to ThemeUIProvider\n", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/system-ui/theme-ui/pull/2360",
          children: "#2360"
        }), "\n(", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/lachlanjc",
          children: "@lachlanjc"
        }), "\n", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/hasparus",
          children: "@hasparus"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v015",
      children: "v0.15"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "MDX is now opt-in."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["If your project is not using MDX or importing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Themed"
        }), ", you shouldn't need to\nchange anything."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you are using MDX, this change enables you to use Theme UI together with\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://mdxjs.com/blog/v2/",
        children: "MDX v2"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "MDXProvider"
          }), " is no longer included in Theme UI ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "ThemeProvider"
          }), ", and has been\nremoved in favour of an ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useThemedStylesWithMdx"
          }), " hook."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Migration:"
          }), " Use ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useThemedStylesWithMdx"
          }), " together with ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "MDXProvider"
          }), " and\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useMDXComponents"
          }), " from ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "@mdx-js/react"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-tsx",
            children: "import {\n  MDXProvider,\n  useMDXComponents,\n  Components as MDXComponents,\n  MergeComponents as MergeMDXComponents,\n} from '@mdx-js/react'\nimport { useThemedStylesWithMdx } from '@theme-ui/mdx'\nimport { ThemeProvider, Theme } from 'theme-ui'\n\ninterface MyProviderProps {\n  theme: Theme\n  components?: MDXComponents | MergeMDXComponents\n  children: React.ReactNode\n}\nfunction MyProvider({ theme, components, children }: MyProviderProps) {\n  const componentsWithStyles = useThemedStylesWithMdx(\n    useMDXComponents(components)\n  )\n\n  return (\n    <ThemeProvider theme={theme}>\n      <MDXProvider components={componentsWithStyles}>{children}</MDXProvider>\n    </ThemeProvider>\n  )\n}\n"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "Themed"
          }), " components dict and other exports from ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "@theme-ui/mdx"
          }), " are no longer\nreexported from ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme-ui"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Migration:"
          }), " Import it from ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "@theme-ui/mdx"
          }), " instead."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-diff",
            children: "-  import { Themed } from 'theme-ui'\n+  import { Themed } from '@theme-ui/mdx'\n"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Themed"
        }), " object is no longer a component"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Previously, it was an alias for ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Themed.div"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Migration:"
        }), " Whenever you're using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<Themed />"
        }), ", use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<Themed.div />"
        }), "\ninstead."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v014",
      children: "v0.14"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "theme-ui"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "@theme-ui/components"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "@theme-ui/mdx"
          }), " packages no longer\ndepend on ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "@emotion/styled"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Previously deprecated ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Component.withComponent"
          }), " API was removed."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "as"
          }), " prop was removed from Themed.X components from ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "@theme-ui/mdx"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["All occurrences of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<Themed.X as=\"element\">"
            }), " can be changed to\n", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<element sx={t => t.styles.X} />"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v013",
      children: "v0.13"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Moved Emotion and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@mdx-js/react"
        }), " to peerDependencies to solve context\nmismatch bugs"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You now install ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme-ui"
      }), " umbrella package like this:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "npm install theme-ui @emotion/react @emotion/styled @mdx-js/react\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "@emotion/react"
        }), " is now a peer dependency of the majority of Theme UI\nlibraries"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "@emotion/styled"
        }), " is now a peer dependency of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/components"
        }), ",\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/mdx"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-ui"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "@mdx-js/react"
        }), " is now a peer dependency of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/mdx"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-ui"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This is a revert of change from Theme UI v0.3, and it's meant to help solve\nversion clashes and context mismatch bugs on user side. Connected issues:\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/pull/1793",
        children: "#1793"
      }), ",\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/pull/1531",
        children: "#1531"
      }), ",\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/pull/1530",
        children: "#1530"
      }), ",\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/pull/1388",
        children: "#1388"
      }), ",\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/pull/1345",
        children: "#1345"
      }), ",\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/pull/1130",
        children: "#1130"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.em, {
        children: "Disclaimer: You might still encounter this problem if other libraries install\nclashing Emotion versions."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more at\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/pull/1867#issue-948084198",
        children: "#1867"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v09",
      children: "v0.9"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Dropped support for legacy browsers."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Theme UI packages became lighter! Built source code is now 9.5 kB (34%) smaller."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The trade-off is, Babel config has changed and it no longer supports Internet\nExplorer 11. If you need to support legacy browsers, you can transpile\nnode_modules (e.g. with\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/martpie/next-transpile-modules",
        children: "next-transpile-modules"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/system-ui/theme-ui/runs/2618450614",
          children: "See build raport with size comparison for each package"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/system-ui/theme-ui/blob/develop/babel.config.js",
          children: "Inspect updated Babel config"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://bundlephobia.com/result?p=theme-ui@0.9.0",
          children: "Visit Bundlephobia"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Internal package ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "gatsby-theme-code-recipes"
        }), " was removed."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v08",
      children: "v0.8"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Theme configuration options were moved to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.config"
        }), " object."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Deprecations:"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useRootStyles"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useCustomProperties"
          }), ",\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useColorSchemeMediaQuery"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useBorderBox"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useLocalStorage"
          }), " options on\nthe theme object are now scoped under a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "config"
          }), " object on the theme, and the\nroot-level options, now deprecated, will be removed in a future release."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["(e.g. you should be setting ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.config.useBorderBox"
          }), " instead of\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.useBorderBox"
          }), ")"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["⚠ All config options should be migrated at once. If Theme UI sees\n", (0, _jsxRuntime.jsx)(_components.code, {
              children: "theme.config"
            }), " exists, it won't look for options on the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "theme"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "APIs deprecated in v0.6 were removed."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.useBodyStyles"
        }), " ⟶ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.config.useRootStyles"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Styled"
        }), " ⟶ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Themed"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Following the deprecation of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useBodyStyles"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useRootStyles"
      }), " now defaults to\ntrue. This means that your styles from ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.styles.root"
      }), " are now applied to\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<html>"
      }), " element, not ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<body>"
      }), " element. Most use cases should be fine, but some\nstyles may need adjustment."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v06",
      children: "v0.6"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "whats-new",
      children: "What's New"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "Styled"
          }), " components dict was renamed to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/themed/",
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Themed"
            })
          }), " to avoid\nconfusion with ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "styled"
          }), " components constructors from ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "@emotion/styled"
          }), " and\nsimilar libraries."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.useRootStyles"
          }), " configuration option (false by default). Set it to\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), " to add ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "styles.root"
          }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "html"
          }), " instead of ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "body"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "theme.useBodyStyles"
            }), " configuration option still defaults to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", but\nit's going in to be deprecated in favor of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "theme.useRootStyles"
            }), " in the\nfuture."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["New scale: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "transitions"
          }), " supporting ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "transition"
          }), " CSS property. Issue\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/issues/1079",
            children: "#1079"
          }), ", PR\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/pull/1272",
            children: "#1272"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Objects in nested scales can now have a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "__default"
          }), " key. PR\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/pull/951",
            children: "#951"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Given the theme"
        }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "const theme = {\n  colors: {\n    primary: {\n      __default: '#00f',\n      light: '#33f',\n    }\n  }\n}\n"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "color: 'primary'"
          }), " resolves to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "color: '#00f'"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Configuration option for printing color mode. PR\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/pull/1267",
            children: "#1267"
          }), ", issue\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/issues/1144",
            children: "#1144"
          }), ". No more wasted\nink."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{\n  initialColorModeName: \"dark\",\n  printColorModeName: \"light\"\n}\n"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["A new component, Paragraph was added in PR\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/pull/1298",
            children: "#1298"
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "breaking-changes",
      children: "Breaking Changes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Theme UI 0.6 depends on Emotion 11, and isn't compatible with Emotion 10\nanymore."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If you didn't install Emotion separately, this update shouldn't affect you."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If your other dependencies depend on Emotion 10, and have no published\nversions for Emotion 11, you can use\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://yarnpkg.com/lang/en/docs/selective-version-resolutions/",
              children: "Yarn resolutions"
            }), "\nor\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://webpack.js.org/configuration/resolve/#resolvealias",
              children: "Webpack's resolve.alias"
            }), "\nto enforce a version."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Refer to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://emotion.sh/docs/emotion-11",
            children: "Emotion 11 release notes"
          }), " for\nmore information."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Color mode flash on first render is gone, but to achieve this we had to bring\nback ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.rawColors"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsxs)(_components.strong, {
              children: ["You can no longer read raw color values from ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "theme.colors"
              })]
            }), " when reading\ntheme from ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "useThemeUI"
            }), " or inside ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sx"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: ".colors"
            }), " object contains Custom CSS Properties now."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you need to pass original value somewhere where CSS Properties (e.g.\nWebGL Canvas) won't work use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".rawColors"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "How to migrate?"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Find places where you read ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors"
          }), " from the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "useThemeUI"
          }), " and extract\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "rawColors"
          }), " instead of ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-tsx",
            children: "const { rawColors: colors } = useThemeUI().theme\n"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Default color mode name is no longer ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"default\""
          }), " — it's now ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "undefined"
          }), ", what\nrepresents the lack of color mode set by user or detected from preferences."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "useColorSchemeMediaQuery"
          }), " defaults to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), ". Issue\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/issues/624",
            children: "#624"
          }), ", PR\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/pull/1373",
            children: "#1373"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "How to migrate?"
          }), " Add ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "useColorSchemeMediaQuery: false"
          }), " to your theme if you\ndon't have this property.\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://dev.theme-ui.com/color-modes/#responding-to-the-prefers-color-scheme-media-query",
            children: "Read more in the docs."
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["We no longer export internal React context named as ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Context"
          }), " 😅 It wasn't and\nit's still not public API, but if you used it and ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "you really"
          }), " need it, you\ncan grab it as ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "__ThemeUIContext"
          }), ". (But please don't do this. Use\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "ThemeProvider"
          }), " from ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "@theme-ui/core"
          }), " for local theme overrides instead.)"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "deprecations",
      children: "Deprecations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Styled"
        }), " will be removed in v0.7. Use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/themed",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "Themed"
          })
        }), " instead."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "useBodyStyles"
        }), " will be removed in v0.7. Use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "useRootStyles"
        }), " instead."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "img-srchttpsemojisslackmojiscomemojisimages14797454581383typescriptpng1479745458-width20-height20--typescript-changes",
      children: " TypeScript Changes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Theme UI is now written in TypeScript, and the emitted types differ from\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@types/theme-ui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), " in now accepted in responsive tuple types. PR\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/pull/1499",
            children: "#1499"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Known colors (", (0, _jsxRuntime.jsx)(_components.em, {
            children: "primary"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "text"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "background"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "accent"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "secondary"
          }), ") in\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "ColorMode"
          }), " can now be nested scales."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The following no longer typechecks, as ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors.primary"
          }), " can be an object."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-tsx",
            children: "sx={{\n  color: theme => theme.colors?.primary?.toUpperCase()\n}}\n"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "But the following code still works."
        }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-tsx",
            children: "sx={{\n  color: theme => theme.colors?.primary\n}}\n"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors.primary"
          }), " is an object, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors.primary.__default"
          }), " is used."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), " values are skipped before passing style objects to Emotion. Issue\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/issues/1297",
            children: "#1297"
          }), ", PR\n", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/system-ui/theme-ui/pull/1460",
            children: "#1460"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "The following syntax is now supported"
        }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-tsx",
            children: "sx={{ color: isActive && blue }}\n"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "\"as\" prop on Themed.X components now properly opts out of typechecking"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["TypeScript users, don't use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ComponentProps<typeof Themed['div']>"
            }), ", import\n", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ThemedComponent"
            }), " and use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ThemedComponent<'div'>"
            }), " instead."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Theme UI 0.6 depends on ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/frenic/csstype",
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "csstype"
            })
          }), " v3\ninstead of ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "csstype"
          }), " v2."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Renamed types"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Anything copied from ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "styled-system"
            }), " types was renamed or removed."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "SxProps"
            }), " was renamed to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SxProp"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "SxStyleProp"
            }), ", an alias for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ThemeUIStyleObject"
            }), " was removed. Use\n", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ThemeUIStyleObject"
            }), " instead."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/blob/develop/CHANGELOG.md",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Refer to the changelog for a full list of changes."
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v03",
      children: "v0.3"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "whats-new-1",
      children: "What's New"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Components can now be imported directly from the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-ui"
        }), " package. Be sure\nthat treeshaking is enabled with your build tool."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Includes smart defaults for adding base styles to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<body>"
        }), " element."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Simplified color modes API."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["New ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/css"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/core"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/color-modes"
        }), ", and\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/mdx"
        }), " packages allow for more bespoke ways to use the library."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "breaking-changes-1",
      children: "Breaking Changes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "@emotion/react"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@mdx-js/react"
        }), " are now dependencies of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-ui"
        }), " and\nshould not be installed separately. If you'd like to use a particular version\nof each library, use\n", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://yarnpkg.com/lang/en/docs/selective-version-resolutions/",
          children: "Yarn resolutions"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Theme UI context no longer provides ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "context.components"
        }), ". If you're using this\nobject from context, use the MDX hook instead. E.g.\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "import { useMDXComponents } from '@mdx-js/react'"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you'd like color mode to be initialized from the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "prefers-color-scheme"
        }), "\nmedia query, you must enable the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "useColorSchemeMediaQuery: true"
        }), " flag in your\ntheme."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ColorMode"
        }), " component is deprecated and no longer required to add color\nstyles to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<body>"
        }), " element."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The following components have been removed in favor of using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Box"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Flex"
        }), "\ncomponents: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Layout"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Header"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Main"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Footer"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "initialColorMode"
        }), " flag no longer works, use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "initialColorModeName"
        }), "\ninstead."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ThemeProvider"
        }), " now adds global typographic styles to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<body>"
        }), " element\nbased on ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.styles.root"
        }), ". To disable this behavior set the\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "useBodyStyles: false"
        }), " flag in your theme."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Theme context is now stateless. If you've made use of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "context.setTheme"
        }), ", this\nno longer works. An alternative approach is available with the\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/editor"
        }), " package."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ThemeStateProvider"
        }), " component is no longer avialable, see\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/editor"
        }), " as an alternative."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@theme-ui/editor"
        }), " package has a completely new API. Please refer to the\npackage's\n", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/system-ui/theme-ui/blob/stable/packages/editor/README.md",
          children: "README.md"
        }), "\nfor more information."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "v02",
      children: "v0.2"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "jsx-pragma",
      children: "JSX Pragma"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you were using the Theme UI custom JSX pragma, rename the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css"
      }), " prop to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sx"
      }), ".\nThis does not apply if you were importing and using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css"
      }), " utility function\nmanually."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "theme-ui-typography",
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme-ui-typography"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you were using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme-ui-typography"
      }), " package, install the new package\nnamed ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@theme-ui/typography"
      }), " instead. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toStyle"
      }), " API is no longer included.\nUse the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toTheme"
      }), " API instead, see the\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/styling-mdx/#typographyjs",
        children: "Typography.js docs"
      }), " for how to use this utility."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      id: "box-and-layout-component",
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " and layout component"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you were using Styled System style props on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " component or any other\nlayout component, replace these props with either the ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/sx-prop",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sx"
        }), " prop"]
      }), " or\nby using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css"
      }), " utility."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "other",
      children: "Other"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Replace ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ColorModeProvider"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ComponentProvider"
        }), " with the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ThemeProvider"
        }), "\ncomponent."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@emotion/styled"
        }), " package is no longer required for Theme UI. If you are\nnot using it directly in your application, you can remove it from your\ndependencies."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = MDXContent;
exports.default = _default;