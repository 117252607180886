/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Tiles from '../components/tiles';
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    blockquote: "blockquote",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(Tiles, {
    sx: {
      py: 6,
      p: {
        m: 0
      },
      blockquote: {
        m: 0,
        p: 0,
        fontWeight: 'bold'
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
        ':hover': {
          color: 'primary'
        }
      }
    }
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "“Looking into Theme UI and WHOA 🤯 it's everything I like from\nstyled-system, leveraged into a less opinionated usage.”"), "\n"), "\n", React.createElement(_components.p, null, "– ", React.createElement(_components.a, {
    href: "https://mobile.twitter.com/ekafyi/status/1145298381142540288"
  }, "Eka")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "“Feels like web development using cheat codes”"), "\n"), "\n", React.createElement(_components.p, null, "–\n", React.createElement(_components.a, {
    href: "https://mobile.twitter.com/rjgux/status/1146490079482929158"
  }, "Richard George")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "“Theme UI is brilliant. Took me a little while to get into it but it’s super\nbrill brills once you get going! 😎”"), "\n"), "\n", React.createElement(_components.p, null, "–\n", React.createElement(_components.a, {
    href: "https://mobile.twitter.com/PaulieScanlon/status/1146856787494260737"
  }, "Paul Scanlon")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "“Took me approximately 5 minutes to fall in love with Theme UI, its sx prop\ngives you access to design tokens from a theme file automatically as well as\nthe goodness of the css prop 🔥”"), "\n"), "\n", React.createElement(_components.p, null, "– ", React.createElement(_components.a, {
    href: "https://mobile.twitter.com/gill_kyle/status/1144675802002292736"
  }, "Kyle Gill")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "“Theme UI is absolutely brilliant. Things I was struggling with in\nstyled-system (like mixing responsive and css props) are solved very\nelegantly + so much more.”"), "\n"), "\n", React.createElement(_components.p, null, "–\n", React.createElement(_components.a, {
    href: "https://mobile.twitter.com/bernharduw/status/1147047566019899392"
  }, "Bernhard Gschwantner")), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
