/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    code: "code",
    pre: "pre",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "merging-themes"
  }, "Merging Themes"), "\n", React.createElement(_components.p, null, "Sometimes it's useful to split a theme across multiple files, use a preset as the basis for a custom theme, or combine two or more themes together.\nSince themes are plain JavaScript objects, any merging strategy will work.\nThis guide shows a few common ways to merge themes together."), "\n", React.createElement(_components.h2, {
    id: "using-a-preset"
  }, "Using a preset"), "\n", React.createElement(_components.p, null, "To use a preset as the basis for a custom theme, it's recommended that you use a deep merge utility.\nThe ", React.createElement(_components.code, null, "theme-ui"), " package exports a preconfigured version of the ", React.createElement(_components.code, null, "deepmerge"), " package that can be used for this."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme based on preset-future\nimport future from '@theme-ui/preset-future'\nimport { merge } from 'theme-ui'\n\nexport default merge(future, {\n  fonts: {\n    body: 'Montserrat, sans-serif',\n  },\n})\n")), "\n", React.createElement(_components.h2, {
    id: "multiple-files"
  }, "Multiple files"), "\n", React.createElement(_components.p, null, "While there is absolutely nothing wrong with keeping an entire theme in a single file, you can split a theme into multiple files (or modules)."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme/colors.js\nexport default {\n  text: '#000',\n  background: '#fff',\n  primary: '#07c',\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme/fonts.js\nexport default {\n  body: 'system-ui, sans-serif',\n  heading: 'Baskerville, Georgia, serif',\n  monospace: 'Menlo, monospace',\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme/index.js\nimport colors from './colors'\nimport fonts from './fonts'\n\nexport default {\n  colors,\n  fonts,\n}\n")), "\n", React.createElement(_components.h2, {
    id: "merging-theme-values-from-multiple-files"
  }, "Merging theme values from multiple files"), "\n", React.createElement(_components.p, null, "For the core scales (", React.createElement(_components.code, null, "colors"), ", ", React.createElement(_components.code, null, "fontSizes"), ", ", React.createElement(_components.code, null, "space"), ", etc), it’s typically simpler to understand a theme when values are defined in one file.\nHowever, especially for projects with many ", React.createElement(_components.a, {
    href: "/styling-mdx"
  }, "styles"), " or ", React.createElement(_components.a, {
    href: "/guides/variants"
  }, "variants"), ", you may want to split values that end up\nas part of one object across multiple files. You can use the same kind of imports combined with the ", React.createElement(_components.code, null, "merge"), " utility for this."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme/index.js\nimport { merge } from 'theme-ui'\nimport colors from './colors'\nimport fonts from './fonts'\nimport textStyles from './styles/text'\nimport tableStyles from './styles/tables'\nimport { buttons, forms, links } from './variants'\n\nexport default {\n  colors,\n  fonts,\n  styles: merge(textStyles, tableStyles),\n  buttons,\n  forms,\n  links,\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
