/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "stack-layout"
  }, "Stack Layout"), "\n", React.createElement(_components.p, null, "Add vertical space between child elements using CSS Grid."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "",
    xray: ""
  }, "<div\n  sx={{\n    display: 'grid',\n    gridGap: 4,\n  }}>\n  <div>One</div>\n  <div>Two</div>\n  <div>Three</div>\n  <div>Four</div>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nexport default function Stack({ gap = 4, ...props }) => (\n  <div\n    {...props}\n    sx={{\n      display: 'grid',\n      gridGap: gap,\n    }}\n  />\n)\n")), "\n", React.createElement(_components.p, null, "See also: ", React.createElement(_components.a, {
    href: "/components/grid"
  }, "Grid component")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
