/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Logo from '../components/logo';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "theme-ui-logos"
  }, "Theme UI Logos"), "\n", React.createElement(_components.h2, {
    id: "svg"
  }, "SVG"), "\n", React.createElement(Logo), "\n", React.createElement(_components.h2, {
    id: "png"
  }, "PNG"), "\n", React.createElement(_components.img, {
    src: "logo.png",
    alt: "Theme UI logo"
  }), "\n", React.createElement(_components.img, {
    src: "card.png",
    alt: "Theme UI logo"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
