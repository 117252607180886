/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    a: "a",
    pre: "pre",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "styled-components"
  }, "Styled Components"), "\n", React.createElement(_components.p, null, "Theme UI itself doesn’t expose an API for styled components, but works\nseamlessly with the ", React.createElement(_components.code, null, "styled"), " API from the ", React.createElement(_components.a, {
    href: "https://emotion.sh/docs/styled"
  }, "@emotion/styled"), " package.\nComponents written with it should have access to the same theming context that\nTheme UI uses."), "\n", React.createElement(_components.p, null, "Instead of using the ", React.createElement(_components.code, null, "ThemeProvider"), " component from ", React.createElement(_components.code, null, "@emotion/react"), ", import and\nuse the Theme UI provider at your app’s root level."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\nimport { ThemeUIProvider } from 'theme-ui'\nimport theme from './theme'\nimport SomeComponent from './SomeComponent'\n\nexport default (props) => (\n  <ThemeUIProvider theme={theme}>\n    <SomeComponent />\n  </ThemeUIProvider>\n)\n")), "\n", React.createElement(_components.p, null, "If you’re using the ", React.createElement(_components.a, {
    href: "https://styled-components.com"
  }, "Styled Components"), " library, these can usually be\nconverted to use Emotion with a single line change to the ", React.createElement(_components.code, null, "import"), " statement."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// before\nimport styled from 'styled-components'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// after\nimport styled from '@emotion/styled'\n")), "\n", React.createElement(_components.h2, {
    id: "using-the-sx-prop"
  }, "Using the ", React.createElement(_components.code, null, "sx"), " prop"), "\n", React.createElement(_components.p, null, "Theme UI is actively working on removing its internal dependency on\n", React.createElement(_components.code, null, "@emotion/styled"), " to reduce bundle size. While Styled Components made with the\npackage will continue to work into the future, we recommend using the\n", React.createElement(_components.a, {
    href: "/sx-prop"
  }, React.createElement(_components.code, null, "sx"), " prop"), " instead for simpler styling with\n", React.createElement(_components.a, {
    href: "/guides/object-styles"
  }, "object styles"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\n\nconst Section = ({ width, color, bg, ...props }) => (\n  <section\n    {...props}\n    sx={{\n      width,\n      color,\n      bg,\n      // additional styles...\n    }}\n  />\n)\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
