/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    a: "a",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "mdx-layout-components"
  }, "MDX Layout Components"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "ThemeUIProvider"), " component can be nested within a parent ", React.createElement(_components.code, null, "ThemeUIProvider"), ",\nallowing you to contextually adjust styles in certain parts of a UI. This\nfunctionality can be leveraged to create custom layout components that take MDX\ncontent as children to create uniquely styled blocks of content. For example,\nthis site's ", React.createElement(_components.a, {
    href: "/"
  }, "landing page"), " uses this approach to style its content, which is\nwritten in MDX."), "\n", React.createElement(_components.p, null, "As an example, create a new component with the ", React.createElement(_components.code, null, "ThemeUIProvider"), " and a wrapping\n", React.createElement(_components.code, null, "<div>"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsxImportSource theme-ui */\nimport { ThemeUIProvider } from 'theme-ui'\n\nexport default (props) => (\n  <ThemeUIProvider theme={{}}>\n    <div {...props} />\n  </ThemeUIProvider>\n)\n")), "\n", React.createElement(_components.p, null, "To add styles to this component, augment the required ", React.createElement(_components.code, null, "theme"), " prop and add an\n", React.createElement(_components.code, null, "sx"), " prop to the ", React.createElement(_components.code, null, "div"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    filename: "InvertedBanner.js"
  }, "/** @jsxImportSource theme-ui */\nimport { ThemeUIProvider } from 'theme-ui'\n\nconst InvertedBanner = (props) => (\n  <ThemeUIProvider\n    theme={{\n      styles: {\n        // style child elements\n        h1: {\n          fontSize: [5, 6, 7],\n        },\n      },\n    }}\n  >\n    <div\n      {...props}\n      sx={{\n        // swap colors for an inverted effect\n        color: 'background',\n        bg: 'primary',\n        px: 3,\n        py: 4,\n      }}\n    />\n  </ThemeUIProvider>\n)\n\nexport default InvertedBanner\n")), "\n", React.createElement(_components.p, null, "The component above can then be imported and used within an MDX document."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-md"
  }, "import InvertedBanner from './InvertedBanner'\n\n<InvertedBanner>\n\n# Hello\n\nThis section has custom styles\n\n</InvertedBanner>\n\nThis part of the document uses default styles\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
