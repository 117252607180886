/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    code: "code",
    h2: "h2",
    ol: "ol",
    li: "li",
    pre: "pre"
  }, _provideComponents(), props.components), {Note} = _components;
  if (!Note) _missingMdxReference("Note", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "css-keyframes"
  }, "CSS Keyframes"), "\n", React.createElement(_components.p, null, "Using keyframes with Theme UI is fully supported, but not part of the Theme UI\nlibrary itself. Instead, use\n", React.createElement(_components.a, {
    href: "https://emotion.sh/docs/keyframes"
  }, "the ", React.createElement(_components.code, null, "keyframes"), " helper from Emotion"), "."), "\n", React.createElement(_components.h2, {
    id: "usage"
  }, "Usage"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "import { keyframes } from '@emotion/react'")), "\n", React.createElement(_components.li, null, "Create a variable using ", React.createElement(_components.code, null, "keyframes"), " for your animation (examples below)"), "\n", React.createElement(_components.li, null, "Use the variable as the animation name, in an ", React.createElement(_components.code, null, "animation"), " or ", React.createElement(_components.code, null, "animationName"), "\ndeclaration"), "\n"), "\n", React.createElement(Note, null, React.createElement(_components.p, null, React.createElement(_components.code, null, "@emotion/react"), " is a peer dependency of Theme UI, so while required alongside\nTheme UI packages, it needs manual installation in your project.")), "\n", React.createElement(_components.h2, {
    id: "examples"
  }, "Examples"), "\n", React.createElement(_components.p, null, "Using object syntax:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Box } from 'theme-ui'\nimport { keyframes } from '@emotion/react'\n\nconst fadeIn = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })\n\nexport default (props) => (\n  <Box {...props} sx={{ animation: `${fadeIn} 1s backwards` }} />\n)\n")), "\n", React.createElement(Note, null, React.createElement(_components.p, null, "To directly set ", React.createElement(_components.code, null, "animationName"), " using object syntax, append ", React.createElement(_components.code, null, ".toString()"), " to the\nanimation variable. This workaround is not needed when using the variable inside\na template string, such as above.")), "\n", React.createElement(_components.p, null, "Using template literal syntax:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { jsx } from 'theme-ui'\nimport { keyframes } from '@emotion/react'\n\nconst wave = keyframes`\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(-5deg);\n  }\n`\n\nexport default (props) => (\n  <div\n    {...props}\n    sx={{ animation: `${wave} 0.5s linear infinite alternate` }}\n  />\n)\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
