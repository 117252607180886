/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    code: "code",
    pre: "pre",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "responsive-typography"
  }, "Responsive Typography"), "\n", React.createElement(_components.p, null, "While there are many different approaches to styling responsive typography on\nthe web, the ", React.createElement(_components.a, {
    href: "/theming/#styles"
  }, React.createElement(_components.code, null, "theme.styles")), " API can be used to create rich, responsive\ntypography in MDX documents, with full control over the end result. Used in\ncombination with ", React.createElement(_components.a, {
    href: "/sx-prop/#responsive-values"
  }, "responsive array values"), " and ", React.createElement(_components.a, {
    href: "/guides/variants"
  }, "variants"), ", you can create\nreusable typographic styles that don't pollute the global CSS scope."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme with responsive typography styles\n{\n  fontSizes: [\n    12, 14, 16, 18, 24, 32, 48, 64, 72,\n  ],\n  fonts: {\n    body: 'system-ui, sans-serif',\n    heading: 'Poppins, sans-serif',\n  },\n  fontWeights: {\n    body: 400,\n    heading: 900,\n    bold: 700,\n  },\n  lineHeights: {\n    body: 1.5,\n    heading: 1.125,\n  },\n  text: {\n    heading: {\n      fontFamily: 'heading',\n      fontWeight: 'heading',\n      lineHeight: 'heading',\n    },\n  },\n  styles: {\n    root: {\n      fontFamily: 'body',\n      fontWeight: 'body',\n      lineHeight: 'body',\n    },\n    p: {\n      fontSize: [2, 3],\n    },\n    h1: {\n      variant: 'text.heading',\n      fontSize: [5, 6, 7],\n    },\n    h2: {\n      variant: 'text.heading',\n      fontSize: [4, 5],\n    },\n  },\n}\n")), "\n", React.createElement(_components.h2, {
    id: "caveats"
  }, "Caveats"), "\n", React.createElement(_components.p, null, "Due to how CSS specificity works, if you've defined responsive styles in\n", React.createElement(_components.code, null, "theme.styles"), ", overriding styles with the ", React.createElement(_components.code, null, "sx"), " prop requires also including\nstyles for the ", React.createElement(_components.a, {
    href: "/theming/#breakpoints"
  }, React.createElement(_components.code, null, "breakpoints")), " set in the theme. For example, with the\nfollowing, the ", React.createElement(_components.code, null, "fontSize"), " style will only apply at the smallest breakpoint, and\nthe ", React.createElement(_components.code, null, "theme.styles.h1"), " responsive styles will apply at other breakpoints."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme\n{\n  styles: {\n    h1: {\n      fontSize: [ 4, 5, 6 ],\n    }\n  }\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Themed.h1\n  sx={{\n    // styles for larger breakpoints will still apply\n    // due to CSS specificity in media queries\n    fontSize: 3,\n  }}\n/>\n")), "\n", React.createElement(_components.h2, {
    id: "non-mdx-content"
  }, "Non-MDX content"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "/mdx/themed"
  }, React.createElement(_components.code, null, "Themed")), " components can be used to pick up responsive styles outside of\nMDX, but if you'd like to apply styles to other markdown or user generated\ncontent, you can use the ", React.createElement(_components.code, null, "sx"), " prop with a variant to style child elements. What\nthis does is take the entire ", React.createElement(_components.code, null, "theme.styles"), " object, adds a scoped classname to\nthe ", React.createElement(_components.code, null, "<div>"), ", and injects CSS with child selectors based on the keys in\n", React.createElement(_components.code, null, "theme.styles"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "export default (props) => (\n  <div\n    sx={{\n      variant: 'styles',\n    }}\n  >\n    {props.children}\n  </div>\n)\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
