/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    pre: "pre",
    code: "code",
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "card"
  }, "Card"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Card } from 'theme-ui'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    live: "true"
  }, "<Card\n  sx={{\n    maxWidth: 256,\n  }}\n>\n  <Image src={images.nyc} />\n  <Text>Card</Text>\n</Card>\n")), "\n", React.createElement(_components.h2, {
    id: "variants"
  }, "Variants"), "\n", React.createElement(_components.p, null, "Card style variants can be defined in the ", React.createElement(_components.code, null, "theme.cards"), " object. By default the\nCard component uses the ", React.createElement(_components.code, null, "theme.cards.primary"), " variant."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme variants\n{\n  cards: {\n    primary: {\n      padding: 2,\n      borderRadius: 4,\n      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',\n    },\n    compact: {\n      padding: 1,\n      borderRadius: 2,\n      border: '1px solid',\n      borderColor: 'muted',\n    },\n  },\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
